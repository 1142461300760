import { IncomingMessage, ServerResponse } from 'http';
import { v4 as uuidv4 } from 'uuid';

import { appendSetCookieHeader, readCookie, setCookie } from '../utils/cookies';

// Note: The CSRF / XSRF token is NOT generated by the spring backend.
// The next.js server attaches a self generated token to the very first request the user sends in order to
// provide a valid token for subsequent POST requests (e.g. fetching products). If this backend would issue
// the token the client would have to wait for a GET request that contains said cookie. This would slow down
// the website significantly.
export const attachXsrfCookie = (req: IncomingMessage, res: ServerResponse) => {
    const xsrfToken = readCookie('XSRF-TOKEN', req.headers.cookie);
    if (xsrfToken) {
        return;
    }

    appendSetCookieHeader(
        res,
        setCookie('XSRF-TOKEN', uuidv4(), {
            path: '/',
            secure: true,
            sameSite: 'lax'
        })
    );
};
