import { QueryClient, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import { ApiSubscription, getSubscription } from './apiGetSubscription';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import { SiteParams } from '../index';

const BASE_QUERY_KEY = 'subscription';

type Options = {
    readonly rootProductId: string;
    readonly enabled?: boolean;
};

type QueryParams = SiteParams & {
    readonly userId: string;
    readonly rootProductId: string;
};

const queryKey = ({ siteId, userId, rootProductId }: QueryParams): QueryKey => [
    BASE_QUERY_KEY,
    { siteId, userId, rootProductId /* language is irrelevant for active loans and reserves */ }
];

export const useSubscription = ({
    rootProductId,
    enabled = true
}: Options): UseQueryResult<ApiSubscription | undefined> => {
    const { siteId, language } = useSiteParams();
    const { userId } = usePatronInfo();
    const queryParams = { siteId, userId, rootProductId };

    return useQuery({
        queryKey: queryKey(queryParams),
        queryFn: () => getSubscription(browserClient, { siteId, language }, { rootProductId }),
        retry: false,
        enabled
    });
};

export const invalidateSubscription = (client: QueryClient) =>
    client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
