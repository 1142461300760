import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { useProductAction, useProductActionDispatch } from './ProductActionContext';
import { FollowUpActionType } from './ProductActionReducer';
import { useAutoBorrowCurrentIssue } from './useAutoBorrowCurrentIssue';
import { useProductActionFromQuery } from './useProductActionFromQuery';
import { SIZES_PRODUCT_DETAILS_COVER, SIZES_PRODUCT_TILE_COVER } from '../../../next-image-sizes';
import { useBorrowMutation } from '../../api/loan/useBorrowMutation';
import { useReserveMutation } from '../../api/loan/useReserveMutation';
import { useBorrowSubscribeMutation } from '../../api/subscriptions/useBorrowSubscribeMutation';
import { useSubscribeMutation } from '../../api/subscriptions/useSubscribeMutation';
import { LoanErrorResponse } from '../../domain/loan';
import { getFullTitle } from '../../domain/product';
import { useRedirect } from '../../hooks/events/useRedirect';
import { customize } from '../../utils/localizations/customize';
import { isPresent } from '../../utils/objectChecks';
import { pathAccess, PRODUCT_BASE_PATH } from '../../utils/routes/paths';
import { getPlaceholderCoverByFormat } from '../../utils/styles/image';
import { Icon } from '../Icon';
import { ModalDialog } from '../ModalDialog';
import { NextImage } from '../next/NextImage';

export const ProductActionModal = () => {
    useProductActionFromQuery();
    return <ProductActionModalInner />;
};

const ProductActionModalInner = () => {
    const { t } = useTranslation();

    const dispatch = useProductActionDispatch();
    const state = useProductAction();

    const { mutate: borrow } = useBorrowMutation();
    const { mutate: reserve } = useReserveMutation();
    const { mutate: subscribe } = useSubscribeMutation();
    const { mutate: borrowSubscribe } = useBorrowSubscribeMutation();
    const autoBorrowCurrentIssue = useAutoBorrowCurrentIssue();

    const redirectTo = useRedirect();
    const { pathname } = useRouter();
    const isProductDetailsPage = pathname.startsWith(PRODUCT_BASE_PATH);

    if (!state.modalVisible) return null;

    const { product, modalTitle, modalButtons, modalWithCoverAndProductTitle, modalMessages } = state;
    const { productId, loanFormat, title, coverUrl, authors, rootProductTitle, rootProductId } = product;

    const placeholderCover = getPlaceholderCoverByFormat(loanFormat);

    const handleClick = (actionType: FollowUpActionType) => {
        dispatch({ type: actionType });

        switch (actionType) {
            case FollowUpActionType.borrowConfirmed:
            case FollowUpActionType.borrowConfirmedSubscribeNotConfirmed:
                borrow(
                    { productId },
                    {
                        onSuccess: () => dispatch({ type: FollowUpActionType.borrowSucceeded }),
                        onError: error => {
                            dispatch({
                                type: FollowUpActionType.borrowFailed,
                                errorKey: (error as LoanErrorResponse)?.error
                            });
                        }
                    }
                );
                break;

            case FollowUpActionType.borrowNotConfirmedSubscribeConfirmed:
                if (rootProductId) {
                    subscribe(
                        { rootProductId },
                        {
                            onSuccess: () => dispatch({ type: FollowUpActionType.subscribeSucceeded }),
                            onError: () => {
                                dispatch({
                                    type: FollowUpActionType.subscribeFailed
                                });
                            }
                        }
                    );
                }
                break;

            case FollowUpActionType.borrowConfirmedSubscribeConfirmed:
                if (rootProductId) {
                    borrowSubscribe(
                        { borrowOptions: { productId }, subscribeOptions: { rootProductId } },
                        {
                            onSuccess: ({ borrowResult, subscribeResult }) => {
                                const isBorrowOk = borrowResult.status === 'fulfilled';
                                const isSubscribeOk = subscribeResult.status === 'fulfilled';

                                if (isSubscribeOk) void autoBorrowCurrentIssue(productId, rootProductId);

                                if (isBorrowOk && isSubscribeOk) {
                                    dispatch({ type: FollowUpActionType.borrowSucceededSubscribeSucceeded });
                                } else if (!isBorrowOk && isSubscribeOk) {
                                    dispatch({
                                        type: FollowUpActionType.borrowFailedSubscribeSucceeded,
                                        errorKey: (borrowResult.reason as LoanErrorResponse)?.error
                                    });
                                } else if (isBorrowOk && !isSubscribeOk) {
                                    dispatch({
                                        type: FollowUpActionType.borrowSucceededSubscribeFailed
                                    });
                                } else if (!isBorrowOk && !isSubscribeOk) {
                                    dispatch({
                                        type: FollowUpActionType.borrowFailedSubscribeFailed,
                                        errorKey: (borrowResult.reason as LoanErrorResponse)?.error
                                    });
                                }
                            }
                        }
                    );
                }
                break;

            case FollowUpActionType.reserveConfirmed:
                reserve(
                    { productId },
                    {
                        onSuccess: () => dispatch({ type: FollowUpActionType.reserveSucceeded }),
                        onError: error => {
                            dispatch({
                                type: FollowUpActionType.reserveFailed,
                                errorKey: (error as LoanErrorResponse)?.error
                            });
                        }
                    }
                );
                break;

            case FollowUpActionType.download:
                void redirectTo(pathAccess(productId));
                break;
        }
    };

    const messages = modalMessages.map(({ messageType, messageKey, options, errorType }) => {
        switch (messageType) {
            case 'info':
                return <p key={messageKey}>{customize(t(`productActionModal.messages.${messageKey}`, options))}</p>;
            case 'error':
                return (
                    <div key={messageKey} className="component error-message">
                        <p>
                            <Icon icon="error-circle" />
                            {customize(
                                t(
                                    [
                                        `productActionModal.error.${errorType}.${messageKey}`,
                                        `productActionModal.error.${errorType}.unspecific`
                                    ],
                                    options
                                )
                            )}
                        </p>
                    </div>
                );
            default:
                return null;
        }
    });

    const buttons = modalButtons.map(({ actionType, label, className, disabled }) => (
        <button key={actionType} className={className} onClick={() => handleClick(actionType)} disabled={disabled}>
            {t(`productActionModal.buttons.${label}`)}
        </button>
    ));

    return (
        <ModalDialog
            className="size-wide"
            isOpen={true}
            onClose={() => handleClick(FollowUpActionType.cancelled)}
            title={t(`productActionModal.title.${modalTitle}`)}
            totalNumberButtons={modalButtons.length}
            buttons={buttons}
        >
            {modalWithCoverAndProductTitle ? (
                <div className="component product-action-tile">
                    <div className="cover">
                        <NextImage
                            alt=""
                            src={coverUrl}
                            sizes={isProductDetailsPage ? SIZES_PRODUCT_DETAILS_COVER : SIZES_PRODUCT_TILE_COVER}
                            priority
                            placeholder="blur"
                            blurDataURL={placeholderCover}
                        />
                    </div>
                    <div className="details">
                        <b>
                            {getFullTitle({
                                title,
                                rootProduct: rootProductTitle ? { title: rootProductTitle } : undefined
                            })}
                        </b>
                        <br />
                        {isPresent(authors) ? authors[0].name : null}
                        <hr />
                        {messages}
                    </div>
                </div>
            ) : (
                <>{messages}</>
            )}
        </ModalDialog>
    );
};
