import {
    arrow,
    flip,
    offset,
    safePolygon,
    shift,
    useDismiss,
    useFloating,
    useHover,
    useInteractions
} from '@floating-ui/react-dom-interactions';
import { useRef, useState } from 'react';

import { DEFAULT_PLACEMENT } from '../utils/constants';

type UseTooltipFloaterParams = {
    readonly onOpenChange?: (open: boolean) => void;
    readonly hoverSafePolygon?: boolean; // defaults to `true`
    readonly hoverEnabled?: boolean; // defaults to `true`
};

export const useTooltipFloater = ({
    onOpenChange,
    hoverSafePolygon = true,
    hoverEnabled = true
}: UseTooltipFloaterParams) => {
    const arrowRef = useRef(null);

    const [open, setOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setOpen(open);

        onOpenChange?.(open);
    };

    const floatingProps = useFloating({
        open,
        onOpenChange: handleOpenChange,
        placement: DEFAULT_PLACEMENT,
        middleware: [offset(10), flip(), shift(), arrow({ element: arrowRef })]
    });

    const { context, reference } = floatingProps;

    const handleClose = hoverSafePolygon !== false ? safePolygon() : null;
    const hover = useHover(context, { enabled: hoverEnabled, move: false, handleClose });
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss]);

    return {
        open,
        setOpen,
        arrowRef,
        context,
        reference,
        floatingProps,
        getReferenceProps,
        getFloatingProps
    };
};
