import { QueryClient, useQuery } from '@tanstack/react-query';

import { fetchProductDetails } from './apiFetchProductDetails';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { SiteParams } from '../index';

const QUERY_KEY = 'productDetails';

type QueryParameters = {
    readonly productId: string | undefined;
};

type Options = QueryParameters & { readonly enabled?: boolean };

type BuildQueryKeyParams = {
    readonly siteId?: string | null;
    readonly productId: string | undefined;
};

const buildQueryKey = ({ siteId, productId }: BuildQueryKeyParams) => {
    return [QUERY_KEY, productId, { siteId }];
};

/**
 * Fetches the product details for the given product id.
 */
export function useProductDetails({ productId, enabled = true }: Options) {
    const { siteId, language } = useSiteParams();

    return useQuery({
        queryKey: buildQueryKey({ productId, siteId }),
        queryFn: () => {
            if (productId !== undefined) {
                return fetchProductDetails(browserClient, {
                    productId,
                    siteId,
                    language
                });
            }
        },
        enabled: enabled && productId !== undefined
    });
}

type PrefetchProductDetailsOptions = {
    readonly productId: string;
    readonly fetchFunc?: typeof fetchProductDetails;
};

export const prefetchProductDetails = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    { productId, fetchFunc = fetchProductDetails }: PrefetchProductDetailsOptions,
    { siteId, language }: SiteParams
) => {
    return queryClient.fetchQuery({
        queryKey: buildQueryKey({ productId, siteId }),
        queryFn: () =>
            fetchFunc(apiClient, {
                productId,
                siteId,
                language
            })
    });
};
