import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltip } from './floater/tooltip/ElementWithTooltip';
import { Icon } from './Icon';
import { useReadingLists } from '../api/reading-list/useReadingLists';
import { useRedirect } from '../hooks/events/useRedirect';
import { useUserLoggedIn } from '../hooks/getters/useUserLoggedIn';
import { loginRoutePath } from '../pages/login/[[...any]]';
import { stringifyQuery } from '../utils/routes/queryString';
import { extractBasePath, filterNonProductListQueryParams } from '../utils/routes/routing';

export const ADD_TO_LIST_ACTION = 'addToList';

type Props = {
    readonly productId: string;
    readonly className?: string;
    readonly defaultClassNameOverride?: string;
    readonly onClick?: () => void;
    readonly ariaDescribedBy?: string;
};

export const MyListButton: FC<Props> = ({
    productId,
    className,
    defaultClassNameOverride,
    onClick,
    ariaDescribedBy
}) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { isOnReadingLists, addToReadingLists, removeFromReadingLists } = useReadingLists();
    const isUserLoggedIn = useUserLoggedIn();
    const redirectTo = useRedirect();

    const handleClick = useCallback(() => {
        onClick?.();

        if (!isUserLoggedIn) {
            const newQuery = filterNonProductListQueryParams(router.query);
            newQuery[ADD_TO_LIST_ACTION] = productId;
            const newUrl = `${extractBasePath(router.asPath)}?${stringifyQuery(newQuery)}`;
            void redirectTo(loginRoutePath({ redirectTo: newUrl }));
        } else {
            if (isOnReadingLists(productId)) {
                removeFromReadingLists(productId);
            } else {
                addToReadingLists(productId);
            }
        }
    }, [
        onClick,
        isUserLoggedIn,
        router.query,
        router.asPath,
        productId,
        redirectTo,
        isOnReadingLists,
        removeFromReadingLists,
        addToReadingLists
    ]);

    const tooltip = isOnReadingLists(productId)
        ? t('reading_lists.my_list.tooltip.remove_from_list')
        : t('reading_lists.my_list.tooltip.add_to_list');

    return (
        <ButtonWithTooltip
            tooltip={tooltip}
            className={classNames(
                { 'icon-positive': isOnReadingLists(productId) },
                defaultClassNameOverride ?? 'button-text',
                className
            )}
            aria-label={tooltip}
            aria-describedby={ariaDescribedBy}
            onClick={handleClick}
        >
            <Icon icon={isOnReadingLists(productId) ? 'check' : 'add'} />
            &nbsp;
            {t('reading_lists.my_list.label')}
        </ButtonWithTooltip>
    );
};
