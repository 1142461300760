export type PlacementBase = 'bottom' | 'left' | 'top' | 'right';

export const DEFAULT_PLACEMENT = 'bottom';

export const PLACEMENT_MIRRORED: Record<PlacementBase, PlacementBase> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
};
