import slugify from 'slugify';

import { GenreId } from '../../api/genres';
import { Availability, RootProduct } from '../../domain/product';
import { LoanFormatType } from '../domain/loanFormat';
import { isBlank, isPresent } from '../objectChecks';

export const HOME_PATH = '/';
export const HOME_DISCOVER_PATH = HOME_PATH;
export const PRODUCT_BASE_PATH = '/product';
export const MY_LOANS_PATH = '/home/my-loans';
export const MY_READING_LISTS_PATH = '/home/my-reading-lists';
export const ACCESS_PATH = '/home/my-loans/access';
export const SEARCH_PATH = '/search';
export const ADVANCED_SEARCH_PATH = '/advanced-search';
export const REGISTER_PATH = '/register';
export const HOWTO_PATH = '/howto';
export const TOS_ROUTE_PATH = '/tos';
export const PRIVACY_ROUTE_PATH = '/privacy';
export const COPYRIGHT_ROUTE_PATH = '/copyright';
export const ACCESSIBILITY_ROUTE_PATH = '/accessibility';
export const HELP_ROUTE_PATH = '/help';
export const SITE_MAP_ROUTE_PATH = '/sitemap';
export const MY_ACCOUNT_PATH = '/home/my-account';
export const LOGIN_PATH = '/login';
export const PASSWORD_REQUEST_PATH = '/password/request';
export const PASSWORD_REQUEST_EMAIL_SENT_PATH = '/password/emailsent';
export const NOT_FOUND_PATH = '/404';
const HEALTH_PATH = '/health';

export const pathProductDetailsShort = (productId: string) => `/product/${productId}`;

export const pathProductDetails = (product: {
    readonly productId: string;
    readonly title: string;
    readonly rootProduct?: RootProduct;
    readonly availability?: Availability;
}) => {
    const productId = product.availability?.latestAvailableIssueId ?? product.productId;
    const title = product.rootProduct ? product.rootProduct.title : product.title;

    return `/product/${productId}/title/${normalizeSlug(title)}`;
};

export const pathProductGroup = (productGroupId: number, productGroupName: string) =>
    `/product-group/${productGroupId}/${normalizeSlug(productGroupName)}`;

export const pathProductMoreFromGenres = (productId: string, loanFormat: string) =>
    `/product/${productId}/more-from-genres/${loanFormat}`;
export const pathProductMoreFromPerformers = (productId: string, loanFormat: string) =>
    `/product/${productId}/more-from-performers/${loanFormat}`;
export const pathProductMoreFromSeries = (productId: string, loanFormat: string) =>
    `/product/${productId}/more-from-series/${loanFormat}`;
export const pathProductOtherIssues = (productId: string) => `/product/${productId}/other-issues`;
export const pathProductIssuesByYear = (rootProductId: string, year: number) =>
    `/product/${rootProductId}/issues/${year}`;

export const pathGenre = (genreId: GenreId, genreName: string): string =>
    `/genres/${genreId}/${normalizeSlug(genreName)}`;

export const pathAccess = (productId: string) => `/home/my-loans/access/${productId}`;
export const pathRead = (productId: string) => `/read/${productId}`;
export const pathReadNew = (productId: string) => `/read-new/${productId}`;

export const pathPerformer = (performerId: string, name: string) => `/performer/${performerId}/${normalizeSlug(name)}`;
export const pathPerformerMoreFrom = (performerId: string, loanFormat: LoanFormatType) =>
    `/performer/${performerId}/more-from/${loanFormat.toLowerCase()}`;

export const pathHistoricalLoans = (loanFormat: LoanFormatType) =>
    `/home/my-loans/historical/${loanFormat.toLowerCase()}`;

export const pathMyReadingListByFormat = (loanFormat: LoanFormatType) =>
    `${MY_READING_LISTS_PATH}/${loanFormat.toLowerCase()}`;

export const pathSeries = (seriesId: string, seriesName: string) => `/series/${seriesId}/${normalizeSlug(seriesName)}`;

export const pathLoanDetails = (rootProductId: string) => `/home/my-loans/loan-details/${rootProductId}`;

const SLUGIFY_OPTIONS = {
    replacement: '-', //default: '-'
    remove: /()/g, // Remove special characters, default if undefined: /[^\w\s$*_+~.()'"!\-:@]+/g
    lower: true, //default: false
    strict: true, //default: false, if true only allows characters, numbers and whitespace (removes /[^A-Za-z0-9\s]/g)
    locale: 'en', // Always use english to have consistent urls across all languages
    trim: true //default: true
};

function normalizeSlug(slug: string): string {
    if (isBlank(slug)) return '-';

    const slugified = slugify(slug, SLUGIFY_OPTIONS);

    // with our current slugify-Options, we already produce a valid URL
    // however, we produce a URL here and URLs should always be encoded to be on the safe side
    const encoded = encodeURIComponent(slugified);

    return isPresent(encoded) ? encoded : '-';
}

export function isPathNonBorrowBoxRoute(path: string) {
    return [HEALTH_PATH].includes(path);
}
