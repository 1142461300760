import { useEffect, useState } from 'react';

const SCREEN_XS = 0;
const SCREEN_SM = 576;
const SCREEN_MD = 768;
const SCREEN_LG = 992;
const SCREEN_XL = 1560;
const SCREEN_SPECIAL_1000 = 1000;

export enum MediaQuery {
    MinXs = 'MinXs',
    MinSm = 'MinSm',
    MinMd = 'MinMd',
    MinLg = 'MinLg',
    MinXl = 'MinXl',
    MinSpecial1000 = 'MinSpecial1000',
    MaxXs = 'MaxXs',
    MaxSm = 'MaxSm',
    MaxMd = 'MaxMd',
    MaxLg = 'MaxLg',
    MaxXl = 'MaxXl'
}

export const BREAKPOINT_QUERIES: Record<MediaQuery, string> = {
    [MediaQuery.MinXs]: `(min-width: ${SCREEN_XS}px)`,
    [MediaQuery.MinSm]: `(min-width: ${SCREEN_SM}px)`,
    [MediaQuery.MinMd]: `(min-width: ${SCREEN_MD}px)`,
    [MediaQuery.MinLg]: `(min-width: ${SCREEN_LG}px)`,
    [MediaQuery.MinXl]: `(min-width: ${SCREEN_XL}px)`,
    [MediaQuery.MinSpecial1000]: `(min-width: ${SCREEN_SPECIAL_1000}px)`,

    [MediaQuery.MaxXs]: `(max-width: ${SCREEN_XS - 1}px)`,
    [MediaQuery.MaxSm]: `(max-width: ${SCREEN_SM - 1}px)`,
    [MediaQuery.MaxMd]: `(max-width: ${SCREEN_MD - 1}px)`,
    [MediaQuery.MaxLg]: `(max-width: ${SCREEN_LG - 1}px)`,
    [MediaQuery.MaxXl]: `(max-width: ${SCREEN_XL - 1}px)`
};

/**
 * Runs a names media query, which causes re-renders when the result changes.
 *
 * Media queries match breakpoints in Gild.
 *
 * NOTE: The server is not able to evaluate media queries. If you use this for conditional rendering, it very likely
 * means that the browser has to immediately render again, which is not good for performance. Please only use this if
 * the initial value has no impact on the initial render, e.g. because it only affects popups triggered on user
 * interaction.
 */
export function useMediaQuery<T>(queryName: MediaQuery, initialValue: T): boolean | T {
    const query = BREAKPOINT_QUERIES[queryName];
    const [match, setMatch] = useState<T | boolean>(initialValue);

    useEffect(() => {
        // Only call matchMedia inside useEffect to make the first render on the client match the render on the server.
        // Without this React cannot hydrate properly.
        const mediaQuery = window.matchMedia(query);
        setMatch(mediaQuery.matches);

        const onChange = ({ matches }: MediaQueryListEvent) => setMatch(matches);

        // DO NOT USE addEventListener / removeEventLister, it breaks in mobile Chrome and other browsers
        mediaQuery.addListener(onChange);
        return () => mediaQuery.removeListener(onChange);
    }, [query]);

    return match;
}
