import compact from 'lodash/compact';
import { useTranslation } from 'react-i18next';

import { ProductsPageType } from '../../components/layout/header/Navigation';
import { useLibraryContext } from '../../context/LibraryContext';
import { AgeCategoryType } from '../../domain/ageCategory';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { i18nAgeCategoryMap, i18nLoanFormatMap, i18nPageTypeMap } from '../../utils/localizations/i18nextMaps';

export enum PageTitleFormats {
    featured,
    explore,
    genres,
    all,
    genre,
    productGroup,
    searchResults,
    login,
    reader,
    read,
    listen
}

export type UsePageTitleProps =
    | {
          readonly page:
              | PageTitleFormats.featured // Featured {format}[ - {ageCategory}]
              | PageTitleFormats.explore // Explore {format}[ - {ageCategory}]
              | PageTitleFormats.genres // {format} by Genre[ - {ageCategory}]
              | PageTitleFormats.all;
          readonly loanFormat?: LoanFormatType;
          readonly ageCategory?: AgeCategoryType;
          readonly genre?: never;
          readonly subGenre?: never;
          readonly title?: never;
      }
    | {
          readonly page: PageTitleFormats.genre; // {genre}[ > {subgenre}] {format}[ - {ageCategory}]
          readonly loanFormat?: LoanFormatType;
          readonly ageCategory?: AgeCategoryType;
          readonly genre?: string;
          readonly subGenre?: string;
          readonly title?: never;
      }
    | {
          readonly page:
              | PageTitleFormats.searchResults // Search Results
              | PageTitleFormats.login; // Login
          readonly loanFormat?: never;
          readonly ageCategory?: never;
          readonly genre?: never;
          readonly subGenre?: never;
          readonly title?: never;
      }
    | {
          readonly page:
              | PageTitleFormats.reader // {title} | Read
              | PageTitleFormats.read // {title} | Read
              | PageTitleFormats.listen; // {title} | Listen
          readonly loanFormat?: never;
          readonly ageCategory?: never;
          readonly genre?: never;
          readonly subGenre?: never;
          readonly title?: string;
      }
    | {
          readonly page?: never;
          readonly loanFormat?: never;
          readonly ageCategory?: never;
          readonly genre?: never;
          readonly subGenre?: never;
          readonly title?: string;
      };

export const usePageTitle = (params: UsePageTitleProps) => {
    const baseTitle = usePageBaseTitle();
    const pagePrefix = usePagePrefix(params);

    return compact([pagePrefix, baseTitle]).join(' | '); // i.e All eBooks - Children | Brisbane Libraries - BorrowBox
};

const usePageBaseTitle = () => {
    const { frontendSiteName } = useLibraryContext();
    const productName = 'BorrowBox'; // must be static - translations cannot be loaded when library context is missing

    return compact([frontendSiteName, productName]).join(' - '); // i.e. Brisbane Libraries - BorrowBox
};

const usePagePrefix = ({ page, loanFormat, ageCategory, genre, subGenre, title }: UsePageTitleProps) => {
    const { t } = useTranslation();

    const format = loanFormat ? t(`defaults.plural.${i18nLoanFormatMap[loanFormat]}`) : null;
    const hasAgeCategory = !!ageCategory && ageCategory !== AgeCategoryType.allAges;
    const age = hasAgeCategory ? `- ${t(`age_categories.${i18nAgeCategoryMap[ageCategory!]}`)}` : null;

    switch (page) {
        case PageTitleFormats.featured:
        case PageTitleFormats.explore:
            const pageType = page === PageTitleFormats.featured ? ProductsPageType.Featured : ProductsPageType.Explore;
            return combine([t(`header.nav.pages.${i18nPageTypeMap[pageType]}`), format, age]);
        case PageTitleFormats.genres:
            return combine([format, t('page_titles.by_genre'), age]);
        case PageTitleFormats.genre:
            const subGenrePartial = subGenre ? `> ${subGenre}` : null;
            return combine([genre, subGenrePartial, format, age]);
        case PageTitleFormats.all:
            return combine([t('page_titles.all'), loanFormat === LoanFormatType.eMagazines ? 'ePress' : format, age]);
        case PageTitleFormats.login:
            return t('page_titles.login');
        case PageTitleFormats.searchResults:
            return t('page_titles.search_results');
        case PageTitleFormats.reader:
            return compact([title, t('page_titles.read')]).join(' | ');
        case PageTitleFormats.read:
            return compact([title, t('page_titles.read')]).join(' | ');
        case PageTitleFormats.listen:
            return compact([title, t('page_titles.listen')]).join(' | ');
        default:
            return title;
    }
};

const combine = (partials: Array<string | null | undefined>): string => {
    return compact(partials).join(' ');
};
