import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const CLOSE_PREVIEW_MESSAGE = 'close preview';

type HeaderPreviewProps = {
    readonly title: string;
};

export const HeaderPreview: FC<HeaderPreviewProps> = ({ title }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="left" />
            <div className="center">
                <h1 className="product-title">{title}</h1>
            </div>
            <div className="right">
                <button className="button-neutral size-standalone" onClick={postCloseMessage}>
                    {t('preview.modal_close_button')}
                </button>
            </div>
        </>
    );
};

export const postCloseMessage = () => window?.top?.postMessage(CLOSE_PREVIEW_MESSAGE, '*');
