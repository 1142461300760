import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isDeprecatedBrowser } from '../../utils/browserslist/isDeprecatedBrowser';
import { NoSsr } from '../../utils/nextjs/NoSsr';

const SESSION_STORAGE_KEY = 'unsupported browser confirmed';

export const UnsupportedBrowserBanner = NoSsr(() => {
    const { t } = useTranslation();

    const [isConfirmed, setIsConfirmed] = useState<boolean>(!!sessionStorage.getItem(SESSION_STORAGE_KEY));

    const onAccept = useCallback(() => {
        setIsConfirmed(true);
        sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    }, []);

    if (!isDeprecatedBrowser() || isConfirmed) return null;

    return (
        <>
            <section className="alert-banner" aria-label={t('unsupported_browser.banner.label')}>
                <div className="text">{t('unsupported_browser.banner.text')}</div>
                <div className="actions">
                    <button onClick={onAccept} className="button-white size-large">
                        {t('unsupported_browser.banner.buttons.accept')}
                    </button>
                </div>
            </section>
        </>
    );
});
