import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderNavigationFirstLine } from './HeaderNavigationFirstLine';
import { HomeNavigation, ProductsNavigation } from './HeaderNavigationSecondLine';
import { ProductsPageType } from './Navigation';
import { AgeCategoryType } from '../../../domain/ageCategory';
import { Protected } from '../../../page-components/Protected';
import { UserPermission } from '../../../utils/domain/authorization';
import { LoanFormatType } from '../../../utils/domain/loanFormat';
import { Skippable } from '../../Skippable';

const SkipToMain: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    return <Skippable title={t('header.sr.skip_to_main')}>{children}</Skippable>;
};

export const DefaultHeader: FC = () => {
    return (
        <SkipToMain>
            <header>
                <HeaderNavigationFirstLine loanFormat={undefined} ageCategory={undefined} pageType={undefined} />
            </header>
        </SkipToMain>
    );
};

export const HomeHeader: FC = () => {
    return (
        <SkipToMain>
            <header>
                <HeaderNavigationFirstLine loanFormat={undefined} ageCategory={undefined} pageType={undefined} />
                <Protected required={UserPermission.ACCESS_GUEST}>
                    <HomeNavigation />
                </Protected>
            </header>
        </SkipToMain>
    );
};

type ProductsHeaderProps = {
    readonly loanFormat: LoanFormatType | undefined;
    readonly ageCategory: AgeCategoryType;
    readonly pageType: ProductsPageType;
    readonly pageSuffix?: string;
};

export const ProductsHeader: FC<ProductsHeaderProps> = ({ loanFormat, ageCategory, pageType, pageSuffix }) => {
    return (
        <SkipToMain>
            <header>
                <HeaderNavigationFirstLine loanFormat={loanFormat} ageCategory={ageCategory} pageType={pageType} />
                <ProductsNavigation
                    loanFormat={loanFormat}
                    ageCategory={ageCategory}
                    pageType={pageType}
                    pageSuffix={pageSuffix}
                />
            </header>
        </SkipToMain>
    );
};
