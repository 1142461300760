import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { ApiAllLoanStatusResponse, isApiAllLoanStatusResponse } from '../../api/loan/apiGetAllLoanStatus';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../getters/useSiteParams';

/**
 * This hook is used to broadcast the loan status to other tabs. Calls for loan status are expensive, so we want to
 * avoid calling them too often. It uses the BroadcastChannel API. If the API is not supported, this hook does nothing.
 */
export const useLoanStatusBroadcast = (queryKey: QueryKey) => {
    const [initialized, setInitialized] = useState(false);
    const queryClient = useQueryClient();
    const { siteId } = useSiteParams();
    const { userId } = usePatronInfo();

    const getChannel = useCallback(() => {
        const supportBroadcastChannel = 'BroadcastChannel' in window;

        if (!supportBroadcastChannel) return;

        return new BroadcastChannel(`loan-status::${siteId}-${userId}`);
    }, [siteId, userId]);

    const broadcastLoanStatus = (allLoanStatus: ApiAllLoanStatusResponse) => {
        const channel = getChannel();

        if (!channel) return;

        channel.postMessage(allLoanStatus);
        channel.close();
    };

    useEffect(() => {
        const channel = getChannel();

        if (!channel) return;

        if (!initialized) channel.postMessage('request');

        // If we don't get a response within 100ms, we assume that the other tabs are not active. This is to avoid
        // waiting for a response indefinitely.
        // The average response time from other tabs were 20-40ms with slowed down CPU. So 100ms should be more than
        // enough. Even if it's not, the worst that can happen is that an unnecessary call is made, which is fine.
        const timeoutId = setTimeout(() => {
            if (!initialized) setInitialized(true);
        }, 100);

        channel.onmessage = ({ data }) => {
            if (data === 'request') {
                const allLoanStatus = queryClient.getQueryData<ApiAllLoanStatusResponse>(queryKey);
                if (allLoanStatus) channel.postMessage(allLoanStatus);
                return;
            }

            if (isApiAllLoanStatusResponse(data)) {
                queryClient.setQueryData(queryKey, data);
                setInitialized(true);
                return;
            }
        };

        return () => {
            channel.close();
            clearTimeout(timeoutId);
        };
    }, [initialized, getChannel, queryClient, queryKey]);

    return { initialized, broadcastLoanStatus };
};
