import { ApiLoanInfo } from './apiGetLoan';
import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

export function getLoanDetails(
    client: ApiClient,
    rootProductId: string,
    { siteId, language }: SiteParams
): Promise<ApiLoanInfo> {
    return client
        .get<ApiLoanInfo>(`/api/v1/loan-details/by-root-product/${rootProductId}`, { siteId, language })
        .then(res => res.data);
}
