import { ApiLoanInfo } from './apiGetLoan';
import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

export type ApiActiveLoansAndReservesResponse = {
    loans: ReadonlyArray<ApiLoanInfo>;
};

export function fetchActiveLoansAndReserves(
    client: ApiClient,
    params: SiteParams
): Promise<ApiActiveLoansAndReservesResponse> {
    return client.get<ApiActiveLoansAndReservesResponse>('/api/v1/loans', params).then(result => result.data);
}
