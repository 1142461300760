import { Resource } from 'i18next';
import merge from 'lodash/merge';

import { ApiLocalizationResponse } from '../../api/apiLocalization';
import { PropertyServiceNamespace, propertyServiceToI18nextNamespace } from '../../setup/i18n';
import { isEmpty } from '../objectChecks';

type ResourceTranslation = { [key: string]: string | undefined | ResourceTranslation };

export function transformLocalizations(properties: Array<ApiLocalizationResponse>): Resource {
    // The keys need to match the namespaces in the property service.
    let i18nextProperties: { [P in PropertyServiceNamespace]?: any } = {};

    const mergedProperties = properties.reduce(
        (acc, cur) => ({
            lang: cur.lang,
            text: {
                ...acc.text,
                ...cur.text
            }
        }),
        { text: {}, lang: '' }
    );
    const propertiesEntries = Object.entries(mergedProperties.text);

    propertiesEntries.forEach(([key, translation]) => {
        // A key is considered incomplete if it has a leaf and a subtree exists on the same level. See test for example.
        const someIncompleteEntry = propertiesEntries.find(([k]) => k.startsWith(key + '.'));

        const keyChain = key.split('.');

        if (someIncompleteEntry) {
            throw new Error(
                `Inconsistent entry in localization found: ${key}` +
                    `has an entry itself, but also leaves. e.g.: ${someIncompleteEntry[0]}`
            );
        } else {
            i18nextProperties = merge(i18nextProperties, breakKeyChain(keyChain, translation!));
        }
    });

    const i18nextTranslationsWithoutLang = (Object.keys(i18nextProperties) as Array<PropertyServiceNamespace>).reduce(
        (acc, cur) => {
            return {
                ...acc,
                [propertyServiceToI18nextNamespace[cur]]: i18nextProperties[cur]
            };
        },
        {}
    );

    return {
        [mergedProperties.lang]: i18nextTranslationsWithoutLang
    };
}

function breakKeyChain(keyChain: Array<string>, value: string): ResourceTranslation {
    let result: ResourceTranslation = {};
    const current = keyChain.shift();

    if (!current) return result;

    result[current] = isEmpty(keyChain) ? value : breakKeyChain(keyChain, value);

    return result;
}
