import classNames from 'classnames';
import reduce from 'lodash/reduce';
import { cloneElement, createElement, Fragment, ReactChild, ReactElement, ReactNode } from 'react';

import { postCloseMessage } from '../../page-components/reader/shared/components/HeaderPreview';
import { isEmpty, isPresent } from '../objectChecks';
import {
    HELP_ROUTE_PATH,
    HOME_PATH,
    MY_LOANS_PATH,
    PRIVACY_ROUTE_PATH,
    PRODUCT_BASE_PATH,
    TOS_ROUTE_PATH
} from '../routes/paths';

enum CustomizationType {
    Url = 'url',
    Style = 'style',
    Attribute = 'attribute',
    History = 'history',
    Preview = 'preview'
}

type Customization = (element: ReactElement, param: string) => ReactElement;

const SUPPORTED_STYLES: { [name: string]: string } = {
    green: 'f-green',
    orange: 'f-orange',
    red: 'f-red',
    highlighted: 'f-highlighted',
    muted: 'f-muted',
    underline: 'text-underline',
    nowrap: 'text-nowrap'
};

/**
 * A customization which applies a CSS class to the customized element.
 */
const StyleCustomization: Customization = (element, param) => {
    const styleClassName = SUPPORTED_STYLES[param.toLowerCase()];

    return cloneElement(element, {
        ...element.props,
        className: classNames(element.props.className, styleClassName)
    });
};

const HistoryCustomization: Customization = element => {
    return createElement('a', {
        ...element.props,
        key: element.key,
        href: '#',
        onClick: (e: MouseEvent) => {
            e.preventDefault();
            window.history.back();
        }
    });
};

const PreviewCustomization: Customization = element => {
    return createElement('a', {
        ...element.props,
        key: element.key,
        href: '#',
        onClick: (e: MouseEvent) => {
            e.preventDefault();
            postCloseMessage();
        }
    });
};

/**
 * A customization which replaces the element with a link to a given URL.
 */
const LinkCustomization: Customization = (element, param) => {
    return createElement('a', { ...element.props, key: element.key, href: extractHref(param) });
};

const MARKUP_INTERNAL_LINKS: { [name: string]: string } = {
    home: HOME_PATH,
    tos: TOS_ROUTE_PATH,
    privacy: PRIVACY_ROUTE_PATH,
    'my-loans': MY_LOANS_PATH,
    help: HELP_ROUTE_PATH
};

function extractHref(value: string): string {
    if (isExternalLink(value)) {
        return value;
    } else if (Object.keys(MARKUP_INTERNAL_LINKS).includes(value)) {
        return MARKUP_INTERNAL_LINKS[value];
    } else if (value.startsWith('product-')) {
        return [PRODUCT_BASE_PATH, value.replace('product-', '')].join('/');
    }

    return '';
}

function isExternalLink(value: string): boolean {
    return value.startsWith('http') || value.startsWith('mailto');
}

const SUPPORTED_ATTRIBUTES: { [s: string]: object } = {
    newtab: { target: '_blank' }
};

/**
 * A customization which sets arbitrary attributes on the element.
 */
const AttributeCustomization: Customization = (element, param) => {
    const newAttributes = SUPPORTED_ATTRIBUTES[param.toLowerCase()];

    if (newAttributes) {
        return cloneElement(element, { ...element.props, ...newAttributes });
    }

    return element;
};

/**
 * Represents the metadata of a single customization.
 */
type CustomizationConfig = {
    /**
     * Defines which customization should be applied when this config is
     * evaluated.
     */
    readonly type: CustomizationType;

    /**
     * Some arbitrary params which should be interpreted by the customization
     * when it is applied.
     */
    readonly param: string;
};

/**
 * Parses a customization config such as `"url-http://borrowbox.io/foo-bar"`
 * or `"style-green"` into a config object. The components are separated by the
 * first `"-"` within the source string.
 *
 * For example `"url-http://borrowbox.io/foo-bar"` would be parsed to
 * ```
 * { type: "url", param: "http://borrowbox.io/foo-bar" }
 * ```
 */
function parseCustomizationConfig(raw: string): CustomizationConfig {
    const normalized = (raw ?? '').trim();
    const splitIndex = normalized.indexOf('-');
    const type = normalized.substring(0, splitIndex).toLowerCase() as CustomizationType;
    const param = normalized.substring(splitIndex + 1);
    return { type, param };
}

/**
 * Represents the occurrence of a customized section within a text that should
 * be customized.
 */
type CustomizationMatch = {
    /**
     * Indicates that this occurrence is a customization.
     */
    readonly type: 'CUSTOMIZATION';

    /**
     * The start index (inclusive) of the customization within the source text.
     */
    readonly startIndex: number;

    /**
     * The end index (exclusive) of the customization within the source text.
     */
    readonly endIndex: number;

    /**
     * The value that should be customized.
     *
     * For example if the raw customization source is `"[Foo](style-green)"`,
     * then this will contain the string `"Foo"`.
     */
    readonly value: string;

    /**
     * The parsed customization configs, that describe the customizations which
     * should be applied to the value.
     *
     * For example if the raw customization source is
     * `"[Foo](style-green,url-http://foobar.com)"`, then this will contain the
     * following array:
     * ```
     * [
     *   { type: "style", param: "green" },
     *   { type: "url", param: "http://foobar.com" }
     * ]
     * ```
     */
    readonly configs: ReadonlyArray<CustomizationConfig>;
};

/**
 * Represents the occurrence of a simple text within a text that should be
 * customized.
 */
type TextMatch = {
    /**
     * Indicates that this occurrence is a customization.
     */
    readonly type: 'TEXT';

    /**
     * The start index (inclusive) of this text within the source text.
     */
    readonly startIndex: number;

    /**
     * The end index (exclusive) of this text within the source text.
     */
    readonly endIndex: number;

    /**
     * The text value this occurrence corresponds to.
     */
    readonly value: string;
};

type Match = CustomizationMatch | TextMatch;

/**
 * Parses a text which might contain customizations. The result is a list of
 * tokens that represent the text and customization sections within the text.
 * Each token contains metadata describing itself within the source text, such
 * as the type of the token (TEXT or CUSTOMIZATION), it's start-/end-index and
 * the text value corresponding to the token.
 *
 * For example the text `"Hello World!"` would be parsed to a list with a single
 * text token: `[ TEXT ]`.
 *
 * The text `"Hello [World!](style-red)"` would be parsed to a list with two
 * tokens: `[ TEXT, CUSTOMIZATION ]`.
 */
function parseCustomizations(text: string): ReadonlyArray<Match> {
    const reg = /\[([^\]]*?)]\(([^)(]*)\)/g;

    let prev: RegExpExecArray | null = null;
    let match: RegExpExecArray | null = null;

    const result: Array<Match> = [];

    // eslint-disable-next-line no-cond-assign
    while ((match = reg.exec(text)) !== null) {
        const startIndex = match.index;
        const endIndex = match.index + match[0].length;
        const prevEndIndex = prev ? prev.index + prev[0].length : -1;

        // handle any text which comes before the first match
        if (!prev && match.index > 0) {
            result.push({
                type: 'TEXT',
                startIndex: 0,
                endIndex: startIndex,
                value: text.substring(0, startIndex)
            });
        }

        // handle text between the previous match and the current match
        if (prevEndIndex > 0 && startIndex > prevEndIndex) {
            result.push({
                type: 'TEXT',
                startIndex: prevEndIndex,
                endIndex: startIndex,
                value: text.substring(prevEndIndex, startIndex)
            });
        }

        const value = match[1];
        const options = match[2].split(',').map(it => parseCustomizationConfig(it));

        result.push({
            type: 'CUSTOMIZATION',
            startIndex,
            endIndex,
            value,
            configs: options
        });

        prev = match;
    }

    // handle text which does not contain any customizations
    if (!prev) {
        result.push({
            type: 'TEXT',
            startIndex: 0,
            endIndex: text.length,
            value: text
        });
    }

    const prevEndIndex = prev ? prev.index + prev[0].length : -1;

    // handle any text after the last match
    if (prevEndIndex > 0 && prevEndIndex < text.length) {
        result.push({
            type: 'TEXT',
            startIndex: prevEndIndex,
            endIndex: text.length,
            value: text.substring(prevEndIndex)
        });
    }

    return result;
}

/**
 * Maps customization types to actual customizations.
 */
const CUSTOMIZATIONS: { [T in CustomizationType]: Customization } = {
    [CustomizationType.Style]: StyleCustomization,
    [CustomizationType.Url]: LinkCustomization,
    [CustomizationType.Attribute]: AttributeCustomization,
    [CustomizationType.History]: HistoryCustomization,
    [CustomizationType.Preview]: PreviewCustomization
};

/**
 * Transforms a text which might contain customizations to a React element or a
 * list of React elements/strings which replaces the customizations with
 * elements that have been styled/customized according to the customizations,
 * which they replace.
 *
 * For example the string
 * `"Hello [World](style-green,url-http://www.google.com)!"` would be transformed
 * to the following list:
 * ```
 * [
 *   "Hello ",
 *   <a className="f-green" href="http://www.google.com">World</a>,
 *   "!"
 * ]
 * ```
 */
export function customize(text: string): ReactNode {
    const matches = parseCustomizations(text);

    if (isEmpty(matches)) return null;

    return matches.map((match, index) => applyCustomization(match, index)).map((node, i) => splitNewLines(node, i));
}

function applyCustomization(match: Match, index: number): ReactChild {
    if (match.type === 'CUSTOMIZATION') {
        return reduce(
            match.configs,
            (elem: ReactElement, option) => {
                const customization = CUSTOMIZATIONS[option.type];

                if (customization) {
                    return customization(elem, option.param);
                } else {
                    // ignore any unknown customization
                    return elem;
                }
            },
            createElement('span', { key: `customization-${index}` }, match.value)
        );
    }

    // handle text match
    return match.value;
}

function splitNewLines(node: ReactChild, index: number): ReactNode {
    if (typeof node === 'string') {
        const [firstLine, ...moreLines] = node.split('\n');
        if (isPresent(moreLines)) {
            const children = moreLines.reduce<Array<ReactNode>>(
                (acc, curr) => [...acc, createElement('br', { key: `br-${acc.length}` }), curr],
                [firstLine]
            );
            return createElement(Fragment, { key: `newline-${index}` }, children);
        }
        return node;
    }

    return node;
}
