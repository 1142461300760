export enum UserRole {
    /**
     * The user is authenticated and has completed registration.
     */
    FULL = 'FULL_USER',

    /**
     * The user is authenticated but has not yet completed registration.
     */
    TEMP = 'TEMP_USER',

    /**
     * The user is not authenticated (anonymous)
     */
    GUEST = 'GUEST_USER'
}

export enum UserPermission {
    // TODO this needs to be replaced with actual permissions such as "ACCESS_PRODUCTS_ALL" but this is most probably
    //  a topic for the next release
    ACCESS_FULL = 'ACCESS_FULL',
    ACCESS_TEMP = 'ACCESS_TEMP',
    ACCESS_GUEST = 'ACCESS_GUEST'
}

const PERMISSIONS = {
    [UserRole.FULL]: [UserPermission.ACCESS_FULL, UserPermission.ACCESS_TEMP, UserPermission.ACCESS_GUEST],
    [UserRole.TEMP]: [UserPermission.ACCESS_TEMP, UserPermission.ACCESS_GUEST],
    [UserRole.GUEST]: [UserPermission.ACCESS_GUEST]
};

export function getUserPermissions(role: UserRole): Array<UserPermission> {
    const permissions = PERMISSIONS[role];

    if (!permissions) {
        return [];
    }

    return permissions;
}
