import { FC, forwardRef, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsPageDropdownLink } from './DropdownLink';
import { ProductsPageType } from './Navigation';
import { useLibraryContext } from '../../../context/LibraryContext';
import { AgeCategoryType } from '../../../domain/ageCategory';
import { LoanFormatType } from '../../../utils/domain/loanFormat';
import { i18nAgeCategoryMap } from '../../../utils/localizations/i18nextMaps';
import { selectAvailableAgeCategories } from '../../../utils/navigation/navSelectors';
import { LinkWithPopover } from '../../floater/popover/ElementWithPopover';
import { Icon } from '../../Icon';

interface IAgeCategoryDropdownProps {
    loanFormat: LoanFormatType;
    selectedAgeCategory: AgeCategoryType;
    pageType?: ProductsPageType;
    pageSuffix?: string;
}

export const AgeCategoryDropdown: FC<IAgeCategoryDropdownProps> = ({
    loanFormat,
    selectedAgeCategory,
    pageType,
    pageSuffix
}) => {
    const { t } = useTranslation();
    const toggleHtmlId = useId();

    const libraryContext = useLibraryContext();
    const ageCategories = selectAvailableAgeCategories(libraryContext, loanFormat);
    const currentAgeCategory =
        ageCategories.indexOf(selectedAgeCategory) >= 0 ? selectedAgeCategory : AgeCategoryType.allAges;

    const popover = (
        <div className="component popover-list" role="menu" aria-labelledby={toggleHtmlId}>
            <ul>
                {ageCategories.map((ageCategory, index) => {
                    const selected = ageCategory === currentAgeCategory;
                    return (
                        <DropDownOption
                            key={index}
                            ageCategory={ageCategory}
                            loanFormat={loanFormat}
                            selected={selected}
                            pageType={pageType}
                            pageSuffix={pageSuffix}
                        />
                    );
                })}
            </ul>
        </div>
    );

    return (
        <div className="age-category-dropdown">
            <LinkWithPopover
                popover={popover}
                className="libcolor-text"
                role="button"
                aria-labelledby={toggleHtmlId}
                aria-haspopup="menu"
            >
                <div className="dropdown">
                    <span id={toggleHtmlId}>{t(`age_categories.${i18nAgeCategoryMap[currentAgeCategory]}`)}</span>
                    <Icon icon="caret-down" />
                </div>
            </LinkWithPopover>
            <div className="divider">
                <Icon icon="angle-bracket-outline-right" />
            </div>
        </div>
    );
};

type DropDownOptionProps = {
    readonly key: number;
    readonly ageCategory: AgeCategoryType;
    readonly loanFormat: LoanFormatType;
    readonly selected: boolean;
    readonly pageType: ProductsPageType | undefined;
    readonly pageSuffix: string | undefined;
};

const DropDownOption = forwardRef<HTMLAnchorElement, DropDownOptionProps>(
    ({ key, ageCategory, loanFormat, selected, pageType, pageSuffix, ...props }, ref) => {
        const { t } = useTranslation();
        return (
            <li key={key} className={selected ? 'active' : ''}>
                <ProductsPageDropdownLink
                    {...props}
                    ref={ref}
                    data-bar-style="secondary"
                    className="libcolor-text-active"
                    loanFormat={loanFormat}
                    pageType={pageType}
                    pageSuffix={pageSuffix}
                    ageCategory={ageCategory}
                >
                    {t(`age_categories.${i18nAgeCategoryMap[ageCategory]}`)}
                    {selected ? <Icon icon="check" /> : null}
                </ProductsPageDropdownLink>
            </li>
        );
    }
);
