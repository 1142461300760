import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getProductActionProductData } from './getProductActionProductData';
import { useProductActionDispatch } from './ProductActionContext';
import { isInitialActionType } from './ProductActionReducer';
import { useLoanData } from './useLoanData';
import { useProductDetails } from '../../api/product-details/useProductDetails';
import { useRedirect } from '../../hooks/events/useRedirect';
import { parseLoanFormatType } from '../../utils/domain/loanFormat';
import { isEmpty } from '../../utils/objectChecks';
import { stringifyQuery } from '../../utils/routes/queryString';
import { extractBasePath, filterNonProductListQueryParams } from '../../utils/routes/routing';

export const useProductActionFromQuery = () => {
    const router = useRouter();
    const searchParams = useSearchParams();
    const redirectTo = useRedirect();

    const dispatchProductAction = useProductActionDispatch();

    const productIdFromQuery = searchParams.get('productId') ?? undefined;
    const actionFromQuery = searchParams.get('action');
    const loanFormatFromQuery = searchParams.get('lf');

    const loanData = useLoanData({
        productId: productIdFromQuery,
        loanFormat: loanFormatFromQuery ? parseLoanFormatType(loanFormatFromQuery) : undefined,
        latestAvailableIssueId: undefined
    });

    const { refetch: fetchProduct } = useProductDetails({
        productId: productIdFromQuery,
        enabled: false
    });

    useEffect(() => {
        const getProductAndDispatch = async () => {
            const { data: product } = await fetchProduct();
            if (product && isInitialActionType(actionFromQuery))
                dispatchProductAction({
                    type: actionFromQuery,
                    product: getProductActionProductData(product),
                    loanData
                });
        };

        if (productIdFromQuery) {
            const newQuery = filterNonProductListQueryParams(router.query);
            delete newQuery['action'];
            delete newQuery['productId'];
            const queryString = isEmpty(newQuery) ? '' : `?${stringifyQuery(newQuery)}`;
            const newUrl = `${extractBasePath(router.asPath)}${queryString}`;
            void redirectTo(newUrl, true);

            getProductAndDispatch();
        }
    }, [
        actionFromQuery,
        dispatchProductAction,
        fetchProduct,
        loanData,
        productIdFromQuery,
        redirectTo,
        router.asPath,
        router.query
    ]);
};
