import Script, { type ScriptProps } from 'next/script';
import { FC, useEffect, useState } from 'react';

import {
    getGtagLibScriptSrc,
    getGtagSetupScriptContent,
    GTAG_LIB_SCRIPT_ID,
    GTAG_SETUP_SCRIPT_ID,
    isGtagLibScriptPresent,
    isGtagSetupScriptPresent
} from './helpers';
import { useConsentCookie } from '../layout/footer/cookie-consent/ConsentCookie';

type GtagProps = {
    strategy: ScriptProps['strategy'];
};

export const GtagLibScript: FC<GtagProps> = ({ strategy }) => {
    return <Script async id={GTAG_LIB_SCRIPT_ID} src={getGtagLibScriptSrc()} strategy={strategy} />;
};

export const GtagSetupScript: FC<GtagProps> = ({ strategy }) => {
    return (
        <Script async id={GTAG_SETUP_SCRIPT_ID} strategy={strategy}>
            {getGtagSetupScriptContent()}
        </Script>
    );
};

export const GtagScriptsInjector = () => {
    const [injectGtagScripts, setInjectGtagScripts] = useState(false);
    const { consents } = useConsentCookie();

    useEffect(() => {
        const gtagScriptsAlreadyInjected = isGtagLibScriptPresent() && isGtagSetupScriptPresent();

        if (consents.analytics && !gtagScriptsAlreadyInjected) {
            setInjectGtagScripts(true);
        }
    }, [consents.analytics]);

    return injectGtagScripts ? (
        <>
            <GtagLibScript strategy="afterInteractive" />
            <GtagSetupScript strategy="afterInteractive" />
        </>
    ) : null;
};
