import { QueryClient } from '@tanstack/react-query';
import { i18n } from 'i18next';
import { GetServerSidePropsContext } from 'next';
import { NextPageContext } from 'next/dist/shared/lib/utils';

import { ApiClient, createSSRApiClient } from './axios';
import {
    createInstance,
    DEFAULT_I18NEXT_NAMESPACES,
    DEFAULT_LANGUAGE,
    i18nextNamespace,
    loadI18nResources
} from './i18n';
import { getQueryClient } from './react-query/reactQueryClient';
import { SiteParams } from '../api';
import { ApiPatronInfo, fetchPatronInfo } from '../api/apiFetchPatronInfo';
import { ApiContextLibrary, LanguageSelectionDisplay } from '../api/apiLibraryContext';
import { readCookie } from '../utils/cookies';
import { LoanFormatType, parseLoanFormatType } from '../utils/domain/loanFormat';
import { getSiteContext } from '../utils/domain/siteContext';
import { getSSRUserLocale } from '../utils/localizations/localization';
import LoggingUtils from '../utils/logging/LoggingUtils';

export type SSRContext<B> = {
    readonly ssrClient: ApiClient;
    readonly queryClient: QueryClient;
    readonly siteParams: SiteParams;
    readonly siteContext: ApiContextLibrary;
    readonly patronInfo: ApiPatronInfo | null;
    readonly availableLoanFormats: ReadonlyArray<LoanFormatType>;
    readonly i18n: B extends true ? i18n : null;
};

type InitSSROption<B> = {
    readonly fetchPatronInfo?: boolean;
    readonly fetchTranslations?: B;
    readonly i18nNamespaces?: Array<i18nextNamespace>;
};

export async function initSSR<B extends boolean>(
    context: GetServerSidePropsContext | NextPageContext,
    options: InitSSROption<B> = {
        fetchPatronInfo: false,
        fetchTranslations: false as B,
        i18nNamespaces: DEFAULT_I18NEXT_NAMESPACES
    }
): Promise<SSRContext<B>> {
    const queryClient = getQueryClient();

    const ssrClient = createSSRApiClient(context);

    const siteContext = await getSiteContext(ssrClient, context);

    const { siteId, languageSelectionDisplay } = siteContext;

    const language =
        languageSelectionDisplay !== LanguageSelectionDisplay.NONE
            ? getSSRUserLocale({ context, siteId })
            : DEFAULT_LANGUAGE;

    const siteParams = { siteId, language };

    let availableLoanFormats: ReadonlyArray<LoanFormatType> = [];
    try {
        availableLoanFormats =
            siteContext.navigation?.defaultCollection.loanFormats.map(it => it.name).map(parseLoanFormatType) ?? [];
    } catch (error) {
        LoggingUtils.logSSRError('Invalid loan formats returned from context call', error);
    }

    let patronInfo = null;
    if (options.fetchPatronInfo && hasSessionCookie(context)) {
        try {
            patronInfo = await fetchPatronInfo(ssrClient, siteParams);
        } catch (error) {
            LoggingUtils.logSSRError('failed to fetch patron info in initSSR', error);
        }
    }

    let i18n = null;
    if (options.fetchTranslations) {
        const resources = await loadI18nResources(ssrClient, siteParams, language, options.i18nNamespaces);
        i18n = createInstance(resources);
        i18n.init();
    }

    return {
        ssrClient,
        queryClient,
        siteParams,
        siteContext,
        patronInfo,
        availableLoanFormats,
        i18n: i18n as B extends true ? i18n : null
    };
}

function hasSessionCookie(ctx: GetServerSidePropsContext | NextPageContext) {
    const sessionToken = readCookie('session', ctx.req?.headers.cookie);
    return typeof sessionToken === 'string';
}
