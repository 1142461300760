export enum AgeCategoryType {
    allAges = 'All Ages',
    adult = 'Adult',
    children = 'Children',
    youngAdult = 'Young Adult'
}

const AgeCategoryKeys = Object.keys(AgeCategoryType) as ReadonlyArray<keyof typeof AgeCategoryType>;

export function parseAgeCategoryType(raw: string | undefined): AgeCategoryType {
    if (typeof raw !== 'string' && raw !== undefined) {
        throw new Error(`invalid age category ${raw}`);
    }

    const normalized = normalizeAgeCategoryValue(raw);

    if (!normalized) {
        return AgeCategoryType.allAges;
    }

    const enumKey = AgeCategoryKeys.find(key => {
        const value = AgeCategoryType[key as keyof typeof AgeCategoryType];
        const normalizedValue = normalizeAgeCategoryValue(value);
        return normalizedValue === normalized;
    }) as keyof typeof AgeCategoryType;

    if (enumKey) {
        return AgeCategoryType[enumKey];
    }

    throw new Error(`unknown age category ${raw}`);
}

function normalizeAgeCategoryValue(raw: string | undefined): string {
    return raw ? raw.trim().toLowerCase().replace(/[\W_]/g, '') : '';
}

export function serializeAgeCategory(ageCategory: AgeCategoryType | undefined): string | undefined {
    if (!ageCategory) {
        return undefined;
    }

    switch (ageCategory) {
        case AgeCategoryType.adult:
            return 'adult';
        case AgeCategoryType.youngAdult:
            return 'youngAdult';
        case AgeCategoryType.children:
            return 'children';
        case AgeCategoryType.allAges:
        default:
            return 'allAges';
    }
}
