import { SiteParams } from './index';
import { ApiClient } from '../setup/axios';

export type ApiLocalizationResponse = {
    readonly lang: string;
    readonly text: { [key: string]: string | undefined };
};

export function fetchSiteSpecificLocalization(
    client: ApiClient,
    siteParams: SiteParams
): Promise<ApiLocalizationResponse> {
    return (
        client
            // Property service performs a prefix-search for the value of namespace,
            // so "namespace=website" will also return website_filters.
            .get<ApiLocalizationResponse>(
                `/api/v1/localization/en?namespace=website.&mergeWithDefaults=false`,
                siteParams
            )
            .then(res => res.data)
    );
}
