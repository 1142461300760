import { ShortProduct } from '../../domain/product';
import { ApiClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { SiteParams } from '../index';

export type ApiReadingListProductsByFormat = {
    readonly items: ReadonlyArray<ShortProduct>;
    readonly totalCount: number;
};

export const EMPTY_READING_LIST_BY_FORMAT: ApiReadingListProductsByFormat = {
    items: [],
    totalCount: 0
};

export type ReadingListSortOrder = 'addedToListDateOldToNew' | 'addedToListDateNewToOld';

export const isReadingListSortOrder = (
    sortOrder: string | ReadonlyArray<string> | undefined
): sortOrder is ReadingListSortOrder => {
    return sortOrder === 'addedToListDateOldToNew' || sortOrder === 'addedToListDateNewToOld';
};

/**
 * Represents the request/query parameter which can be included when fetching
 * historical loans.
 */
type ApiReadingListProductsByFormatRequestParams = {
    /**
     * The loan format for which historical loans should be fetched. If no loan
     * format is given, historical loans for all formats should be fetched.
     */
    readonly loanFormat?: LoanFormatType;
    readonly pageSize?: number;
    readonly nextPosition?: number;
    readonly sortOrder?: ReadingListSortOrder;
};

export function fetchReadingListProductsByFormat(
    apiClient: ApiClient,
    { siteId, language, sortOrder, ...params }: ApiReadingListProductsByFormatRequestParams & SiteParams
): Promise<ApiReadingListProductsByFormat> {
    return apiClient
        .get<ApiReadingListProductsByFormat>('/api/v2/readinglist', {
            siteId,
            language,
            params: { ...params, sortOrder: sortOrder === 'addedToListDateOldToNew' ? 'asc' : 'desc' }
        })
        .then(res => res.data);
}
