/*
 * According to
 *
 * https://gs.statcounter.com/screen-resolution-stats/mobile/oceania/2022
 * https://gs.statcounter.com/screen-resolution-stats/mobile/united-kingdom/2022
 * https://gs.statcounter.com/screen-resolution-stats/mobile/europe/2022
 *
 * the most commonly used mobile phones in our key markets have a screen width of 428px or less
 */
const DEVICE_WIDTHS = {
    MED_PHONE: 375,
    MAX_PHONE: 428,
    MED_TABLET: 810,
    MED_TABLET_LANDSCAPE: 1080,
    MED_DESKTOP: 1366,
    MAX_DESKTOP: 1920
};

/**
 * __IMPORTANT INFORMATION ABOUT CHANGING IMAGE SIZES!__
 *
 * The following sizes are used in the BorrowBox web application to determine the size of images. Because the branch
 * deployment (netlify) are loading images from the stage deployment, the image sizes are not updated on branch
 * deployment and will fail to load. Therefore, the e2e snapshots will fail.
 *
 * To fix this and deploy the changes, the following steps are required:
 * 1. Prevent snapshot tests `E2E-Snapshot-Desktop` and `E2E-Snapshot-Mobile` from running actual tests:
 *    - Just put `#` in front of the playwright test command (__not__ the command with `--list` flag)
 * 2. Merge the changes to the `master` branch and deploy the changes (prod and stage deployments will work)
 * 3. Create a new merge request and enable the gitlab jobs `E2E-Snapshot-Desktop` and `E2E-Snapshot-Mobile`
 * 4. Update snapshots as required and then finally merge the changes to the `master` branch
 */
module.exports = {
    SIZES_PRODUCT_TILE_COVER: `(max-width: ${DEVICE_WIDTHS.MAX_PHONE}px) 142px, 214px`,
    SIZES_PRODUCT_DETAILS_COVER: `(max-width: ${DEVICE_WIDTHS.MED_DESKTOP}px) 300px, 325px`,
    SIZES_EAUDIOBOOK_ACCESS_COVER: '300px',
    SIZES_EPAPER_ACCESS_COVER: '300px',
    SIZES_LOANED_TILE_FOCUS_COVER: '222px',
    SIZES_LOANED_TILE_COVER: '200px',
    SIZES_LOANED_TILE_STACK_COVER: '80px',
    SIZES_BANNER_COVER: '168px',
    SIZES_LIBRARY_LOGO: `270px`,
    SIZES_TOP_BANNER:
        `(max-width: ${DEVICE_WIDTHS.MED_PHONE}px) ${DEVICE_WIDTHS.MED_PHONE}px, ` +
        `(max-width: ${DEVICE_WIDTHS.MAX_PHONE}px) ${DEVICE_WIDTHS.MAX_PHONE}px, ` +
        `(max-width: ${DEVICE_WIDTHS.MED_TABLET}px) ${DEVICE_WIDTHS.MED_TABLET}px, ` +
        `(max-width: ${DEVICE_WIDTHS.MED_TABLET_LANDSCAPE}px) ${DEVICE_WIDTHS.MED_TABLET_LANDSCAPE}px, ` +
        `(max-width: ${DEVICE_WIDTHS.MED_DESKTOP}px) ${DEVICE_WIDTHS.MED_DESKTOP}px, ` +
        `${DEVICE_WIDTHS.MAX_DESKTOP}px`,
    SIZES_AUTHOR_TILE: '35px',
    SIZES_AUTHOR_BANNER: '200px',
    SIZES_GENRE_BANNER: '600px',
    DEVICE_SIZES: [375, 428, 810, 1080, 1366, 1920, 750, 856, 1620, 2160, 2732, 3840],
    IMAGE_SIZES: [35, 80, 142, 168, 200, 214, 222, 284, 300, 336, 400, 428, 444, 600]
};
