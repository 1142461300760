import Head from 'next/head';

import { usePageTitle, UsePageTitleProps } from '../hooks/getters/usePageTitle';

// TODO: set description default or set description in <PageTitle /> on _app.tsx

/*const iPhoneDevices = require('../../assets/images/iPhoneDevices.png');*/ // TODO: use image provided by Jan

type ShareMetaProps = {
    readonly description?: string | null;
    readonly coverSrc?: string;
};

export const PageTitleShareMeta = (params: UsePageTitleProps & ShareMetaProps) => {
    const { description, coverSrc } = params;
    const pageTitle = usePageTitle(params);

    const fallbackSrc = /*isCSR() ? document.location.origin + iPhoneDevices :*/ null;
    const imageSrc = coverSrc ?? fallbackSrc;

    return (
        <Head>
            <title>{pageTitle}</title>

            <meta name="twitter:card" content="summary" />
            <meta property="og:title" content={pageTitle} />
            {description ? <meta property="og:description" content={description} /> : null}
            {imageSrc ? <meta property="og:image" content={imageSrc} /> : null}
        </Head>
    );
};
