import { FC, PropsWithChildren } from 'react';

export const ErrorPage: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="error-page">
            <img src="/images/borrowBoxLogo.svg" alt="BorrowBox Logo" />
            {children}
        </div>
    );
};
