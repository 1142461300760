import {
    flip,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useListNavigation
} from '@floating-ui/react-dom-interactions';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { ComponentProps, FC, ReactNode, useEffect, useRef, useState } from 'react';

import { Wrapper } from './components/Wrapper';
import { BREAKPOINT_QUERIES, MediaQuery } from '../../../utils/styles/breakpoints';
import { DEFAULT_PLACEMENT } from '../tooltip/utils/constants';

type ElementWithPopoverBaseProps = {
    readonly popover: ReactNode;
    readonly activeClassName?: string;
    readonly setPopoverOpen?: (visible: boolean) => void;
    readonly closeOnPopoverClick?: boolean;
    readonly placement?: Placement;

    /**
     * The "shift" middleware keeps popovers inside the viewport. The `shiftPadding` parameter adds a padding between
     * popover and viewport border. See https://floating-ui.com/docs/shift#detectoverflowoptions
     */
    readonly shiftPadding?: number;

    /**
     * Keyboard handlers on popover elements will be overwritten.
     * Set to `true` if popover elements already have registered key handlers.
     */
    readonly disableKeyboardNavigation?: boolean;
};

type ButtonWithPopoverProps = { readonly tagName: 'button' } & ElementWithPopoverBaseProps & ComponentProps<'button'>;
type LinkWithPopoverProps = { readonly tagName: 'a' } & ElementWithPopoverBaseProps & ComponentProps<'a'>;

type ElementWithPopoverProps = ButtonWithPopoverProps | LinkWithPopoverProps;

const ElementWithPopover: FC<ElementWithPopoverProps> = ({
    tagName: Tag = 'button',
    popover,
    activeClassName,
    setPopoverOpen,
    closeOnPopoverClick = true,
    placement = DEFAULT_PLACEMENT,
    shiftPadding = 4,
    disableKeyboardNavigation = false,
    className,
    children,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const [currentBreakpoint, setCurrentBreakpoint] = useState<null | 'desktop' | 'mobile'>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const listRef = useRef([]);

    const getBreakpoint = () =>
        window.matchMedia(BREAKPOINT_QUERIES[MediaQuery.MinMd]).matches ? 'desktop' : 'mobile';

    const handleOnOpenChange = (open: boolean) => {
        setOpen(open);
        setPopoverOpen?.(open);
        setCurrentBreakpoint(getBreakpoint);
    };

    const floatingProps = useFloating({
        open,
        onOpenChange: handleOnOpenChange,
        placement,
        middleware: [offset(4), flip(), shift({ padding: shiftPadding })]
    });

    const { context, reference, update } = floatingProps;

    useEffect(() => {
        const debouncedUpdate = debounce(update, 10);
        window.addEventListener('resize', debouncedUpdate);
        return () => window.removeEventListener('resize', debouncedUpdate);
    }, [update]);

    useEffect(() => {
        if (!open) return; // only add resize event listeners when Popover is open

        const handleResize = () => {
            if (currentBreakpoint && currentBreakpoint !== getBreakpoint()) {
                handleOnOpenChange(false); // close Popover when switching from desktop to mobile or vice versa
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const dismiss = useDismiss(context);
    const click = useClick(context);
    const navigation = useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        enabled: !disableKeyboardNavigation
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([dismiss, click, navigation]);

    const handleFloaterClick = () => {
        if (closeOnPopoverClick) handleOnOpenChange(false);
    };

    return (
        <>
            <Tag
                ref={reference}
                aria-expanded={open}
                data-state={open ? 'open' : 'closed'}
                className={classNames(className, open ? activeClassName : null)}
                {...getReferenceProps(props)}
            >
                {children}
            </Tag>
            <Wrapper
                disableKeyboardNavigation={disableKeyboardNavigation}
                open={open}
                floatingProps={floatingProps}
                getItemProps={getItemProps}
                getFloatingProps={getFloatingProps}
                listRef={listRef}
                onClick={handleFloaterClick}
            >
                {popover}
            </Wrapper>
        </>
    );
};

export const ButtonWithPopover = (props: Omit<ButtonWithPopoverProps, 'tagName'>) => {
    return <ElementWithPopover tagName="button" {...props} />;
};

export const LinkWithPopover = (props: Omit<LinkWithPopoverProps, 'tagName'>) => {
    return <ElementWithPopover tagName="a" href="#" {...props} onClick={event => event.preventDefault()} />;
};
