import { useQueryClient } from '@tanstack/react-query';

import { loanProduct } from '../../api/loan/apiLoanProduct';
import { invalidateOnBorrowSuccess } from '../../api/loan/useBorrowMutation';
import { fetchProductDetails } from '../../api/product-details/apiFetchProductDetails';
import { isProductCurrent } from '../../domain/product';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import LoggingUtils from '../../utils/logging/LoggingUtils';

export const useAutoBorrowCurrentIssue = () => {
    const { siteId } = useSiteParams();
    const queryClient = useQueryClient();

    return async (productId: string, rootProductId: string) => {
        try {
            const rootProduct = await fetchProductDetails(browserClient, { productId: rootProductId, siteId });
            const latestAvailableIssueId = rootProduct.availability.latestAvailableIssueId;

            if (!latestAvailableIssueId) return;

            const latestAvailableIssue = await fetchProductDetails(browserClient, {
                productId: latestAvailableIssueId,
                siteId
            });

            if (isProductCurrent(latestAvailableIssue) && latestAvailableIssueId !== productId) {
                const loan = await loanProduct(browserClient, { productId: latestAvailableIssueId, siteId });
                if (loan.success) {
                    await invalidateOnBorrowSuccess(queryClient);
                }
            }
        } catch (error) {
            LoggingUtils.logWarningWithPayload('Auto-borrowing current issue on subscribe failed.', error);
        }
    };
};
