import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { CookieBanner } from './footer/cookie-consent/CookieBanner';
import { Footer } from './footer/Footer';
import { DefaultHeader } from './header/Header';
import { DarkOverlayProvider, useDarkOverlayClassNames } from '../../context/DarkOverlayContext';
import { ProductActionModal } from '../product-action/ProductActionModal';
import { UnsupportedBrowserBanner } from '../unsupported-browser/UnsupportedBrowserBanner';

type BasicLayoutProps = PropsWithChildren<{
    readonly header?: ReactNode;
    readonly className?: string;
}>;

export const BasicLayout: FC<BasicLayoutProps> = ({ children, ...props }) => {
    return (
        <DarkOverlayProvider>
            <BasicLayoutInner {...props}>{children}</BasicLayoutInner>
        </DarkOverlayProvider>
    );
};

const BasicLayoutInner: FC<BasicLayoutProps> = ({ children, header, className }) => {
    const darkOverlayClassNames = useDarkOverlayClassNames();
    return (
        <>
            <div className={classNames('page', className, ...darkOverlayClassNames)}>
                {header || <DefaultHeader />}
                {children}
                <ProductActionModal />
                <UnsupportedBrowserBanner />
                <CookieBanner />
            </div>
            <Footer />
        </>
    );
};
