import '../setup/polyfills/webPolyfills'; // Note: side-effect import
import '../setup/polyfills/jsPolyfills'; // Note: side-effect import
import '../styles/main.scss';

import { DehydratedState, HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { i18n as I18nInstance, Resource } from 'i18next';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';

import NextjsErrorPage from './_error';
import { ApiContextLibrary, ERROR_INACTIVE_SITE, ERROR_UNKNOWN_SITE } from '../api/apiLibraryContext';
import { GtagScriptsInjector } from '../components/analytics/GtagScripts';
import { CookieRefresh } from '../components/CookieRefresh';
import { HandleRequestedAddToListProduct } from '../components/HandleRequestedAddToListProduct';
import {
    ConsentCookie,
    ConsentCookieProvider,
    extractConsentCookie
} from '../components/layout/footer/cookie-consent/ConsentCookie';
import { PathnameTestId } from '../components/PathnameTestId';
import { ProductActionProvider } from '../components/product-action/ProductActionContext';
import { RouteTransitionIndicator } from '../components/RouteTransitionIndicator';
import { UnknownSitePage } from '../components/UnknownSitePage';
import { AuthenticationContext, savePatronInfoQuery } from '../context/AuthenticationContext';
import { CreditsContext, prefetchCreditsQuery } from '../context/CreditsContext';
import { LibraryContextProvider, useLibraryContext } from '../context/LibraryContext';
import { LoanStatusContext } from '../context/LoanStatusContext';
import { usePageTitle } from '../hooks/getters/usePageTitle';
import { AudioPlayerContextProvider } from '../hooks/utils/useAudio';
import { useCatchRouteInProgress } from '../hooks/utils/useCatchRouteInProgress';
import { addBrowserClientRequestInterceptor, ejectBrowserClientRequestInterceptor } from '../setup/axios';
import { createInstance, DEFAULT_LANGUAGE, importLocales, loadI18nResources } from '../setup/i18n';
import { appendInParenthesis, fileSize, simpleList } from '../setup/i18nFormatters';
import { checkIntlPolyfills } from '../setup/polyfills/checkIntlPolyfills';
import { getQueryClient } from '../setup/react-query/reactQueryClient';
import { safeDehydrate } from '../setup/react-query/safeDehydrate';
import { initSentry } from '../setup/sentry';
import { initSSR, SSRContext } from '../setup/ssr';
import { attachXsrfCookie } from '../setup/xsrf';
import { NoSiteAliasError } from '../utils/domain/errors';
import { isIPv4OrAWSDomain } from '../utils/isIPv4';
import { InstanaTrackGlobal } from '../utils/logging/Instana';
import LoggingUtils from '../utils/logging/LoggingUtils';
import { isCSR } from '../utils/nextjs/ssr';
import { isPathNonBorrowBoxRoute, NOT_FOUND_PATH } from '../utils/routes/paths';
import { globalStyle } from '../utils/styles/globalStyle';

initSentry();

type SiteError = 'unknown-site' | 'generic';

type CustomProps = {
    // are provided by us via `CustomApp.getInitialProps`
    readonly isBorrowBoxRoute?: boolean;
    readonly siteContext?: ApiContextLibrary;
    readonly siteError?: SiteError;
    readonly i18nResource?: Resource;
    readonly appDehydratedState?: DehydratedState;
    readonly consentCookie?: ConsentCookie;
    readonly userId?: string;
    readonly clientIp?: string;
};

type CustomAppProps = AppProps & CustomProps;

let clientIpInterceptorId: number | null = null;

const CustomApp = ({
    Component,
    pageProps,
    isBorrowBoxRoute = true,
    siteContext,
    siteError = undefined,
    i18nResource,
    appDehydratedState,
    consentCookie,
    userId,
    clientIp
}: CustomAppProps) => {
    const i18nRef = useRef<I18nInstance | null>(null);
    useCatchRouteInProgress();

    // The pageProps we receive here, are provided by Next.js and the merged result of
    // a) the pageProps returned from `CustomApp.getInitialProps`
    // b) all the props returned from `getServerSideProps` for the currently requested page
    // This will in most cases also contain a `dehydratedState` from b) which we need to explicitly handle here
    // However, we need to pass all pageProps to the actual page (aka `Component`)!
    const { dehydratedState }: { dehydratedState?: DehydratedState } = pageProps;

    // Add the clientIp to the browserClient headers as app-clientIp
    if (clientIp && isCSR()) {
        if (clientIpInterceptorId !== null) ejectBrowserClientRequestInterceptor(clientIpInterceptorId);
        clientIpInterceptorId = addBrowserClientRequestInterceptor(config => {
            config.headers['app-clientIp'] = clientIp;
            return config;
        });
    }

    // Note: be careful when using any hooks etc. in this component, the i18next t-function is not yet available here!

    if (!isBorrowBoxRoute) {
        return (
            <LibraryContextProvider value={siteContext} error={false}>
                <Component {...pageProps} />
            </LibraryContextProvider>
        );
    }

    if (siteError === 'unknown-site') {
        return (
            <LibraryContextProvider value={siteContext} error={true}>
                <AppHeader userId={userId} />
                <UnknownSitePage />
            </LibraryContextProvider>
        );
    }

    if (siteError === 'generic') {
        return (
            <LibraryContextProvider value={siteContext} error={true}>
                <NextjsErrorPage />
            </LibraryContextProvider>
        );
    }

    if (i18nRef.current === null) {
        if (i18nResource === undefined) {
            // This should not actually happen, but if an uncaught error causes the CustomApp to be rendered again then
            // it will be only rendered on the client side and the i18nResource will be undefined, because those are
            // only provided by the initial server side rendering.
            return (
                <LibraryContextProvider value={siteContext} error={true}>
                    <NextjsErrorPage />
                </LibraryContextProvider>
            );
        }

        // 'i18nResource' comes with the initialProps and is thus undefined on client side transitions, so we cannot
        // call 'createInstance(i18nResource)' in that case. That is why we store the i18nInstance created on the first
        // render in a Ref which will survive re-renders caused by client side transitions.
        i18nRef.current = createInstance(i18nResource).use(initReactI18next);
        i18nRef.current.init();
        i18nRef.current.services.formatter?.add('appendInParenthesis', appendInParenthesis);
        i18nRef.current.services.formatter?.add('simpleList', simpleList);
        i18nRef.current.services.formatter?.add('fileSize', fileSize);
    }

    return (
        <LibraryContextProvider value={siteContext} error={false}>
            <AppHeader userId={userId} />
            <BorrowBoxWrapper
                dehydratedState={dehydratedState}
                appDehydratedState={appDehydratedState}
                consentCookie={consentCookie}
                userId={userId}
            >
                <RouteTransitionIndicator />
                <Component {...pageProps} />
            </BorrowBoxWrapper>
        </LibraryContextProvider>
    );
};

type AppHeaderProps = {
    readonly userId?: string;
};

const AppHeader: FC<AppHeaderProps> = ({ userId }) => {
    const libraryContext = useLibraryContext();
    const theme = libraryContext.theme?.design;
    const siteId = libraryContext.siteId;
    const siteName = libraryContext.frontendSiteName;
    const pageTitle = usePageTitle({});

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <meta name="twitter:card" content="summary" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {theme && (
                    <style
                        dangerouslySetInnerHTML={{
                            __html: globalStyle(theme)
                        }}
                    />
                )}
            </Head>
            <InstanaTrackGlobal siteId={siteId} siteName={siteName} userId={userId} />
        </>
    );
};

type BorrowBoxWrapperProps = PropsWithChildren<{
    readonly dehydratedState?: DehydratedState;
    readonly appDehydratedState?: DehydratedState;
    readonly consentCookie?: ConsentCookie;
    readonly userId?: string;
}>;

/**
 * The props for this component will be set during the very first render on the server (see `getInitialProps`).
 * This component should take care of saving these initial values (via state, context, react-query) if they are needed
 * on subsequent renders.
 */
const BorrowBoxWrapper: FC<BorrowBoxWrapperProps> = ({
    children,
    dehydratedState,
    appDehydratedState,
    consentCookie,
    userId
}) => {
    const enableReactQueryDevTools = process.env.NEXT_PUBLIC_ENABLE_QUERY_DEVTOOLS === 'true';
    const [appQueryClient] = useState(() => getQueryClient());

    const [polyfillsOK, setPolyfillsOK] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        checkIntlPolyfills(i18n.language).then(() => setPolyfillsOK(true));
    }, [i18n.language]);

    if (!polyfillsOK) return;

    // We need to merge the dehydrated state from two different sources:
    // - `appDehydratedState` -> initial server side rendering (coming from `CustomApp.getInitialProps`)
    // - `dehydratedState` -> current page (coming from `getServerSideProps`)
    // The dehydrated state from the current page takes precedence over the dehydrated state from the initial server
    // side rendering.
    const dehydratedQueryState: DehydratedState = {
        queries: [...(appDehydratedState?.queries ?? []), ...(dehydratedState?.queries ?? [])],
        mutations: [] // there are no mutations on the server side
    };

    return (
        <QueryClientProvider client={appQueryClient}>
            <HydrationBoundary state={dehydratedQueryState}>
                <ConsentCookieProvider initialConsentCookie={consentCookie}>
                    <GtagScriptsInjector />
                    <CookieRefresh />
                    <HandleRequestedAddToListProduct />
                    <AudioPlayerContextProvider>
                        <ProductActionProvider>
                            <AuthenticationContext initialUserId={userId}>
                                <LoanStatusContext>
                                    <CreditsContext>{children}</CreditsContext>
                                </LoanStatusContext>
                            </AuthenticationContext>
                        </ProductActionProvider>
                    </AudioPlayerContextProvider>
                </ConsentCookieProvider>
            </HydrationBoundary>
            <PathnameTestId />
            {enableReactQueryDevTools ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </QueryClientProvider>
    );
};

type CustomAppInitialProps = AppInitialProps & CustomProps;

/**
 * https://nextjs.org/docs/api-reference/data-fetching/getInitialProps
 *
 * For the initial page load, `getInitialProps` will run on the server only. `getInitialProps` will then run on the
 * client when navigating to a different route via the `next/link` component or by using `next/router`. However, if
 * the page being navigated to implement `getServerSideProps`, then `getInitialProps` will run on the server.
 */
CustomApp.getInitialProps = async (appContext: AppContext): Promise<CustomAppInitialProps> => {
    const clientIp = appContext.ctx.req?.socket.remoteAddress;

    try {
        // calls page's `getInitialProps` and fills `appProps.pageProps`
        const appProps = await App.getInitialProps(appContext);

        if (isNonBorrowBoxRoute(appContext)) {
            return {
                ...appProps,
                isBorrowBoxRoute: false,
                clientIp
            };
        }

        // Accessing with an IP address or aws domain is invalid as no site is configured for those hosts
        if (isIPv4OrAWSDomain(appContext.ctx.req?.headers.host)) {
            return {
                ...appProps,
                siteError: 'unknown-site',
                clientIp
            };
        }

        if (isClientSideTransitionWithoutGetServerSideProps(appContext)) {
            return {
                ...appProps,
                clientIp
            };
        }

        // On subsequent requests from the client (client side page transitions)
        // we only want to execute until here. The code below this if statement
        // is only needed for the very first call and would be ignored on
        // subsequent requests. To save bandwidth and reduce calls to the backend
        // return early in this case.
        //
        //  Example url: '/_next/data/development/ebooks/all.json?loanFormatPath=ebooks'
        if (isClientSideTransitionWithGetServerSideProps(appContext)) {
            // We found another special case for this lovely function :-) When we have a client side
            // transition with get server side props the url looks like this:
            //
            //   '/_next/data/<SOME_HASH>/ebooks/all.json?loanFormatPath=ebooks'
            //
            // The SOME_HASH is unique per build, it changes when we deploy a new version of the
            // website. Now if a user has an older version of the website and navigates to a route
            // with GetServerSideProps, the next router cannot find the path as the new version
            // on the server has a different hash. Therefore, it correctly wants to render a 404 page.
            //
            // On the client the 404 is received, but not rendered. Next.js seems to have a logic that
            // makes a full refresh of the site in case a 404 was returned from a data route. So the user
            // does not see any missing translations. But the 404 page is still rendered on the server.
            //
            // Our 404 page includes text that is translated via i18next. We now have to return the
            // resources (translated strings), otherwise i18next cannot find the keys and therefore
            // not translate the page. This leads to the `missingKeyHandler` firing for each
            // missing key (see i18n.ts) which would spam Sentry and our Slack.
            //
            // Since this page is never shown to the users we just return the fallback localizations, so
            // we don't have to fetch anything from the backend.
            if (appContext.router.route === NOT_FOUND_PATH) {
                return {
                    ...appProps,
                    i18nResource: {
                        [DEFAULT_LANGUAGE]: await importLocales(DEFAULT_LANGUAGE)
                    },
                    clientIp
                };
            }
            return {
                ...appProps,
                clientIp
            };
        }

        // ************************************************************************
        // This code is only executed on the very first call from the client where
        // the HTML is actually rendered on the server!
        // ************************************************************************
        const req = appContext.ctx.req!;
        const res = appContext.ctx.res!;

        attachXsrfCookie(req, res);

        const consentCookie = extractConsentCookie(req.headers.cookie);

        let ssrContext: SSRContext<false>;
        try {
            ssrContext = await initSSR(appContext.ctx, { fetchPatronInfo: true });
        } catch (error) {
            const isUnknownSite =
                (error instanceof AxiosError &&
                    [ERROR_UNKNOWN_SITE, ERROR_INACTIVE_SITE].includes(error.response?.data?.errorKeys[0])) ||
                error instanceof NoSiteAliasError;

            LoggingUtils.logSSRError('failed to fetch library context', error);
            return {
                ...appProps,
                siteError: isUnknownSite ? 'unknown-site' : 'generic',
                consentCookie,
                clientIp
            };
        }

        const { queryClient, ssrClient, siteContext, siteParams, patronInfo } = ssrContext;
        const locale = siteParams.language || DEFAULT_LANGUAGE;

        const i18nResource = await loadI18nResources(ssrClient, siteParams, locale);

        savePatronInfoQuery(queryClient, siteParams, patronInfo ?? undefined);

        if (!patronInfo) {
            return {
                ...appProps,
                siteContext,
                i18nResource,
                appDehydratedState: safeDehydrate(queryClient),
                consentCookie,
                clientIp
            };
        }

        const { userId } = patronInfo;

        try {
            await prefetchCreditsQuery(ssrClient, queryClient, { ...siteParams, userId });
        } catch (error) {
            LoggingUtils.logSSRError('failed to fetch credit info', error);
        }

        return {
            ...appProps,
            siteContext,
            i18nResource,
            appDehydratedState: safeDehydrate(queryClient),
            consentCookie,
            userId,
            clientIp
        };
    } catch (error) {
        LoggingUtils.logSSRError('error occurred in CustomApp.getInitialProps', error);
        return {
            pageProps: {},
            siteError: 'generic',
            isBorrowBoxRoute: false,
            clientIp
        };
    }
};

function isNonBorrowBoxRoute(appContext: AppContext) {
    // special handling for health-check rule: the health check must not be dependent on any API call
    return isPathNonBorrowBoxRoute(appContext.router.asPath);
}

function isClientSideTransitionWithoutGetServerSideProps(appContext: AppContext) {
    return !appContext.ctx.req || !appContext.ctx.res;
}

function isClientSideTransitionWithGetServerSideProps(appContext: AppContext): boolean {
    return appContext.ctx.req?.url?.startsWith('/_next/data/') === true;
}

// noinspection JSUnusedGlobalSymbols
export default CustomApp;
