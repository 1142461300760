import { ProductActionLoanData, ProductActionProductData } from './ProductActionReducer';
import { useCreditsForLoanFormat } from '../../context/CreditsContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { useLoanStatus } from '../../context/LoanStatusContext';
import { LoanFormatType } from '../../utils/domain/loanFormat';

type Props = {
    readonly loanFormat?: ProductActionProductData['loanFormat'];
    readonly productId?: ProductActionProductData['productId'];
    readonly latestAvailableIssueId?: ProductActionProductData['latestAvailableIssueId'];
};

export const useLoanData = ({ loanFormat, productId, latestAvailableIssueId }: Props): ProductActionLoanData => {
    const loanStatus = useLoanStatus({ productId: latestAvailableIssueId ?? productId });

    const loanFormatCredits = useCreditsForLoanFormat(
        loanFormat === LoanFormatType.eMagazines ? LoanFormatType.eMagazineIssues : loanFormat
    );

    const { loanPeriods } = useLibraryContext();
    const loanPeriod = loanPeriods && loanFormat ? loanPeriods[loanFormat] ?? 0 : 0;

    return {
        alreadyOnLoan: loanStatus.active,
        alreadyOnReserve: loanStatus.reserved,
        creditsLeftForLoanFormat: loanFormatCredits.amount,
        creditsGrantedForLoanFormat: loanFormatCredits.granted,
        loanPeriodDays: loanPeriod
    };
};
