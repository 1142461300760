import { ApiClient, ApiResponse } from '../../setup/axios';
import { SiteParams } from '../index';

export function removeProductFromReadingLists(
    apiClient: ApiClient,
    productId: string,
    siteParams: SiteParams
): Promise<ApiResponse<void>> {
    return apiClient.delete(`/api/v1/reading-lists/${productId}`, { ...siteParams });
}
