import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const appleHref = 'https://itunes.apple.com/app/borrowbox-library/id562843562?mt=8';
const googleHref = 'https://play.google.com/store/apps/details?id=com.bolindadigital.BorrowBoxLibrary';
const amazonHref = 'https://www.amazon.com/gp/mas/dl/android?p=com.bolindadigital.BorrowBoxLibrary';

export const AppStoreLinks: FC<{ color: 'Black' | 'White' | 'Transparent' }> = ({ color }) => {
    const { t } = useTranslation();

    return (
        <>
            <AppStoreLink href={appleHref} imgSrc={`/images/apple${color}.svg`} alt={t('app_badges.apple.label')} />
            <AppStoreLink href={googleHref} imgSrc={`/images/google${color}.svg`} alt={t('app_badges.google.label')} />
            <AppStoreLink href={amazonHref} imgSrc={`/images/amazon${color}.svg`} alt={t('app_badges.amazon.label')} />
        </>
    );
};

type AppStoreLinkProps = {
    readonly href: string;
    readonly imgSrc: string;
    readonly alt: string;
};

const AppStoreLink: FC<AppStoreLinkProps> = ({ href, imgSrc, alt }) => {
    return (
        <a href={href} className="image-button" target="_blank" rel="noopener noreferrer">
            <img src={imgSrc} loading="lazy" alt={alt} />
        </a>
    );
};
