import isEqual from 'lodash/isEqual';
import { ComponentType, forwardRef, memo, ReactNode } from 'react';

import { IProductsPageRef, productsPagePath, ProductsPageType } from './layout/header/Navigation';
import { NavLink } from './layout/header/NavLink';
import { LibraryContextValue, useLibraryContext } from '../context/LibraryContext';
import { AgeCategoryType } from '../domain/ageCategory';
import { DEFAULT_COLLECTION_SLUG } from '../utils/constants';
import { LoanFormatType } from '../utils/domain/loanFormat';
import {
    selectAgeCategoryAvailable,
    selectDefaultAgeCategory,
    selectDefaultLoanFormat,
    selectDefaultPageType,
    selectLoanFormatAvailable,
    selectPageAvailable
} from '../utils/navigation/navSelectors';
import { SEARCH_PATH } from '../utils/routes/paths';
import { stringifyQuery } from '../utils/routes/queryString';

interface IBaseLinkProps {
    readonly href: string;
}

type ProductsPageLinkProps<P extends IBaseLinkProps> = Omit<P, 'href'> &
    IProductsPageRef & {
        fallbackTo?: string;
        children?: ReactNode;
        queryParams?: {
            [key in string]: any;
        };
    };

/**
 * Higher order component for transforming `<NavLink>` like components to products page links.
 * The `to` path of the wrapped component will automatically be set based on the given `collection`, `loanFormat`,
 * `ageCategory` and `pageType`.
 */
export function createProductsPageLink<T extends IBaseLinkProps>(Component: ComponentType<T>) {
    return memo(
        forwardRef(
            (
                {
                    loanFormat,
                    ageCategory,
                    pageType,
                    pageSuffix,
                    fallbackTo = '/',
                    children,
                    queryParams,
                    ...props
                }: ProductsPageLinkProps<T>,
                ref
            ) => {
                const completePath = useCompletePath(loanFormat, ageCategory, pageType, pageSuffix, queryParams);

                const componentProps = {
                    ...props,
                    href: completePath || fallbackTo
                } as unknown as T;

                return (
                    <Component {...componentProps} ref={ref}>
                        {children}
                    </Component>
                );
            }
        ),
        isEqual
    );
}

function useCompletePath(
    targetLoanFormat: LoanFormatType | undefined,
    ageCategory: AgeCategoryType | undefined,
    pageType?: ProductsPageType,
    pageSuffix?: string,
    queryParams?: { [p: string]: any }
): string {
    const loanFormat =
        targetLoanFormat === LoanFormatType.eMagazineIssues ? LoanFormatType.eMagazines : targetLoanFormat;
    const pageRef = usePageRef(loanFormat, ageCategory, pageType);
    const hasSpecificAgeCategory = useHasSpecificAgeCategory(loanFormat, ageCategory ?? AgeCategoryType.allAges);

    let path = productsPagePath(pageRef);
    let serializedQueryParams = stringifyQuery(queryParams ?? {});

    if (!hasSpecificAgeCategory) {
        path = SEARCH_PATH;
        serializedQueryParams = stringifyQuery({
            ...queryParams,
            loanFormat,
            ageCategory
        });
    }

    return path + (pageSuffix ? `/${pageSuffix}` : '') + (serializedQueryParams ? '?' + serializedQueryParams : '');
}

function useHasSpecificAgeCategory(loanFormat: LoanFormatType | undefined, ageCategory: AgeCategoryType): boolean {
    const { navigation } = useLibraryContext();

    if (!loanFormat || ageCategory === AgeCategoryType.allAges) {
        return true;
    }

    return !!navigation.collections[DEFAULT_COLLECTION_SLUG].loanFormats[loanFormat]?.ageCategories[ageCategory];
}

export const ProductsPageLink = createProductsPageLink(NavLink);

export function usePageRef(
    targetLoanFormat: LoanFormatType | null = null,
    targetAgeCategory: AgeCategoryType | null = null,
    targetPageType: ProductsPageType | null = null
): IProductsPageRef {
    const libraryContext = useLibraryContext();
    const loanFormat = selectLoanFormatForLink(libraryContext, targetLoanFormat);
    const ageCategory = selectAgeCategoryForLink(libraryContext, loanFormat, targetAgeCategory);
    const pageType = selectPageTypeForLink(libraryContext, loanFormat, ageCategory, targetPageType);

    return {
        loanFormat: loanFormat || undefined,
        ageCategory,
        pageType: pageType || undefined
    };
}

/**
 * Selects the most appropriate loan format for a link to a specific collection/loan-format/age-category page.
 * In case there is no appropriate loan format, null is returned.
 */
function selectLoanFormatForLink(
    libraryContext: LibraryContextValue,
    loanFormat: LoanFormatType | null
): LoanFormatType | null {
    if (loanFormat) {
        const available = selectLoanFormatAvailable(libraryContext, loanFormat);
        if (available) {
            return loanFormat;
        }
    }
    return selectDefaultLoanFormat(libraryContext);
}

/**
 * Selects the most appropriate age category for a link to a specific collection/loan-format/age-category page.
 * In case there is no appropriate age category, "allAges" is returned.
 */
function selectAgeCategoryForLink(
    libraryContext: LibraryContextValue,
    targetLoanFormat: LoanFormatType | null,
    targetAgeCategory: AgeCategoryType | null
): AgeCategoryType {
    if (targetLoanFormat) {
        if (targetAgeCategory) {
            const available = selectAgeCategoryAvailable(libraryContext, targetLoanFormat, targetAgeCategory);

            if (available) {
                return targetAgeCategory;
            }
        }

        return selectDefaultAgeCategory(libraryContext, targetLoanFormat);
    }
    return AgeCategoryType.allAges;
}

/**
 * Selects the most appropriate page type for a link to a specific collection/loan-format/age-category page.
 * In case there is no appropriate page type, null is returned.
 */
function selectPageTypeForLink(
    libraryContext: LibraryContextValue,
    targetLoanFormat: LoanFormatType | null,
    targetAgeCategory: AgeCategoryType | null,
    targetPageType: ProductsPageType | null
): ProductsPageType | null {
    if (targetLoanFormat && targetAgeCategory) {
        if (targetPageType) {
            const targetPageAvailable = selectPageAvailable(
                libraryContext,
                targetLoanFormat,
                targetAgeCategory,
                targetPageType
            );

            if (targetPageAvailable) {
                return targetPageType;
            }
        }

        return selectDefaultPageType(libraryContext, targetLoanFormat, targetAgeCategory);
    }

    return null;
}
