import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * According to https://bolinda.sentry.io/issues/3777781480/ Next.js measures rendering performance.
 * When changing the route during the measurement, the error occurs. The slower the network and the weaker the computing
 * power, the higher is the chance of the error occurring. To prevent the error, we check if a route change is in
 * progress and if so, we throw a string and not an error. This way, the app will not crash and the error will not be
 * reported to Sentry.
 */
export const useCatchRouteInProgress = () => {
    const router = useRouter();

    const routeInProgress = 'routeInProgress';

    useEffect(() => {
        let isBlocked = false;
        let isRouting = false;

        const start = () => {
            if (isBlocked) throw routeInProgress;
            isRouting = true;
        };

        const complete = () => {
            isBlocked = false;
            isRouting = false;
        };

        const reset = (event: MouseEvent) => {
            const anchor = event.target instanceof HTMLElement ? event.target.closest('a') : null;
            if (anchor && isRouting) {
                isBlocked = true;
            }
        };

        document.addEventListener('mousedown', reset);
        router.events.on('routeChangeStart', start);
        router.events.on('routeChangeComplete', complete);
        router.events.on('routeChangeError', complete);

        return () => {
            document.removeEventListener('mousedown', reset);
            router.events.off('routeChangeStart', start);
            router.events.off('routeChangeComplete', complete);
            router.events.off('routeChangeError', complete);
        };
    }, [router.events]);
};
