import { SearchField } from '../../api/searchProducts';
import { ProductsPageType } from '../../components/layout/header/Navigation';
import { AgeCategoryType } from '../../domain/ageCategory';
import { LocalResourceType } from '../../setup/i18n';
import { LoanFormatType } from '../domain/loanFormat';

export const i18nLoanFormatMap: Record<LoanFormatType, 'ebooks' | 'eaudiobooks' | 'emagazines'> = {
    [LoanFormatType.eaudiobooks]: 'eaudiobooks',
    [LoanFormatType.ebooks]: 'ebooks',
    [LoanFormatType.eMagazines]: 'emagazines',
    [LoanFormatType.eMagazineIssues]: 'emagazines'
};

export const i18nSearchFieldMap: Record<SearchField, keyof LocalResourceType['common']['defaults']['search']> = {
    [SearchField.All]: 'all',
    [SearchField.Title]: 'title',
    [SearchField.Genre]: 'genre',
    [SearchField.Series]: 'series',
    [SearchField.Author]: 'author',
    [SearchField.Narrator]: 'narrator',
    [SearchField.Isbn]: 'isbn'
};

export const i18nAgeCategoryMap: Record<AgeCategoryType, keyof LocalResourceType['common']['age_categories']> = {
    [AgeCategoryType.allAges]: 'all_ages',
    [AgeCategoryType.adult]: 'adult',
    [AgeCategoryType.children]: 'children',
    [AgeCategoryType.youngAdult]: 'young_adult'
};

export const i18nPageTypeMap: Record<ProductsPageType, keyof LocalResourceType['common']['header']['nav']['pages']> = {
    [ProductsPageType.All]: 'all',
    [ProductsPageType.AllEMagazines]: 'allEMagazines',
    [ProductsPageType.AllENews]: 'allENews',
    [ProductsPageType.Featured]: 'featured',
    [ProductsPageType.Explore]: 'explore',
    [ProductsPageType.Genres]: 'genres'
};
