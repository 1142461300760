import { FC, PropsWithChildren, useId } from 'react';

export const Skippable: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
    const endLinkId = 'skip-to-' + useId();
    return (
        <>
            <a className="skip-link" href={`#${endLinkId}`}>
                {title}
            </a>
            {children}
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            <a id={endLinkId} tabIndex={-1} />
        </>
    );
};
