import mapValues from 'lodash/mapValues';
import { useRouter } from 'next/router';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import {
    deserializeCookie,
    needsRefresh,
    readCookie,
    serializeCookie,
    setCookieInBrowser
} from '../../../../utils/cookies';
import { isEmpty, isPresent } from '../../../../utils/objectChecks';
import { isGtagLibScriptPresent } from '../../../analytics/helpers';

const CONSENT_COOKIE_NAME = 'CONSENT';
const CONSENT_COOKIE_MAX_AGE = 180 * 24 * 60 * 60;
const CONSENT_COOKIE_REFRESH_THRESHOLD = 1; // [days]

export type ConsentCookie = {
    analytics: {
        readonly lastUpdated: string | null;
        readonly accepted: boolean;
    };
};

const EMPTY_CONSENT_COOKIE: ConsentCookie = {
    analytics: {
        lastUpdated: null,
        accepted: false
    }
};

type Consents = {
    analytics: boolean;
};

const EMPTY_CONSENTS: Consents = {
    analytics: false
};

type Context = {
    readonly consentCookie: ConsentCookie | undefined;
    readonly setConsentCookie: (consent: ConsentCookie) => void;
};

const Ctx = createContext<Context>({
    consentCookie: undefined,
    setConsentCookie: () => {}
});

type Props = PropsWithChildren<{
    readonly initialConsentCookie?: ConsentCookie;
}>;

export const ConsentCookieProvider: FC<Props> = ({ children, initialConsentCookie }) => {
    const [consentCookie, setConsentCookie] = useState(initialConsentCookie);
    const value: Context = useMemo(() => ({ consentCookie, setConsentCookie }), [consentCookie]);

    return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};

export type UseConsentCookie = {
    /**
     * Indicates whether a consent cookie is set.
     */
    readonly isCookieSet: boolean;

    /**
     * Consent information stored in the consent cookie.
     */
    readonly consents: Consents;

    /**
     * Updates consent information in the consent cookie.
     */
    readonly set: (value: boolean) => void;
};

export function useConsentCookie(): UseConsentCookie {
    const { consentCookie, setConsentCookie } = useContext(Ctx);
    const consents: Consents = useMemo(
        () => (isEmpty(consentCookie) ? EMPTY_CONSENTS : mapValues(consentCookie, consent => !!consent.accepted)),
        [consentCookie]
    );

    const { reload } = useRouter();

    const set = useCallback(
        (accepted: boolean) => {
            const updatedConsentCookie: ConsentCookie = {
                ...(consentCookie ?? EMPTY_CONSENT_COOKIE),
                analytics: {
                    lastUpdated: new Date().toISOString(),
                    accepted
                }
            };

            setConsentCookie(updatedConsentCookie);
            serializeAndStoreConsentCookie(updatedConsentCookie);

            if (!accepted && isGtagLibScriptPresent()) {
                // Reload the page to remove Google Analytics scripts if user has revoked consent
                reload();
            }
        },
        [consentCookie, reload, setConsentCookie]
    );

    return useMemo(
        () => ({
            isCookieSet: isPresent(consentCookie),
            consents,
            set
        }),
        [consentCookie, consents, set]
    );
}

function serializeAndStoreConsentCookie(cookie: ConsentCookie) {
    const updated = serializeCookie(cookie);
    setCookieInBrowser(CONSENT_COOKIE_NAME, updated, {
        maxAge: CONSENT_COOKIE_MAX_AGE,
        path: '/',
        secure: true,
        sameSite: 'lax'
    });
}

function deserializeConsentCookie(cookie: string): ConsentCookie {
    const deserializedCookie = deserializeCookie(cookie) as ConsentCookie | null;
    return deserializedCookie ?? EMPTY_CONSENT_COOKIE;
}

/**
 * Extracts a consent cookie from an incoming request.
 */
export function extractConsentCookie(cookieHeader: string | null | undefined): ConsentCookie | undefined {
    const consentCookieRaw = readCookie(CONSENT_COOKIE_NAME, cookieHeader);

    if (!consentCookieRaw) return undefined;

    return deserializeConsentCookie(consentCookieRaw);
}

export const refreshConsentCookie = () => {
    const cookie = extractConsentCookie(document.cookie);

    if (!cookie) return;

    const { accepted, lastUpdated } = cookie.analytics;

    if (!accepted || !lastUpdated) return;

    if (needsRefresh(lastUpdated, CONSENT_COOKIE_REFRESH_THRESHOLD)) {
        serializeAndStoreConsentCookie({
            analytics: {
                lastUpdated: new Date().toISOString(),
                accepted
            }
        });
    }
};
