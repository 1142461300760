import flatMap from 'lodash/flatMap';
import { Fragment, useMemo } from 'react';

/**
 * Props of HighlightedText.
 */
type HighlightableTextProps = HighlightedTextProps & {
    /**
     * Indicates whether text highlighting should be disabled. In that case
     * the plain text will be rendered.
     */
    readonly disabled?: boolean;
};

/**
 * Renders some plain text while highlighting special keywords within it.
 */
export const HighlightableText = ({ text, keywords, disabled = false }: HighlightableTextProps) => {
    return disabled ? <>{text}</> : <HighlightedText text={text} keywords={keywords} />;
};

/**
 * Props of HighlightedText.
 */
interface HighlightedTextProps {
    /**
     * The text that should be partially highlighted.
     */
    readonly text: string | null | undefined;

    /**
     * Keywords that should be highlighted within the label.
     */
    readonly keywords: ReadonlyArray<string> | null | undefined;

    /**
     * Indicates whether text highlighting should be disabled. In that case
     * the plain text will be rendered.
     */
    readonly disabled?: boolean;
}

/**
 * Represents a chunk of text.
 */
interface Chunk {
    /**
     * The text.
     */
    text: string;

    /**
     * Whether this chunk should be highlighted.
     */
    highlighted: boolean;
}

const HighlightedText = ({ text, keywords }: HighlightedTextProps) => {
    const chunks = useMemo(() => {
        const initialChunk = { text: text || '', highlighted: false };

        if (!keywords || keywords.length < 1) {
            return [initialChunk];
        }

        return keywords.reduce(
            (chunks, keyword) => {
                if (!keyword) {
                    return chunks;
                }

                return flatMap(chunks, chunk => {
                    if (chunk.highlighted) {
                        return chunk;
                    }

                    let text = chunk.text;

                    const nextChunks: Array<Chunk> = [];

                    for (let i = 0; i < text.length; i++) {
                        const nextIndexCandidate = text.toLowerCase().indexOf(keyword.toLowerCase(), i);
                        const nextIndex = nextIndexCandidate !== -1 ? nextIndexCandidate : text.length;

                        if (nextIndex === i) {
                            nextChunks.push({ text: text.slice(i, i + keyword.length), highlighted: true });
                            i += keyword.length - 1;
                        } else {
                            nextChunks.push({ text: text.slice(i, nextIndex), highlighted: false });
                            i = nextIndex - 1;
                        }
                    }

                    return nextChunks;
                });
            },
            [initialChunk]
        );
    }, [text, keywords]);

    return (
        <>
            {chunks.map((chunk, i) =>
                chunk.highlighted ? <mark key={i}>{chunk.text}</mark> : <Fragment key={i}>{chunk.text}</Fragment>
            )}
        </>
    );
};
