import { FloatingPortal, ReferenceType, UseFloatingReturn } from '@floating-ui/react-dom-interactions';
import { FC, HTMLProps, MutableRefObject, PropsWithChildren } from 'react';

import { Arrow } from './Arrow';

type TooltipWrapperProps = PropsWithChildren<{
    readonly open: boolean;
    readonly floatingProps: UseFloatingReturn<ReferenceType>;
    readonly getFloatingProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
    readonly arrowRef?: MutableRefObject<null>;
}>;

export const Wrapper: FC<TooltipWrapperProps> = ({ open, floatingProps, getFloatingProps, arrowRef, children }) => {
    const { x, y, floating, strategy } = floatingProps;

    return (
        <FloatingPortal>
            {open ? (
                <div
                    role="tooltip"
                    ref={floating}
                    className="component tooltip"
                    style={{
                        display: 'block',
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content'
                    }}
                    {...getFloatingProps()}
                >
                    {arrowRef ? <Arrow arrowRef={arrowRef} floatingProps={floatingProps} /> : null}
                    {children}
                </div>
            ) : null}
        </FloatingPortal>
    );
};
