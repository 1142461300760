import { SiteParams } from './index';
import { ApiClient } from '../setup/axios';

export type ApiPatronInfo = {
    readonly userId: string;
    readonly siteId: string;
    readonly siteName: string;
    readonly email: string;
    readonly fullyRegistered: boolean;
    readonly username: string;
    readonly displayName?: string;
    readonly language: string;
};

export const API_PATRON_ME = '/api/v1/me';

/**
 * Do not move the corresponding react-query hook to the `api` folder. We don't want to access the patron info via
 * react query, but rather via this context to have exactly one source of truth, which is the AuthenticationContext.
 */
export function fetchPatronInfo(client: ApiClient, params: SiteParams): Promise<ApiPatronInfo> {
    return client.get<ApiPatronInfo>(API_PATRON_ME, params).then(result => result.data);
}
