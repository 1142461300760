import { useTranslation } from 'react-i18next';

import { useLibraryContext } from '../../context/LibraryContext';
import { PASSWORD_REQUEST_PATH } from '../../utils/routes/paths';

export const useForgotPasswordLink = (): string | null => {
    const { t } = useTranslation();

    const { login } = useLibraryContext();
    const supportsInternalPasswordReset = login?.supportsInternalPasswordReset;

    const passwordLinkUrl = t('login.password_link_url', { defaultValue: null });

    return supportsInternalPasswordReset ? PASSWORD_REQUEST_PATH : passwordLinkUrl;
};
