import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

export type ApiReadingListsProductIdsResponse = {
    readonly productIds: ReadonlyArray<string>;
};

export function fetchReadingListsProductIds(
    apiClient: ApiClient,
    siteParams: SiteParams
): Promise<ApiReadingListsProductIdsResponse> {
    return apiClient
        .get<ApiReadingListsProductIdsResponse>('/api/v1/reading-lists/ids', { ...siteParams })
        .then(res => res.data);
}
