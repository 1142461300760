import classNames from 'classnames';
import { FC } from 'react';

import { GildLoanFormatClass } from '../utils/domain/loanFormat';

export type IconId =
    | 'account-circle'
    | 'add'
    | 'angle-bracket-outline-right'
    | 'angle-bracket-outline-left'
    | 'arrow-forward'
    | 'arrow-left'
    | 'arrow-right'
    | 'arrow-striped-down'
    | 'bookmark-add'
    | 'bookmarks'
    | 'caret-down'
    | 'caret-left'
    | 'caret-right'
    | 'caret-up'
    | 'check'
    | 'cloud-done'
    | 'close-outline'
    | 'cross'
    | 'diagnosis'
    | 'download'
    | 'error-circle'
    | 'favorite'
    | 'format-align-center'
    | 'format-align-justify'
    | 'format-align-left'
    | 'format-align-right'
    | 'format-size'
    | 'fullscreen'
    | 'globe-grid'
    | 'grid-view'
    | 'info-filled'
    | 'line-height-decrease'
    | 'line-height-increase'
    | 'login'
    | 'magnifier'
    | 'margins-narrower'
    | 'margins-wider'
    | 'menu'
    | 'more-horizontal'
    | 'one-column'
    | 'pause'
    | 'play'
    | 'plus'
    | 'preview-ebook-outline'
    | 'push-pin'
    | 'push-pin-outline'
    | 'rotate-anticlockwise'
    | 'rotate-clockwise'
    | 'search'
    | 'search-outline'
    | 'settings-reset'
    | 'skip-next'
    | 'skip-previous'
    | 'smiley'
    | 'sort'
    | 'star-outline'
    | 'sub-list-outline'
    | 'text-decrease'
    | 'text-increase'
    | 'toc'
    | 'trash'
    | 'two-columns'
    | 'unzip'
    | 'zoom-in'
    | 'zoom-out'
    | `${GildLoanFormatClass}-filled`
    | `${GildLoanFormatClass}-outline`;

type IconProps = {
    readonly icon: IconId;
    readonly className?: string;
    readonly label?: string;
};

export const Icon: FC<IconProps> = ({ icon, label, className }) => {
    return (
        <span
            role="img"
            className={classNames('icon', icon, className)}
            aria-label={label}
            aria-hidden={label ? undefined : true}
        />
    );
};

export function getFilled(gildLoanFormat: GildLoanFormatClass): IconId {
    return `${gildLoanFormat}-filled` as IconId;
}

export function getOutline(gildLoanFormat: GildLoanFormatClass): IconId {
    return `${gildLoanFormat}-outline` as IconId;
}
