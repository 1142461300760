import { FloatingPortal, ReferenceType, UseFloatingReturn } from '@floating-ui/react-dom-interactions';
import {
    Children,
    cloneElement,
    FC,
    HTMLProps,
    isValidElement,
    MouseEventHandler,
    MutableRefObject,
    PropsWithChildren,
    ReactNode,
    useCallback
} from 'react';
import FocusLock from 'react-focus-lock';

type WrapperProps = PropsWithChildren<{
    readonly open: boolean;
    readonly floatingProps: UseFloatingReturn<ReferenceType>;
    readonly getItemProps?: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
    readonly listRef?: MutableRefObject<Array<HTMLElement | null>>;
    readonly getFloatingProps?: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
    readonly focusLock?: boolean;
    readonly disableKeyboardNavigation?: boolean;
    readonly onClick?: MouseEventHandler;
}>;

export const Wrapper: FC<WrapperProps> = ({
    open,
    floatingProps,
    getItemProps,
    listRef,
    getFloatingProps,
    focusLock = true,
    disableKeyboardNavigation = false,
    onClick,
    children
}) => {
    const { x, y, floating, strategy } = floatingProps;

    const getClonedChild = (children: ReactNode): ReactNode | undefined => {
        return Children.map(children, child => {
            if (!listRef) return child;

            // A string indicates the end of a child tree and will be returned
            if (typeof child === 'string') return child;

            if (!isValidElement(child)) return;

            if (child.type === 'ul') {
                return cloneElement(
                    child,
                    getItemProps?.({
                        // We need to pass the props to the children of the <ul> child because
                        // the next children are the <li> elements which can be navigated.
                        children: Children.map(child.props.children, (child, index) => {
                            if (!isValidElement(child)) return;

                            return cloneElement(
                                child,
                                getItemProps?.({
                                    tabIndex: -1,
                                    ref(node: HTMLElement) {
                                        listRef.current[index] = node;
                                    }
                                })
                            );
                        })
                    })
                );
            } else {
                return cloneElement(
                    child,
                    getItemProps?.({
                        // We need to check deeper nodes for the <ul> element
                        children: getClonedChild(child.props.children)
                    })
                );
            }
        });
    };

    // To enable a navigation using the useListNavigation hook we have to pass a reference and some props to
    // the list items which can be navigated. Therefore, we clone the children and pass the needed props.
    // If the navigation is disabled we don't need to copy the children.
    const popoverChildren = disableKeyboardNavigation ? children : getClonedChild(children);

    const ConditionalFocusLock = useCallback(
        ({ children }: { children: JSX.Element }) => {
            return focusLock ? (
                <FocusLock focusOptions={{ preventScroll: true }} returnFocus={{ preventScroll: true }}>
                    {children}
                </FocusLock>
            ) : (
                children
            );
        },
        [focusLock]
    );

    return (
        <FloatingPortal>
            {open ? (
                <ConditionalFocusLock>
                    <div
                        ref={floating}
                        style={{
                            display: 'block',
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            zIndex: 999999
                        }}
                        onClick={onClick}
                        {...getFloatingProps?.()}
                    >
                        {popoverChildren}
                    </div>
                </ConditionalFocusLock>
            ) : null}
        </FloatingPortal>
    );
};
