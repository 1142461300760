import { AxiosError } from 'axios';

import { LanguageSelectionDisplay } from './apiLibraryContext';
import { LoanFormatHash } from '../utils/domain/loanFormat';

export type SiteParams = {
    readonly siteId?: string;
    readonly language?: string;
    readonly languageSelectionDisplay?: LanguageSelectionDisplay;
};

export interface PaginationParamsForProductGroups {
    limit?: number;
}

export interface PaginationParams {
    readonly offset: number;
    readonly limit: number;
}

export interface SortParams {
    readonly sortBy?: string;
}

export interface AvailabilityParams {
    readonly availableOnly?: boolean;
}

export type TotalCountByLoanFormat = LoanFormatHash<number>;

/** Represents a generic response body. It ensures all fields are readonly and potentially undefined or null. */
export type ResponseBody<T> = T extends Function
    ? never
    : T extends Array<infer E>
      ? ReadonlyArray<ResponseBody<E> | undefined>
      : { readonly [P in keyof T]?: ResponseBody<T[P]> };

export function isAxiosError(error: any): error is AxiosError<any> {
    return error?.isAxiosError === true;
}

interface ApiError {
    errorKeys: ReadonlyArray<string>;
}

export type AxiosApiError = AxiosError<ApiError>;

export function isApiError(error: Error | unknown): error is AxiosApiError {
    if (isAxiosError(error)) {
        return Array.isArray(error.response?.data?.errorKeys);
    }

    return false;
}
