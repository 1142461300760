import { darken } from 'polished';

import { ApiContextLibraryThemeDesign } from '../../api/apiLibraryContext';

/*
 * Currently, we have no hints from gild which links we should color in the library-color. Until a more elaborate
 * solution is implemented, we will just apply the library-color to ALL a-tags. However, there are certain design
 * buttons that are semantically (and thus implemented as) a-tags. For now, we have to explicitly exclude them.
 */
export const globalStyle = (theme: ApiContextLibraryThemeDesign) => `
    a:hover:not(.no-libcolor-text-on-link-hover),
    a:active:not(.no-libcolor-text-on-link-hover) {
        color: ${theme.pp_bar_color} !important;
    }

    .libcolor-text {
        color: ${theme.pp_bar_color} !important;
    }

    .libcolor-border {
        border-color: ${theme.pp_bar_color} !important;
    }

    .libcolor-bg {
        background-color: ${theme.pp_bar_color} !important;
    }

    @media(max-width:1419px) {
        .libcolor-text-1419-or-smaller {
            color: ${theme.pp_bar_color} !important;
        }
    }

    @media(min-width:1420px) {
        .libcolor-bg-1420-or-higher {
            background-color: ${theme.pp_bar_color} !important;
        }

        .libcolor-bg-dark-hover-1420-or-higher:hover {
            background-color: ${darken(0.15, theme.pp_bar_color)} !important;
        }
    }

    @media(min-width:992px) {
        .libcolor-bg-lg-or-higher {
            background-color: ${theme.pp_bar_color} !important;
        }

        .libcolor-bg-dark-hover-lg-or-higher:hover {
            background-color: ${darken(0.15, theme.pp_bar_color)} !important;
        }
    }

    .libcolor-text-hover:hover {
        color: ${theme.pp_bar_color} !important;
    }

    .libcolor-text-dark-hover:hover {
        color: ${darken(0.15, theme.pp_bar_color)} !important;
    }

    .libcolor-bg-hover:hover {
        background-color: ${theme.pp_bar_color} !important;
    }

    .libcolor-bg-dark-hover:hover {
        background-color: ${darken(0.15, theme.pp_bar_color)} !important;
    }

    .libcolor-loader.loader,
    .libcolor-loader.loader:before,
    .libcolor-loader.loader:after {
        background-color: ${theme.pp_bar_color} !important;
        color: ${theme.pp_bar_color} !important;
    }

    .libcolor-text-active.active {
        color: ${theme.pp_bar_color} !important;
    }
    li.active a.libcolor-text-active,
     li.active button.libcolor-text-active{
        color: ${theme.pp_bar_color} !important;
    }
    .nav-links li.active a.libcolor-text-active:after {
        background-color: ${theme.pp_bar_color} !important;
    }

    .ended-on {
        font-weight: bold;
    }
`;
