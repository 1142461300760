import { ComponentType, forwardRef, useEffect, useState } from 'react';

// This variable should be always `false` on the server. If a client rendered the component for the first time,
// save this state. The next time it is rendered we don't have to re-run the `useEffect` that sets `shouldRender` to
// `true`, instead we can render it directly and save a render cycle this way.
//
// This was added because we don't render the index page on the server. This leads to a client side render. If the
// user goes to another page and uses the browser back button, the scroll position would be lost as the first render
// is virtually empty. Only after the `useEffect` the page is complete, but the scroll position is lost by then.
let clientRendered = false;

/**
 * Transforms a React component into a new component, that will render nothing on the Server.
 */
export function NoSsr<P, R = void>(Component: ComponentType<P>) {
    return forwardRef<R, P>((props, ref) => {
        // We need to guard the client side render with a useEffect. That's required because the first render on the
        // client needs to match the render on the server.
        // See also: https://github.com/vercel/next.js/discussions/17443#discussioncomment-87097
        const [shouldRender, setShouldRender] = useState(clientRendered);
        useEffect(() => {
            setShouldRender(true);
            clientRendered = true;
        }, []);

        return shouldRender ? <Component {...props} ref={ref} /> : null;
    });
}
