import { parse } from 'accept-language-parser';
import compact from 'lodash/compact';
import { GetServerSidePropsContext } from 'next';
import { NextPageContext } from 'next/dist/shared/lib/utils';
import { useTranslation } from 'react-i18next';

import {
    DEFAULT_LANGUAGE,
    DEFAULT_SUPPORTED_LANGUAGES,
    FALLBACK_COUNTRY_REGION,
    LOCALE_COOKIE_MAY_AGE,
    LOCALE_COOKIE_NAME,
    LOCALE_COOKIE_REFRESH_THRESHOLD,
    SITE_SUPPORTED_LANGUAGES
} from '../../setup/i18n';
import { deserializeCookie, needsRefresh, readCookie, serializeCookie, setCookieInBrowser } from '../cookies';

type GetSSRUserLocaleArgs = {
    readonly context: GetServerSidePropsContext | NextPageContext;
    readonly siteId: string;
};

export function getSSRUserLocale({ context, siteId }: GetSSRUserLocaleArgs) {
    const queryLocale = typeof context.query?.locale === 'string' ? context.query.locale : undefined;
    const cookieLocale = readLocaleCookie(context.req?.headers.cookie).locale;
    const locale = queryLocale ?? cookieLocale;

    if (locale) {
        return getSupportedRegion({ siteId, locale }) ?? DEFAULT_LANGUAGE;
    }

    const acceptLanguageHeader = context.req?.headers['accept-language'];
    const requestLocales = parseAcceptLanguageHeader(acceptLanguageHeader);

    const supportedRequestLocales = requestLocales.map(locale => getSupportedRegion({ locale, siteId }));

    const userLocale = supportedRequestLocales.find(language => isSupportedLanguage({ siteId, language }));

    return userLocale ?? DEFAULT_LANGUAGE;
}

type GetSupportedRegionArgs = { siteId?: string | null; locale: string };

export function getSupportedRegion({ siteId, locale }: GetSupportedRegionArgs) {
    const localeSanitized = sanitizeLocale(locale);

    if (isSupportedLanguage({ siteId, language: localeSanitized })) {
        return localeSanitized;
    }

    const localeWithSupportedRegion = fallbackToSupportedRegion(localeSanitized);

    if (isSupportedLanguage({ siteId, language: localeWithSupportedRegion })) {
        return localeWithSupportedRegion;
    }

    return undefined;
}

export function sanitizeLocale(locale: string) {
    const [country, region] = locale.split('-');
    const [regionStripped] = region ? region.split('-') : []; // Discard Orthography

    return compact([country.toLowerCase(), regionStripped?.toUpperCase()]).join('-');
}

export function fallbackToSupportedRegion(locale: string) {
    const [country] = locale.split('-');

    if (country in FALLBACK_COUNTRY_REGION) {
        const fallbackRegion = FALLBACK_COUNTRY_REGION[country as keyof typeof FALLBACK_COUNTRY_REGION];

        return `${country}-${fallbackRegion}`;
    }

    return undefined;
}

export function parseAcceptLanguageHeader(acceptLanguage: string | undefined) {
    const parsedLocales = parse(acceptLanguage || '');
    return parsedLocales.map(parsedLocale => compact([parsedLocale.code, parsedLocale.region]).join('-'));
}

export function getSupportedLanguages(siteId?: string | null) {
    return siteId && siteId in SITE_SUPPORTED_LANGUAGES
        ? SITE_SUPPORTED_LANGUAGES[siteId as keyof typeof SITE_SUPPORTED_LANGUAGES]
        : DEFAULT_SUPPORTED_LANGUAGES;
}

type IsSupportedLanguageArgs = {
    readonly siteId?: string | null;
    readonly language?: string;
};

function isSupportedLanguage({ siteId, language }: IsSupportedLanguageArgs) {
    const supportedLanguageKeys = Object.keys(getSupportedLanguages(siteId));
    return language ? supportedLanguageKeys.includes(language) : false;
}

export function useLocale() {
    const { i18n } = useTranslation();
    return i18n.language;
}

type LocaleCookie = {
    readonly locale?: string;
    readonly lastUpdated?: string | null;
};

export const setLocaleCookie = (locale: string) => {
    const cookie: LocaleCookie = {
        locale,
        lastUpdated: new Date().toISOString()
    };

    setCookieInBrowser(LOCALE_COOKIE_NAME, serializeCookie(cookie), {
        maxAge: LOCALE_COOKIE_MAY_AGE,
        path: '/',
        secure: true,
        sameSite: 'lax'
    });
};

// was plain string i.e. `en-AU` TODO: remove after 2023-07 (180 days after deployment of this change)
const isDeprecatedCookieFormat = (cookie: string | undefined) => cookie && cookie.length < 6;

const readLocaleCookie = (cookieHeader: string | null | undefined): LocaleCookie => {
    const cookie = readCookie(LOCALE_COOKIE_NAME, cookieHeader);
    const cookieDeserialized: LocaleCookie =
        cookie && !isDeprecatedCookieFormat(cookie) ? deserializeCookie(cookie) ?? {} : {};
    const locale = cookieDeserialized.locale;
    const lastUpdated = 'lastUpdated' in cookieDeserialized ? cookieDeserialized.lastUpdated : null;

    return {
        locale,
        lastUpdated
    };
};

export const refreshLocaleCookie = () => {
    const { locale, lastUpdated } = readLocaleCookie(document.cookie);

    if (!locale || !lastUpdated) return;

    if (needsRefresh(lastUpdated, LOCALE_COOKIE_REFRESH_THRESHOLD)) {
        setLocaleCookie(locale);
    }
};
