import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

export const API_MDP = '/api/v1/mdp';

const getMdpUrl = (productId: string, preview: boolean) => {
    if (preview) {
        return `${API_MDP}/${productId}/url/preview`;
    } else {
        return `${API_MDP}/${productId}/url`;
    }
};

type FetchMdpUrlParams = SiteParams & {
    readonly context: string;
    readonly preview: boolean;
    readonly type: string;
    readonly file?: string;
    readonly translateTo?: string;
};

async function fetchMdpUrl(
    client: ApiClient,
    { context, preview, type, file, translateTo, ...params }: FetchMdpUrlParams,
    productId: string
): Promise<string> {
    const url = getMdpUrl(productId, preview);
    const result = await client.get<{ url: string }>(url, {
        ...params,
        params: { context, file, type, ...(translateTo ? { translateTo } : {}) }
    });
    return result.data.url;
}

export function fetchMdpUrlForManifestToc(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'webpubManifest', preview, type: 'tableOfContents' }, productId);
}

export function fetchMdpUrlForManifestReadingOrder(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'webpubManifest', preview, type: 'readingOrder' }, productId);
}

export function fetchMdpUrlForManifestResources(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'webpubManifest', preview, type: 'resources' }, productId);
}

export function fetchMdpUrlForManifestMetadata(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'webpubManifest', preview, type: 'metadata' }, productId);
}

export function fetchMdpUrlForResource(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string,
    file: string,
    translateTo?: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'epub', preview, type: 'resource', file, translateTo }, productId);
}

export function fetchMdpUrlForPdfPToken(
    client: ApiClient,
    preview: boolean,
    params: SiteParams,
    productId: string
): Promise<string> {
    return fetchMdpUrl(client, { ...params, context: 'pspdfkit', preview, type: 'token' }, productId);
}
