import { MouseEventHandler, useCallback } from 'react';

/**
 * Wraps a callback in a function, which receives a MouseEvent and calls preventDefault on it.
 *
 * Useful for when we use <a> tags with onClick listeners and href="#".
 *
 * Note: This hook probably shouldn't exist. We should try to convert all those links into proper buttons. But until our
 * design allows that, we can use this hook.
 */
export function usePreventDefault(callback: () => void) {
    return useCallback<MouseEventHandler>(
        event => {
            event.preventDefault();
            callback();
        },
        [callback]
    );
}
