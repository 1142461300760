import { isPresent } from '../objectChecks';
import { stripAnchor } from '../string';

const URL_ARRAY_SEPARATOR = '|';

type QueryReturnType = {
    [key: string]: string | Array<string>;
};

export const parseQuery = (queryString: string): QueryReturnType => {
    const pureQueryString = stripAnchor(queryString);
    const searchParams = new URLSearchParams(pureQueryString);

    return Object.fromEntries(
        Array.from(searchParams).map(([key, value]) => [
            key,
            value.includes(URL_ARRAY_SEPARATOR) ? value.split(URL_ARRAY_SEPARATOR) : value
        ])
    );
};

type QueryParamType = {
    [key: string]: string | Array<string | null> | boolean | number | null | undefined;
};

export const stringifyQuery = (query: QueryParamType, target: 'client' | 'api' = 'client'): string => {
    const searchParams = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (target === 'api') {
                value.forEach(val => {
                    if (isPresent(val)) searchParams.append(key, val.toString());
                });
            }
            if (target === 'client' && value.length > 0) {
                searchParams.append(key, value.join('URL_ARRAY_SEPARATOR'));
            }
        } else if (isPresent(value)) {
            searchParams.append(key, value.toString());
        }
    });

    return searchParams.toString().replaceAll('URL_ARRAY_SEPARATOR', URL_ARRAY_SEPARATOR);
};
