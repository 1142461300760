import { ProductActionProductData } from './ProductActionReducer';
import { ShortProduct } from '../../domain/product';
import { ProductDetails } from '../../domain/productDetails';
import { LoanFormatType } from '../../utils/domain/loanFormat';

export const getProductActionProductData = (product: ProductDetails | ShortProduct): ProductActionProductData => {
    return {
        productId: product.productId,
        title: product.title,
        authors: product.authors,
        loanFormat: product.formatSpecifics.loanFormat,
        coverUrl: product.coverUrl,
        availabilityStatus: product.availability.status,
        availabilityDate: product.availability.nextAvailableDate,
        latestAvailableIssueId: product.availability.latestAvailableIssueId,
        rootProductId: product.rootProductId,
        rootProductTitle: product.rootProduct?.title,
        currentPeriodEnd: product.currentPeriodEnd,
        releaseFrequency:
            product.rootProduct?.formatSpecifics.loanFormat === LoanFormatType.eMagazines
                ? product.rootProduct.formatSpecifics.releaseFrequency
                : undefined,
        restrictions: product.restrictions
    };
};
