import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useConsentCookie } from './ConsentCookie';
import { CookiePreferencesModal } from './CookiePreferencesModal';
import { useToggle } from '../../../../hooks/utils/useToggle';

export const CookieBanner: FC = () => {
    const { t } = useTranslation();
    const { isCookieSet, set } = useConsentCookie();
    const [visible, toggleVisible] = useToggle();

    const handleAccept = () => set(true);

    if (isCookieSet) return null;

    return (
        <>
            <section className="alert-banner" aria-label={t('cookie_consent.banner.label')}>
                <div className="text">{t('cookie_consent.banner.text')}</div>
                <div className="actions">
                    <button onClick={toggleVisible} className="button-transparent-light size-large">
                        {t('cookie_consent.banner.buttons.cookie_preferences')}
                    </button>
                    <button onClick={handleAccept} className="button-white size-large">
                        {t('cookie_consent.banner.buttons.accept')}
                    </button>
                </div>
            </section>
            {visible ? <CookiePreferencesModal onClose={toggleVisible} /> : null}
        </>
    );
};
