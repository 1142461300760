export enum AgeCategoryName {
    allAges = 'ALL_AGES',
    adult = 'ADULT',
    youngAdult = 'YOUNG_ADULT',
    children = 'CHILDREN'
}

export enum LoanFormatName {
    eBooks = 'EBOOKS',
    eAudiobooks = 'EAUDIOBOOKS',
    eMagazines = 'EMAGAZINES'
}
