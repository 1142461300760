import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query';

import { ApiLoanInfo } from './apiGetLoan';
import { getLoanDetails } from './apiGetLoanDetails';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { SiteParams } from '../index';

const BASE_QUERY_KEY = 'loanDetails';

type Options = {
    readonly rootProductId: string;
    readonly fetchFunc?: typeof getLoanDetails;
};

type BuildQueryKeyParams = SiteParams & {
    readonly userId: string;
    readonly rootProductId: string;
};

const buildQueryKey = ({ siteId, userId, rootProductId }: BuildQueryKeyParams): QueryKey => [
    BASE_QUERY_KEY,
    { siteId, userId, rootProductId /* language is irrelevant for active loans and reserves */ }
];

export function useLoanDetails({
    rootProductId,
    fetchFunc = getLoanDetails
}: Options): UseQueryResult<'data', ApiLoanInfo | undefined> {
    const { siteId, language } = useSiteParams();
    const { userId } = usePatronInfo();
    const queryParams = { siteId, language, userId, rootProductId };

    return useQuery({
        queryKey: buildQueryKey(queryParams),
        queryFn: () => fetchFunc(browserClient, rootProductId, { siteId, language })
    });
}

export const prefetchLoanDetails = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    { siteId, language, userId, rootProductId, fetchFunc = getLoanDetails }: Options & BuildQueryKeyParams
) => {
    const queryParams = { siteId, language, userId, rootProductId };
    return queryClient.fetchQuery({
        queryKey: buildQueryKey(queryParams),
        queryFn: () => fetchFunc(apiClient, rootProductId, { siteId, language })
    });
};

export const invalidateLoanDetails = (client: QueryClient) =>
    client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
