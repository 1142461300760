import { QueryClient, useQuery } from '@tanstack/react-query';

import {
    ApiReadingListProductsByFormat,
    fetchReadingListProductsByFormat
} from './apiFetchReadingListProductsByFormat';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { ReadingListProductsByFormatParams } from '../../hooks/getters/useReadingListProductsByFormatParams';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { SiteParams } from '../index';

const QUERY_KEY = 'reading-lists-products-by-format';

const EMPTY_RESPONSE: ApiReadingListProductsByFormat = { items: [], totalCount: 0 };

type useReadingListProductsByFormatParams = {
    readonly loanFormat?: LoanFormatType;
    readonly params: ReadingListProductsByFormatParams;
};

/**
 * Fetches historical loans for a specific loan format.
 */
export const useReadingListProductsByFormat = ({
    loanFormat,
    params
}: useReadingListProductsByFormatParams): UseQueryResult<'data', ApiReadingListProductsByFormat> => {
    const { siteId, language } = useSiteParams();
    const userId = usePatronInfo().userId;
    const { offset, limit, sortOrder } = params;

    const { data, ...rest } = useQuery({
        queryKey: buildReadingListProductsByFormatQueryKey({ loanFormat, userId, siteId, params }),
        queryFn: () =>
            fetchReadingListProductsByFormat(browserClient, {
                loanFormat,
                pageSize: limit,
                nextPosition: offset,
                sortOrder,
                siteId,
                language
            })
    });

    return {
        data: data ?? EMPTY_RESPONSE,
        ...rest
    };
};

export const prefetchReadingsListProductsByFormat = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    { loanFormat, params }: useReadingListProductsByFormatParams,
    userId: string,
    { siteId, language }: SiteParams
) => {
    const { offset, limit, sortOrder } = params;

    return queryClient.fetchQuery({
        queryKey: buildReadingListProductsByFormatQueryKey({ loanFormat, userId, siteId, params }),
        queryFn: () =>
            fetchReadingListProductsByFormat(apiClient, {
                loanFormat,
                pageSize: limit,
                nextPosition: offset,
                sortOrder,
                siteId,
                language
            })
    });
};

export function invalidateReadingListProductsByFormat(queryClient: QueryClient): Promise<void> {
    return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY]
    });
}

type BuildQueryKeyParams = {
    readonly loanFormat?: LoanFormatType;
    readonly userId: string;
    readonly params?: ReadingListProductsByFormatParams;
    readonly siteId: string | undefined | null;
};

export type ReadingListsProductsQueryKey = ['reading-lists-products-by-format', BuildQueryKeyParams];

export const buildReadingListProductsByFormatQueryKey = ({
    loanFormat,
    siteId,
    userId,
    params
}: BuildQueryKeyParams): ReadingListsProductsQueryKey => {
    return [QUERY_KEY, { loanFormat, siteId, userId, params }];
};
