/**
 * A callback for validating form inputs. The form input is considered valid,
 * if the callback returns `true`. If the callback returns `false`, the form
 * input is considered invalid. If the callback returns a string, the form
 * input is also considered invalid, and the returned string will be exposed
 * as a validation error message.
 */
export type ValidationCallback<T> = (value: T | null | undefined) => boolean | string;

// simple email check: example@domain.com
const EMAIL_PATTERN = /(\S+)@(\S+\.\S+)/;

// this pattern checks an email domain (e.g. foobar.com)
const EMAIL_DOMAIN_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9-.]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

const USER_PATTERN =
    /^\s*(((\\.)|[^\s()<>@,;:'\\".[\]]|')+|("(\\"|[^"])*"))(\.(((\\.)|[^\s()<>@,;:'\\".[\]]|')+|("(\\"|[^"])*")))*$/;

function createEmailValidation(): ValidationCallback<string> {
    return email => {
        if (!email) {
            return false;
        } else if (email.endsWith('.')) {
            return false;
        } else {
            const emailMatches = EMAIL_PATTERN.exec(email);
            if (!emailMatches) {
                return false;
            } else if (!isValidUser(emailMatches[1])) {
                return false;
            } else if (!EMAIL_DOMAIN_PATTERN.test(emailMatches[2])) {
                return false;
            }
            return true;
        }
    };
}

export const isEmailValid = createEmailValidation();

function createNotEmptyValidation(): ValidationCallback<string> {
    return value => {
        return !(typeof value !== 'string' || value.trim().length < 1);
    };
}

export const validateNotEmpty = createNotEmptyValidation();

function isValidUser(user: string) {
    return user != null && user.length <= 64 ? USER_PATTERN.exec(user) : false;
}
