import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import result from 'lodash/result';

import { isAxiosError } from '../../api';
import { NoSiteAliasError } from '../domain/errors';
import { isIPv4OrAWSDomain } from '../isIPv4';
import { isSSR } from '../nextjs/ssr';

export enum LoggingEnvironment {
    ANY = 'any',
    DEV = 'dev',
    STAGE = 'stage',
    PROD = 'prod'
}

const defaultLogEnv = [LoggingEnvironment.ANY];
const isDevelopment = process.env.NODE_ENV === 'development';

export default abstract class LoggingUtils {
    public static hasLoggingEnv(logEnv: Array<LoggingEnvironment>): boolean {
        if (logEnv.includes(LoggingEnvironment.ANY)) return true;

        const currentEnvironment: string | undefined = result<string | undefined>(Sentry, [
            'getCurrentHub',
            'getClient',
            'getOptions',
            'environment'
        ]);

        return !isEmpty(currentEnvironment) && logEnv.some(env => env === currentEnvironment);
    }

    public static logWarning(message: string, logEnv: Array<LoggingEnvironment> = defaultLogEnv) {
        const logAllowed = this.hasLoggingEnv(logEnv);

        if (logAllowed) Sentry.captureMessage(message, 'warning');

        // eslint-disable-next-line no-console
        if (isDevelopment) console.warn(message);
    }

    public static logError(error: Error, logEnv: Array<LoggingEnvironment> = defaultLogEnv) {
        const logAllowed = this.hasLoggingEnv(logEnv);

        if (logAllowed) Sentry.captureException(error);

        // eslint-disable-next-line no-console
        if (isDevelopment) console.error(error);
    }

    public static logInfoWithPayload(
        message: string,
        payload: object,
        logEnv: Array<LoggingEnvironment> = defaultLogEnv
    ) {
        this.logWithExtra(message, 'payload', payload, 'info', logEnv);

        // eslint-disable-next-line no-console
        if (isDevelopment) console.info(message, payload);
    }

    public static logWarningWithPayload(
        message: string,
        payload: unknown,
        logEnv: Array<LoggingEnvironment> = defaultLogEnv
    ) {
        this.logWithExtra(message, 'payload', payload, 'warning', logEnv);

        // eslint-disable-next-line no-console
        if (isDevelopment) console.warn(message, payload);
    }

    public static logErrorWithPayload(
        message: string,
        payload: unknown,
        logEnv: Array<LoggingEnvironment> = defaultLogEnv
    ) {
        this.logWithExtra(message, 'payload', payload, 'error', logEnv);

        // eslint-disable-next-line no-console
        if (isDevelopment) console.error(message, payload);
    }

    public static logSSRWarning(msg: string) {
        // eslint-disable-next-line no-console
        if (isSSR()) console.warn(`[SSR] ${msg}`);
    }

    public static logSSRError(msg: string, error?: unknown) {
        if (isSSR() && error instanceof Error) {
            // Don't log 4xx API responses. Those are visible in Instana and generally indicate an invalid link,
            // which we don't want to log.
            if (isAxiosError(error) && error.response && error.response.status >= 400 && error.response.status <= 499) {
                return;
            }

            // Don't log NoSiteAliasError if host is an ip address or aws domain, because those are probably bots.
            if (error instanceof NoSiteAliasError && isIPv4OrAWSDomain(error.host)) return;

            let optionalAxiosInformation = '';

            if (isAxiosError(error)) {
                try {
                    const axiosConfig = JSON.stringify(error.config ?? error.response?.config);
                    optionalAxiosInformation = ` axios-config: ${axiosConfig}`;
                } catch (stringifyError) {
                    // eslint-disable-next-line no-console
                    console.error(stringifyError);
                }
                try {
                    const axiosData = JSON.stringify(error.response?.data);
                    optionalAxiosInformation += ` axios-data: ${axiosData}`;
                } catch (stringifyError) {
                    // eslint-disable-next-line no-console
                    console.error(stringifyError);
                }
            }

            // eslint-disable-next-line no-console
            console.error(`[SSR] ${msg}: message: ${error.message} stack: ${error.stack}` + optionalAxiosInformation);
        }
    }

    private static logWithExtra(
        message: string,
        extraKey: string,
        extra: unknown,
        severity: SeverityLevel,
        logEnv: Array<LoggingEnvironment> = defaultLogEnv
    ) {
        const logAllowed = this.hasLoggingEnv(logEnv);

        if (logAllowed) {
            Sentry.withScope(scope => {
                scope.setExtra(extraKey, extra);
                Sentry.captureMessage(message, severity);
            });
        }
    }
}
