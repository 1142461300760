/**
 * Calculates an offset from the given page and page size.
 */
export function getOffsetForPage(page: number, pageSize: number): number {
    return (page - 1) * pageSize;
}

/**
 * Calculates a page from the given offset and limit.
 */
export function getPageForOffset(offset: number, limit: number): number {
    return limit > 0 ? Math.floor(offset / limit) + 1 : 1;
}

/**
 * Calculates the total number of pages based on the total number of items across all pages and the limit of items
 * that are displayed at once (items per page).
 */
export function getPageCountFromTotalCount(totalCount: number, limit: number): number {
    return limit > 0 ? Math.ceil(totalCount / limit) : 1;
}
