import { RefObject, useEffect } from 'react';

type Options = {
    /**
     * A ref or multiple refs to elements which are ignored (clicks to these elements won't count as clicks outside).
     */
    readonly ignore?: RefObject<HTMLElement> | Array<RefObject<HTMLElement>>;

    /**
     * A ref to an element which encloses the DOM element of interest (clicks to elements outside this ref are ignored).
     */
    readonly boundary?: RefObject<HTMLElement>;
};

/**
 * Custom React Hook for detecting clicks outside a DOM element.
 *
 * @param ref A React Ref to the DOM element of interest. Clicks outside this element will trigger the given handler.
 * @param handler Triggered when a click outside the DOM element is detected.
 * @param options Additional refs to important elements.
 */
export function useClickOutside(ref: RefObject<HTMLElement>, handler: () => void, options?: Options) {
    useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            if (options?.boundary?.current && !options?.boundary.current.contains(event.target as Node)) {
                return;
            }
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }
            if (options?.ignore) {
                const refs = Array.isArray(options.ignore) ? options.ignore : [options.ignore];
                if (refs.some(ignore => ignore.current?.contains(event.target as Node) === true)) {
                    return;
                }
            }
            handler();
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, options]);
}
