import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query';
import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { usePatronInfo, useUserRole } from './AuthenticationContext';
import { SiteParams } from '../api';
import { ApiCredit, fetchCredits } from '../api/apiFetchCredits';
import { useSiteParams } from '../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../setup/axios';
import { LoanFormatType } from '../utils/domain/loanFormat';

export type Credit = ApiCredit;

const EMPTY_CREDITS: Credit = {
    granted: 0,
    amount: 0,
    loanFormat: LoanFormatType.ebooks
};

type Context = {
    credits: ReadonlyArray<Credit>;
};

const emptyCredits: Context = {
    credits: []
};

const ctx = createContext<Context>(emptyCredits);

export const CreditsContext: FC<PropsWithChildren> = ({ children }) => {
    const { isFullUser } = useUserRole();

    if (!isFullUser) {
        return <ctx.Provider value={emptyCredits}>{children}</ctx.Provider>;
    }

    return <CreditsContextSignedIn>{children}</CreditsContextSignedIn>;
};

const CreditsContextSignedIn: FC<PropsWithChildren> = ({ children }) => {
    const { data, isError } = useCreditsQuery();

    if (isError) {
        return <ctx.Provider value={emptyCredits}>{children}</ctx.Provider>;
    }

    const credits = data ?? emptyCredits;
    return <ctx.Provider value={credits}>{children}</ctx.Provider>;
};

export const useCredits = (): Context => {
    return useContext(ctx);
};

export const useCreditsForLoanFormat = (loanFormat: LoanFormatType | undefined): Credit => {
    const { credits } = useContext(ctx);
    return credits.find(credit => credit.loanFormat === loanFormat) ?? EMPTY_CREDITS;
};

type QueryParams = SiteParams & {
    readonly userId: string;
};

const BASE_QUERY_KEY = 'credits';

function queryKey({ siteId, userId }: QueryParams): QueryKey {
    return [BASE_QUERY_KEY, { userId, siteId /* language is irrelevant for credits */ }];
}

/**
 * Do not move the react-query hook to the `api` folder. We don't want to access the credits via
 * react query, but rather via this context to have exactly one source of truth, which is here.
 */
function useCreditsQuery() {
    const { siteId, language } = useSiteParams();
    const { userId } = usePatronInfo();
    const { isFullUser } = useUserRole();

    const queryParams = { siteId, language, userId };

    return useQuery({
        queryKey: queryKey(queryParams),
        queryFn: () => fetchCredits(browserClient, queryParams),
        enabled: isFullUser,
        staleTime: 5_000,
        refetchOnWindowFocus: true
    });
}

export function prefetchCreditsQuery(client: ApiClient, queryClient: QueryClient, queryParams: QueryParams) {
    return queryClient.fetchQuery({
        queryKey: queryKey(queryParams),
        queryFn: () => fetchCredits(client, queryParams)
    });
}

/**
 * Remove the cache for the credits and do >NOT< trigger a refetch.
 */
export const removeCredits = (client: QueryClient) => {
    client.removeQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};

export const invalidateCredits = (client: QueryClient) => {
    return client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};
