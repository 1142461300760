import { QueryClient, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SiteParams } from '../api';
import { ApiPatronInfo, fetchPatronInfo } from '../api/apiFetchPatronInfo';
import { useSessionExtension } from '../api/authentication/useSessionExtension';
import { useSiteParams } from '../hooks/getters/useSiteParams';
import {
    browserClient,
    ejectBrowserClientRequestInterceptor,
    addBrowserClientRequestInterceptor
} from '../setup/axios';
import { UserRole } from '../utils/domain/authorization';

const emptyPatronInformation = {
    userId: '',
    siteId: '',
    siteName: '',
    email: '',
    fullyRegistered: false,
    username: '',
    displayName: '',
    language: ''
};

type Context = {
    patronInfo: ApiPatronInfo;
    setUserId: (userId: string) => void;
};

const ctx = createContext<Context>({
    patronInfo: emptyPatronInformation,
    setUserId: () => {}
});

type Props = PropsWithChildren<{
    readonly initialUserId?: string;
}>;

export const AuthenticationContext: FC<Props> = ({ initialUserId, children }) => {
    const [userId, setUserId] = useState(initialUserId ?? emptyPatronInformation.userId);

    useSessionExtension();

    // Add userId to the browserClient headers as app-patronId
    useEffect(() => {
        const interceptor = addBrowserClientRequestInterceptor(config => {
            if (userId) config.headers['app-patronId'] = userId;
            return config;
        });

        return () => ejectBrowserClientRequestInterceptor(interceptor);
    }, [userId]);

    const { data: patronInfo } = usePatronInfoQuery({
        userId
    });
    const value = useMemo(
        () => ({
            patronInfo: patronInfo ?? emptyPatronInformation,
            setUserId
        }),
        [patronInfo]
    );

    return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export const usePatronInfo = (): ApiPatronInfo => {
    return useContext(ctx).patronInfo;
};

type UseUserRole = {
    readonly role: UserRole;
    readonly isGuestUser: boolean;
    readonly isFullUser: boolean;
    readonly isTempUser: boolean;
};

export const useUserRole = (): UseUserRole => {
    const patronInfo = usePatronInfo();

    const isGuestUser = useMemo(() => isEqual(patronInfo, emptyPatronInformation), [patronInfo]);
    const isFullUser = !isGuestUser && patronInfo.fullyRegistered;
    const isTempUser = !isGuestUser && !isFullUser;

    return useMemo(
        () => ({
            role: isGuestUser ? UserRole.GUEST : isFullUser ? UserRole.FULL : UserRole.TEMP,
            isGuestUser,
            isFullUser,
            isTempUser
        }),
        [isGuestUser, isFullUser, isTempUser]
    );
};

const BASE_QUERY_KEY = 'patronInfo';

function queryKey({ siteId }: SiteParams, userId: string): QueryKey {
    return [BASE_QUERY_KEY, { userId, siteId /* language is irrelevant for patron info */ }];
}

type PatronInfoQueryOptions = {
    readonly userId: string;
    readonly onSuccess?: (patronInfo: ApiPatronInfo) => void;
    readonly onError?: (error: Error) => void;
};

/**
 * Do not move this react-query hook to the `api` folder. We don't want to access the patron info via react query,
 * but rather via this contexts. To have exactly one source of truth, we leave this here.
 */
function usePatronInfoQuery({ userId }: PatronInfoQueryOptions) {
    const { siteId, language } = useSiteParams();

    const queryParams = { siteId, language };

    return useQuery({
        queryKey: queryKey(queryParams, userId),
        queryFn: () => fetchPatronInfo(browserClient, queryParams),
        retry: false
    });
}

export function savePatronInfoQuery(
    client: QueryClient,
    siteParams: SiteParams,
    patronInfo: ApiPatronInfo | undefined
) {
    const patronInfoData = patronInfo ?? emptyPatronInformation;
    client.setQueryData(queryKey(siteParams, patronInfoData.userId), patronInfoData);
}

export const usePatronInfoUpdate = () => {
    const client = useQueryClient();
    const { siteId, language } = useSiteParams();
    const setUserId = useContext(ctx).setUserId;

    return useCallback(
        (patronInfo: ApiPatronInfo | undefined) => {
            const siteParams = { siteId, language };
            savePatronInfoQuery(client, siteParams, patronInfo);
            setUserId(patronInfo?.userId ?? emptyPatronInformation.userId);
        },
        [client, siteId, language, setUserId]
    );
};

/**
 * removePatronInfo removes the cache for the patron information and does >NOT< trigger a refetch.
 */
export const removePatronInfo = (client: QueryClient) => {
    client.removeQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};

/**
 * invalidatePatronInfo triggers a refetch of the patron information.
 */
export const invalidatePatronInfo = (client: QueryClient) => {
    return client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};
