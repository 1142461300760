import { KeyboardEventHandler, useCallback } from 'react';

import { KEY } from '../../utils/events/keyboardevents';

const ESC_KEY_CODE = 27;

export function useEscape(callback: () => void): KeyboardEventHandler {
    return useCallback(
        event => {
            if (event.key === KEY.ESCAPE || event.keyCode === ESC_KEY_CODE) {
                callback();
            }
        },
        [callback]
    );
}
