/**
 * Returns `true` if code is executed on the server (Server Side Rendering)
 * using this way of checking for server side ensures the code is removed on the client side
 * see https://github.com/vercel/next.js/issues/5354#issuecomment-520305040
 */
export const isSSR = () => typeof window === 'undefined';

/**
 * Returns `true` if code is executed on the client (Client Side Rendering)
 * using this way of checking for server side ensures the code is removed on the client side
 * see https://github.com/vercel/next.js/issues/5354#issuecomment-520305040
 */
export const isCSR = () => !isSSR();
