import { SiteParams } from './index';
import { ApiClient } from '../setup/axios';
import { LoanFormatType } from '../utils/domain/loanFormat';

export type ApiCredit = {
    loanFormat: LoanFormatType;
    amount: number;
    granted: number;
};

export type ApiCredits = {
    credits: ReadonlyArray<ApiCredit>;
};

/**
 * Do not move the corresponding react-query hook to the `api` folder. We don't want to access the credits via
 * react query, but rather via this context to have exactly one source of truth, which is the CreditsContext.
 */
export function fetchCredits(client: ApiClient, params: SiteParams): Promise<ApiCredits> {
    return client.get<{ credits: ReadonlyArray<ApiCredit> }>('/api/v1/credits', params).then(result => result.data);
}
