import { FC } from 'react';

import { ErrorPage } from './ErrorPage';

export const UnknownSitePage: FC = () => {
    return (
        <ErrorPage>
            <p>
                We could not find this library.
                <br />
                Please contact your local library for help.
            </p>
        </ErrorPage>
    );
};
