import { UnsupportedLoanFormatError } from './errors';
import { ApiLoanInfo } from '../../api/loan/apiGetLoan';

export enum LoanFormatType {
    eaudiobooks = 'eAudiobooks',
    ebooks = 'eBooks',
    eMagazines = 'eMagazines',
    eMagazineIssues = 'eMagazineIssues'
}

export const ALL_LOAN_FORMATS: ReadonlyArray<LoanFormatType> = Object.values(LoanFormatType);

export type GildLoanFormatClass = 'ebook' | 'eaudiobook' | 'emagazine';

export function loanFormatToGildClass(productLoanFormat: LoanFormatType): GildLoanFormatClass {
    switch (productLoanFormat) {
        case LoanFormatType.eaudiobooks:
            return 'eaudiobook';
        case LoanFormatType.ebooks:
            return 'ebook';
        case LoanFormatType.eMagazineIssues:
            return 'emagazine';
        case LoanFormatType.eMagazines:
            return 'emagazine';
    }
}

export type LoanFormatHash<T> = Record<LoanFormatType, T>;

type LoanFormatTypeForLoanInfo = Exclude<LoanFormatType, LoanFormatType.eMagazines>;
type LoanInfosByFormat = Record<LoanFormatTypeForLoanInfo, Array<ApiLoanInfo>>;

export const getLoanFormatMergingMagazines = (loanInfo: ApiLoanInfo): LoanFormatTypeForLoanInfo => {
    const { loanFormat } = loanInfo.product.formatSpecifics;
    return loanFormat === LoanFormatType.eMagazines ? LoanFormatType.eMagazineIssues : loanFormat;
};

export const separateByLoanFormatMergingMagazines = (mixed: ReadonlyArray<ApiLoanInfo>): LoanInfosByFormat => {
    const result: LoanInfosByFormat = {
        [LoanFormatType.ebooks]: [],
        [LoanFormatType.eaudiobooks]: [],
        [LoanFormatType.eMagazineIssues]: []
    };
    for (const item of mixed) {
        const loanFormat = getLoanFormatMergingMagazines(item);
        result[loanFormat].push(item);
    }

    return result;
};

export function parseLoanFormatType(raw: string): LoanFormatType {
    const normalized = raw.toLowerCase().replace(/[_\W]/g, '');

    switch (normalized) {
        case 'ebooks':
            return LoanFormatType.ebooks;
        case 'eaudiobooks':
            return LoanFormatType.eaudiobooks;
        case 'emagazines':
        case 'epress':
        case 'emagazine':
        case 'enews':
            return LoanFormatType.eMagazines;
        case 'emagazineissues':
            return LoanFormatType.eMagazineIssues;
        default:
            throw new UnsupportedLoanFormatError(normalized);
    }
}

export const aggregateTotalCount = (totalCount: Record<string, number>) => {
    return Object.values(totalCount).reduce((acc, curr) => acc + curr, 0);
};

type LoanFormatItem = { readonly loanFormat: LoanFormatType };

const DEFAULT_LOAN_FORMAT_ORDER = [
    LoanFormatType.ebooks,
    LoanFormatType.eaudiobooks,
    LoanFormatType.eMagazines,
    LoanFormatType.eMagazineIssues
];

const byLoanFormatOrder = (a: LoanFormatItem, b: LoanFormatItem) =>
    DEFAULT_LOAN_FORMAT_ORDER.indexOf(a.loanFormat) > DEFAULT_LOAN_FORMAT_ORDER.indexOf(b.loanFormat) ? 1 : -1;

export const sortByDefaultLoanFormatOrder = <T extends ReadonlyArray<LoanFormatItem>>(items: T) => {
    return [...items].sort(byLoanFormatOrder);
};
