import { isPresent } from '../utils/objectChecks';

export const appendInParenthesis = (value: string) => (isPresent(value) ? ` (${value})` : '');

export const simpleList = (value: unknown) => {
    // as of April 2023 joining list items with ', ' from left to right is correct for all supported languages.
    // If this ever changes, we will need a special treatment for those cases and/or use Intl.ListFormat() which can
    // be accessed directly through i18next's 'list' formatter. At the time of writing, Intl.ListFormat() requires
    // polyfills for Welsh in Chromium (see src/setup/polyfills/checkIntlPolyfills.js) and doesn't respect the style
    // prop for German across all platforms (it always returns the 'long' style, i.e. "Katze, Hund und Kuh")
    return Array.isArray(value) ? value.join(', ') : String(value);
};

export const fileSize = (fileSizeInByte: number, lng: string | undefined, options: Intl.NumberFormatOptions) => {
    const { fileSize, unit } = getFileSizeInAppropriateUnit(fileSizeInByte);
    const formatter = new Intl.NumberFormat(lng, { style: 'unit', unit, maximumFractionDigits: 0, ...options });

    return formatter.format(fileSize);
};

export const getFileSizeInAppropriateUnit = (fileSizeInByte: number) => {
    const GIGABYTE = 1_000_000_000;
    const MEGABYTE = 1_000_000;
    const KILOBYTE = 1_000;

    let unit: string;
    let fileSize: number;

    if (fileSizeInByte >= GIGABYTE) {
        unit = 'gigabyte';
        fileSize = fileSizeInByte / GIGABYTE;
    } else if (fileSizeInByte >= MEGABYTE) {
        unit = 'megabyte';
        fileSize = fileSizeInByte / MEGABYTE;
    } else if (fileSizeInByte >= KILOBYTE) {
        unit = 'kilobyte';
        fileSize = fileSizeInByte / KILOBYTE;
    } else {
        unit = 'kilobyte';
        fileSize = 1; // always show at least 1 kB for files smaller than that
    }

    return {
        fileSize,
        unit
    };
};
