import { ApiClient } from '../../setup/axios';
import { ApiPatronInfo } from '../apiFetchPatronInfo';
import { SiteParams } from '../index';

export const API_INTERNAL_LOGIN = '/api/v1/auth/login';

export type LoginOptions = SiteParams & {
    readonly username: string;
    readonly password: string;
};

export function internalLogin(
    client: ApiClient,
    { username, password, ...params }: LoginOptions
): Promise<ApiPatronInfo> {
    return client
        .post<ApiPatronInfo>(
            API_INTERNAL_LOGIN,
            {
                username,
                password
            },
            params
        )
        .then(res => res.data);
}
