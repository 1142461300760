import { useRouter } from 'next/router';
import { FC, useEffect, useMemo } from 'react';

import { ProductsPageType } from '../../components/layout/header/Navigation';
import { parseAgeCategoryType } from '../../domain/ageCategory';
import { parseLoanFormatType } from '../domain/loanFormat';
import { isSSR } from '../nextjs/ssr';
import { redactEzProxySsoToken } from '../scrubbing';

type TrackGlobalProps = {
    readonly siteId?: string;
    readonly siteName?: string;
    readonly userId?: string;
};

export const InstanaTrackGlobal: FC<TrackGlobalProps> = ({ siteId, siteName, userId }) => {
    const router = useRouter();
    const { pathname, query } = router ?? {};
    const { productId, performerId, loanFormatPath, ageCategoryPath, seriesId, genreId, productGroupId } = query;

    const loanFormat = useMemo(() => {
        if (loanFormatPath) {
            try {
                return parseLoanFormatType(loanFormatPath as string);
            } catch (error) {}
        }
    }, [loanFormatPath]);

    const ageCategory = useMemo(() => {
        if (ageCategoryPath) {
            try {
                return parseAgeCategoryType(ageCategoryPath as string);
            } catch (error) {}
        }
    }, [ageCategoryPath]);

    useEffect(() => {
        if (!isInstanaEnabled() || !window.ineum) return;

        if (userId) {
            window.ineum('user', userId);
        }
        if (siteId) {
            window.ineum('meta', 'siteId', siteId);
        }
        if (siteName) {
            window.ineum('meta', 'metaSiteName', siteName);
        }
        if (typeof genreId === 'string') {
            window.ineum('meta', 'genreId', genreId);
        }
        if (typeof productId === 'string') {
            window.ineum('meta', 'productId', productId);
        }
        if (typeof performerId === 'string') {
            window.ineum('meta', 'performerId', performerId);
        }
        if (typeof productGroupId === 'string') {
            window.ineum('meta', 'productGroupId', productGroupId);
        }
        if (typeof seriesId === 'string') {
            window.ineum('meta', 'seriesId', seriesId);
        }
        if (loanFormat) {
            window.ineum('meta', 'loanFormat', loanFormat);
        }
        if (ageCategory) {
            window.ineum('meta', 'ageCategory', ageCategory);
        }

        // Set page last, because this immediately triggers a page transition event, and we want to include all those
        // valuable metadata entries in it :-)
        window.ineum('page', redactEzProxySsoToken(pathname));
    }, [
        siteId,
        siteName,
        userId,
        pathname,
        genreId,
        productId,
        performerId,
        productGroupId,
        seriesId,
        loanFormat,
        ageCategory
    ]);

    return null;
};

type TrackProductPageProps = {
    readonly pageType: ProductsPageType;
};

export const InstanaTrackProductPage: FC<TrackProductPageProps> = ({ pageType }) => {
    useEffect(() => {
        if (!isInstanaEnabled() || !window.ineum) return;

        window.ineum('meta', 'pageType', pageType);
    }, [pageType]);

    return null;
};

export function isInstanaEnabled(): boolean {
    if (isSSR()) {
        // this environment variable is injected into the server process via gitops:
        // - platform/gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        // - platform/gitops/us-east-1/stage/team-website/patron-point-ui/values.yaml
        return process.env['INSTANA_ENABLED']?.toLowerCase() === 'true';
    }

    return document.documentElement.getAttribute('data-instana-enabled')?.toLowerCase() === 'true';
}
