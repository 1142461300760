import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import sortedUniq from 'lodash/sortedUniq';

import { ApiFiltersRequest } from '../../api/filters';
import { ProductFilterParams } from '../../hooks/getters/useProductListParams';
import { isPresent } from '../../utils/objectChecks';

/**
 * Brings a filter object into a standardized form. A standardized form means that a) empty filters are removed,
 * b) the filter keys are sorted, and c) the filter keys are deduplicated.
 *
 * This results in a stable filter object, which can be used as a query key for React Query.
 */
export function standardizeFilterParams(params: ProductFilterParams): ProductFilterParams {
    return mapValues(pickBy(params, isPresent), filters => sortedUniq(sortBy(filters)));
}

/**
 * Brings a filter request into a standardized form.
 */
export function standardizeFiltersRequest(request: ApiFiltersRequest): ApiFiltersRequest {
    return {
        ...request,
        filters: standardizeFilterParams(request.filters)
    };
}
