import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiContextLibrary } from '../../api/apiLibraryContext';
import { HighlightingFilterOptionLabel } from '../../components/HighlightingFilterOptionLabel';
import { BasicLayout } from '../../components/layout/BasicLayout';
import { loginRoutePath } from '../../pages/login/[[...any]]';
import LoggingUtils from '../../utils/logging/LoggingUtils';
import { isSSR } from '../../utils/nextjs/ssr';
import { isEmpty, isPresent } from '../../utils/objectChecks';

const SEARCH_THRESHOLD = 10; // make list scrollable and show search bar for long lists

type SiteSelectorProps = {
    readonly siteContext: ApiContextLibrary;
    readonly redirectTo: string;
};

export const SiteSelector: FC<SiteSelectorProps> = ({ siteContext, redirectTo }) => {
    const { t } = useTranslation();

    const { frontendSiteName: title, networkSites } = siteContext;
    const libraries = networkSites?.filter(hasValidLink).sort(alphabetically) ?? [];

    const [searchTerm, setSearchTerm] = useState('');
    const [keywords, setKeywords] = useState<ReadonlyArray<string>>([]);
    const [librariesFiltered, setLibrariesFiltered] = useState(libraries);

    if (isEmpty(libraries)) {
        LoggingUtils.logErrorWithPayload('no libraries set', siteContext);
    }

    const hasSearch = libraries.length > SEARCH_THRESHOLD;

    const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        const getKeywords = (text: string) => text.toLowerCase().split(' ');
        const value = event.target.value;
        const keywords = getKeywords(value);

        setSearchTerm(value);
        setKeywords(keywords);

        setLibrariesFiltered(
            libraries.filter(({ name }) => {
                const libraryWords = getKeywords(name);
                return keywords.every(keyword => libraryWords.some(word => word.startsWith(keyword)));
            })
        );
    };

    return (
        <BasicLayout className="single-form-page library-select-page">
            <div className="container size-narrow">
                <div className="form-page-group">
                    <h1>{title}</h1>
                    <p>{t('login.site_select.select_library')}</p>
                </div>
                <div className={classNames('component searchable-item-picker ', { 'has-search': hasSearch })}>
                    {hasSearch ? (
                        <div className="list-search">
                            <input
                                type="text"
                                placeholder={t('login.site_select.search_placeholder')}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                            />
                            <div className="submit">
                                <span className="icon search" />
                            </div>
                        </div>
                    ) : null}
                    {isPresent(librariesFiltered) ? (
                        <div className="scrollable">
                            <ul className="items">
                                {[...librariesFiltered].map(({ name, link }) => (
                                    <li key={link}>
                                        <a href={buildHref(link, redirectTo)}>
                                            <span className="label">
                                                <HighlightingFilterOptionLabel label={name} keywords={keywords} />
                                            </span>
                                            <span className="icon arrow-forward" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="no-results">{t('login.site_select.no_results')}</div>
                    )}
                </div>
            </div>
        </BasicLayout>
    );
};

const buildHref = (link: string, redirectTo: string) => {
    return ['https://', link, '.', getHost(), loginRoutePath({ redirectTo })].join('');
};

const getHost = () => {
    if (isSSR()) {
        return ['local', 'stage'].includes(process.env.BOL_ENVIRONMENT ?? '') ? 'borrowbox.io' : 'borrowbox.com';
    }

    return process.env.NODE_ENV === 'production'
        ? document.location.host.slice(document.location.host.indexOf('.') + 1)
        : 'borrowbox.io';
};

const hasValidLink = (item: { link: unknown }) => typeof item.link === 'string';

const alphabetically = (itemA: { name: string }, itemB: { name: string }) => {
    const nameA = itemA.name.toUpperCase();
    const nameB = itemB.name.toUpperCase();

    return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
};
