import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useRedirect = () => {
    const router = useRouter();
    return useCallback(
        async (to: string, shallow: boolean = false) => {
            // We don't want to redirect if we're already on the page
            if (router.asPath === to) return false;

            return router.push(to, undefined, { shallow });
        },
        [router]
    );
};
