import cookie, { CookieSerializeOptions } from 'cookie';
import { IncomingMessage, ServerResponse } from 'http';

import LoggingUtils from './logging/LoggingUtils';
import { isSSR } from './nextjs/ssr';

const base64Encode = isSSR() ? (s: string) => Buffer.from(s).toString('base64') : btoa;
const base64Decode = isSSR() ? (s: string) => Buffer.from(s, 'base64').toString() : atob;

export function deserializeCookie(cookie: string): unknown {
    try {
        const decoded = base64Decode(cookie);
        return JSON.parse(decoded);
    } catch (error) {
        if (cookie) LoggingUtils.logErrorWithPayload('Failed to deserialize cookie', { cookie });
        return null;
    }
}

export function serializeCookie(data: unknown): string {
    return base64Encode(JSON.stringify(data));
}

export function readCookie(name: string, cookieHeader: string | null | undefined): string | undefined {
    if (!cookieHeader) return;

    return cookie.parse(cookieHeader)[name];
}

export function setCookie(name: string, value: string, options?: CookieSerializeOptions): string {
    return cookie.serialize(name, value, options);
}

export function appendSetCookieHeader(res: ServerResponse, setCookieHeader: string) {
    const existing = res.getHeader('Set-Cookie');
    const values = existing === undefined ? [] : Array.isArray(existing) ? existing : [existing.toString()];
    values.push(setCookieHeader);
    res.setHeader('Set-Cookie', values);
}

export function rewriteCookieDomainForDevelopment(req: IncomingMessage, setCookieHeader: string): string {
    if (process.env.NODE_ENV !== 'development') {
        return setCookieHeader;
    }

    const oldDomain = 'localhost';
    const newDomain = req.headers.host?.split(':')[0] ?? oldDomain;
    return setCookieHeader.replace(oldDomain, newDomain);
}

export function setCookieInBrowser(name: string, value: string, options?: CookieSerializeOptions) {
    // This will crash on the server. We think this is better than to fail silently, because it makes the error easier
    // to find.
    document.cookie = cookie.serialize(name, value, options);
}

export function removeCookieFromBrowser(name: string, options?: CookieSerializeOptions) {
    // This will crash on the server. We think this is better than to fail silently, because it makes the error easier
    // to find.
    setCookieInBrowser(name, '', {
        ...options,
        expires: new Date(1970, 1, 1, 0, 0, 1),
        maxAge: 0
    });
}

function getCookieAgeInDays(lastUpdated: string) {
    return (Date.now() - Date.parse(lastUpdated)) / 1_000 / 60 / 60 / 24; // [days]
}

export function needsRefresh(lastUpdated: string, threshold: number) {
    const cookieAgeInDays = getCookieAgeInDays(lastUpdated);
    return cookieAgeInDays > threshold;
}
