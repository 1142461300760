import { useEffect, useRef } from 'react';

type Options = {
    readonly callback: () => void;
    readonly delay: number;
};

// taken from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval({ callback, delay }: Options) {
    const savedCallback = useRef(() => {});

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
