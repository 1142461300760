import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const RouteTransitionIndicator = () => {
    const [transitionState, setTransitionState] = useState<'IDLE' | 'BUSY' | 'DONE'>('IDLE');

    const isIdle = transitionState === 'IDLE';
    const isBusy = transitionState === 'BUSY';
    const isDone = transitionState === 'DONE';

    const router = useRouter();

    useEffect(() => {
        const start = () => {
            setTransitionState('IDLE');
            requestAnimationFrame(() => setTransitionState('BUSY'));
        };

        const complete = () => {
            setTransitionState('DONE');
        };

        router.events.on('routeChangeStart', start);
        router.events.on('routeChangeComplete', complete);
        router.events.on('routeChangeError', complete);

        return () => {
            router.events.off('routeChangeStart', start);
            router.events.off('routeChangeComplete', complete);
            router.events.off('routeChangeError', complete);
        };
    }, [router.events]);

    return (
        <div className={classNames('route-transition-indicator', { busy: isBusy, done: isDone, idle: isIdle })}>
            <div className={classNames('progress', { busy: isBusy, done: isDone, idle: isIdle })} />
        </div>
    );
};
