import { AvailabilityParams, PaginationParams, SortParams } from '.';
import { ApiProductsRequest } from './products';
import { ReadingListSortOrder } from './reading-list/apiFetchReadingListProductsByFormat';

export type SortOrder =
    | (
          | 'relevance'
          | 'releaseDateNewToOld'
          | 'releaseDateOldToNew'
          | 'pagesMoreToLess'
          | 'pagesLessToMore'
          | 'durationLongToShort'
          | 'durationShortToLong'
          | 'seriesAZ'
          | 'titleAZ'
          | 'authorAZ'
          | 'narratorAZ'
          | 'loanDateNewToOld'
          | 'loanDateOldToNew'
          | 'featured'
          | 'mostPopular'
          | 'leastPopular'
          | 'newToLibrary'
          | 'custom'
      )
    | ReadingListSortOrder;

export enum SearchOperation {
    Or = 'DISJUNCTION',
    And = 'CONJUNCTION'
}

export enum SearchField {
    All = 'ALL',
    Title = 'TITLE',
    Genre = 'GENRE',
    Series = 'SERIES',
    Author = 'AUTHOR',
    Narrator = 'NARRATOR',
    Isbn = 'ISBN'
}

/**
 * Common properties which are passed to the search results page endpoints.
 * Currently, these endpoints are the search result filters and search result
 * products.
 */
export type SearchRequest = {
    readonly searchOperation: SearchOperation;
    readonly searchTerm: {
        [F in SearchField]?: string;
    };
    readonly fuzzy: boolean;
};

export type SearchProductsRequestBody = ApiProductsRequest & SearchRequest;

export type SearchProductsRequestParams = PaginationParams & SortParams & AvailabilityParams;
