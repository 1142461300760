import merge from 'lodash/merge';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import {
    ApiContextLibrary,
    ApiContextLibraryTheme,
    ApiContextLoanPeriods,
    ApiContextLogin,
    LanguageSelectionDisplay
} from '../api/apiLibraryContext';
import { DEFAULT_COLLECTION_SLUG } from '../utils/constants';
import { INavigationState, parseNavigationState } from '../utils/navigation/parseNavigationState';

export type LibraryContextValue = {
    readonly theme: ApiContextLibraryTheme | undefined;
    readonly login: ApiContextLogin | undefined;
    readonly loanPeriods: ApiContextLoanPeriods | undefined;
    readonly timezone: string | undefined;
    readonly siteId: string | undefined;
    readonly homeLink: string | undefined;
    readonly frontendSiteName: string | undefined;
    readonly libraryError: boolean;
    readonly languageSelectionDisplay: LanguageSelectionDisplay;
    readonly navigation: INavigationState;
};

const defaultNavigationState: INavigationState = {
    collections: {
        [DEFAULT_COLLECTION_SLUG]: {
            name: 'Home',
            loanFormats: {}
        }
    }
};

const initialValue: LibraryContextValue = {
    theme: undefined,
    login: undefined,
    loanPeriods: undefined,
    timezone: undefined,
    siteId: undefined,
    homeLink: undefined,
    frontendSiteName: undefined,
    libraryError: false,
    languageSelectionDisplay: LanguageSelectionDisplay.NONE,
    navigation: defaultNavigationState
};

function reduce(state: LibraryContextValue, value: ApiContextLibrary | undefined, error: boolean): LibraryContextValue {
    const navigationState = value?.navigation ? parseNavigationState(value.navigation) : undefined;

    const { loanPeriods, timezone, siteId, homeLink, frontendSiteName, languageSelectionDisplay } = value ?? state;

    const theme = value
        ? {
              design: value.design,
              logoUrl: value.logoUrl
          }
        : state.theme;

    const login = value
        ? {
              ssoServerUrl: value.ssoServerUrl,
              userManagementType: value.userManagementType,
              supportsInternalPasswordReset: value.supportsInternalPasswordReset
          }
        : state.login;

    return {
        theme,
        login,
        loanPeriods,
        timezone,
        siteId,
        homeLink,
        frontendSiteName,
        languageSelectionDisplay,
        navigation: merge({}, state.navigation, navigationState),
        libraryError: error
    };
}

const LibraryContext = createContext<LibraryContextValue>(initialValue);
LibraryContext.displayName = 'LibraryContext';

export function useLibraryContext(): LibraryContextValue {
    return useContext(LibraryContext);
}

type LibraryContextProviderProps = PropsWithChildren<{
    readonly value?: ApiContextLibrary;
    readonly error: boolean;
}>;

export const LibraryContextProvider: FC<LibraryContextProviderProps> = ({ children, value, error }) => {
    const initialRender = useRef(true);
    const [libraryContext, setLibraryContext] = useState<LibraryContextValue>(() => reduce(initialValue, value, error));

    useEffect(() => {
        if (initialRender.current) {
            // do not update library context on initial render, because initial value is already set, and we don't want
            // to trigger an unnecessary rerender when initialized
            initialRender.current = false;
        } else {
            // update library context on subsequent renders
            setLibraryContext(prevState => reduce(prevState, value, error));
        }
    }, [value, error]);

    return <LibraryContext.Provider value={libraryContext}>{children}</LibraryContext.Provider>;
};
