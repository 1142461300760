import { TOptions } from 'i18next';
import compact from 'lodash/compact';
import { Reducer } from 'react';

import { LoanErrorKey } from '../../api/loan/handleLoanSuccessAndError';
import { ApiSubscription } from '../../api/subscriptions/apiGetSubscription';
import { Performer } from '../../domain/performer';
import { AvailabilityStatus, isProductCurrent, Restrictions } from '../../domain/product';
import { LocalResourceType } from '../../setup/i18n';
import { LoanFormatType } from '../../utils/domain/loanFormat';

export enum InitialActionType {
    borrowIntended = 'borrowIntended',
    borrowSubscribeIntended = 'borrowSubscribeIntended',
    reserveIntended = 'reserveIntended'
}

export const isInitialActionType = (actionType: string | null): actionType is InitialActionType => {
    return actionType ? actionType in InitialActionType : false;
};

export enum FollowUpActionType {
    borrowConfirmed = 'borrowConfirmed',
    borrowSucceeded = 'borrowSucceeded',
    borrowFailed = 'borrowFailed',
    borrowConfirmedSubscribeConfirmed = 'borrowConfirmedSubscribeConfirmed',
    borrowConfirmedSubscribeNotConfirmed = 'borrowConfirmedSubscribeNotConfirmed',
    borrowNotConfirmedSubscribeConfirmed = 'borrowNotConfirmedSubscribeConfirmed',
    borrowSucceededSubscribeSucceeded = 'borrowSucceededSubscribeSucceeded',
    borrowSucceededSubscribeFailed = 'borrowSucceededSubscribeFailed',
    borrowFailedSubscribeSucceeded = 'borrowFailedSubscribeSucceeded',
    borrowFailedSubscribeFailed = 'borrowFailedSubscribeFailed',
    subscribeSucceeded = 'subscribeSucceeded',
    subscribeFailed = 'subscribeFailed',
    reserveConfirmed = 'reserveConfirmed',
    reserveSucceeded = 'reserveSucceeded',
    reserveFailed = 'reserveFailed',
    cancelled = 'cancelled',
    download = 'download'
}

export type ProductActionProductData = {
    readonly productId: string;
    readonly rootProductId: string | undefined;
    readonly title: string;
    readonly rootProductTitle: string | undefined;
    readonly authors?: ReadonlyArray<Performer>;
    readonly coverUrl: string;
    readonly loanFormat: LoanFormatType;
    readonly availabilityStatus: AvailabilityStatus;
    readonly availabilityDate: number | undefined;
    readonly latestAvailableIssueId: string | undefined;
    readonly currentPeriodEnd: number | undefined;
    readonly releaseFrequency: string | undefined;
    readonly restrictions: Restrictions | undefined;
};

export type ProductActionLoanData = {
    readonly alreadyOnLoan: boolean;
    readonly alreadyOnReserve: boolean;
    readonly creditsLeftForLoanFormat: number;
    readonly creditsGrantedForLoanFormat: number;
    readonly loanPeriodDays: number;
    readonly subscription?: ApiSubscription;
};

export type ProductActionAction =
    | {
          readonly type: InitialActionType;
          readonly product: ProductActionProductData;
          readonly loanData: ProductActionLoanData;
      }
    | {
          readonly type: FollowUpActionType;
          readonly errorKey?: LoanErrorKey;
      };

type ProductActionButton = {
    readonly actionType: FollowUpActionType;
    readonly label: keyof LocalResourceType['common']['productActionModal']['buttons'];
    readonly className: string;
    readonly disabled?: boolean;
};

type ProductActionMessage =
    | {
          readonly messageType: 'info';
          readonly errorType?: never;
          readonly messageKey: keyof LocalResourceType['common']['productActionModal']['messages'];
          readonly options?: TOptions;
      }
    | {
          readonly messageType: 'error';
          readonly errorType: 'borrow' | 'reserve' | 'subscribe';
          readonly messageKey?: LoanErrorKey;
          readonly options?: TOptions;
      };

type ProductActionStateInitialized = {
    readonly modalVisible: true;
    readonly product: ProductActionProductData;
    readonly loanData: ProductActionLoanData;
    readonly modalTitle: keyof LocalResourceType['common']['productActionModal']['title'];
    readonly modalMessages: ReadonlyArray<ProductActionMessage>;
    readonly modalButtons: ReadonlyArray<ProductActionButton>;
    readonly modalWithCoverAndProductTitle: boolean;
};

type ProductActionStateNone = {
    readonly modalVisible: false;
};

export type ProductActionState = ProductActionStateInitialized | ProductActionStateNone;

const isProductActionStateInitialized = (state: ProductActionState): state is ProductActionStateInitialized => {
    return state.modalVisible && !!state.product && !!state.loanData;
};

class StateNotInitializedError extends Error {
    constructor(action: ProductActionAction) {
        super(`Illegal action: ${action.type}. State must be initialized before dispatching this follow up action.`);
    }
}

export const productActionReducer: Reducer<ProductActionState, ProductActionAction> = (state, action) => {
    switch (action.type) {
        case InitialActionType.borrowIntended:
            return {
                product: action.product,
                loanData: action.loanData,
                modalVisible: true,
                modalWithCoverAndProductTitle: true,
                modalTitle: action.loanData.alreadyOnLoan
                    ? 'alreadyOnLoan'
                    : action.loanData.creditsLeftForLoanFormat <= 0
                      ? 'loanNotPossible'
                      : 'loanConfirmation',
                modalMessages: action.loanData.alreadyOnLoan
                    ? [
                          {
                              messageType: 'info',
                              messageKey: 'alreadyOnLoan',
                              options: { productTitle: action.product.title }
                          }
                      ]
                    : action.loanData.creditsLeftForLoanFormat <= 0
                      ? [
                            {
                                messageType: 'error',
                                errorType: 'borrow',
                                messageKey: 'notEnoughCredits',
                                options: {
                                    productTitle: action.product.title,
                                    creditsGranted: action.loanData.creditsGrantedForLoanFormat,
                                    context: action.product.loanFormat
                                }
                            }
                        ]
                      : compact([
                            {
                                messageType: 'info',
                                messageKey: 'loansLeftAfterBorrowConfirm',
                                options: { count: Math.max(action.loanData.creditsLeftForLoanFormat - 1, 0) }
                            },
                            {
                                messageType: 'info',
                                messageKey: 'loanPeriodDays',
                                options: { count: action.loanData.loanPeriodDays }
                            },
                            action.product.restrictions?.includes('geo')
                                ? {
                                      messageType: 'info',
                                      messageKey: 'restrictionGeoHint'
                                  }
                                : undefined
                        ]),
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label:
                            !action.loanData.alreadyOnLoan && action.loanData.creditsLeftForLoanFormat <= 0
                                ? 'ok'
                                : 'cancel',
                        className: 'button-neutral'
                    },
                    action.loanData.alreadyOnLoan
                        ? {
                              actionType: FollowUpActionType.download,
                              label: action.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                              className: 'button-positive'
                          }
                        : {
                              actionType: FollowUpActionType.borrowConfirmed,
                              label: 'confirmLoan',
                              className: 'button-positive',
                              disabled: action.loanData.creditsLeftForLoanFormat <= 0
                          }
                ]
            };

        case FollowUpActionType.borrowConfirmed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.borrowConfirmed,
                        label: 'confirmLoan',
                        className: 'button-positive',
                        disabled: true
                    }
                ]
            };

        case FollowUpActionType.borrowSucceeded:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: true,
                modalTitle: 'loanSuccessful',
                modalMessages: [
                    {
                        messageType: 'info',
                        messageKey: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow'
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'keepBrowsing',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.download,
                        label: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                        className: 'button-positive'
                    }
                ]
            };

        case FollowUpActionType.borrowFailed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'loanFailed',
                modalMessages: [
                    {
                        messageType: 'error',
                        errorType: 'borrow',
                        messageKey: action.errorKey,
                        options: {
                            productTitle: state.product.title,
                            creditsGranted: state.loanData.creditsGrantedForLoanFormat,
                            context: state.product.loanFormat
                        }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case InitialActionType.reserveIntended:
            return {
                product: action.product,
                loanData: action.loanData,
                modalVisible: true,
                modalWithCoverAndProductTitle: true,
                modalTitle: action.loanData.alreadyOnLoan
                    ? 'alreadyOnLoan'
                    : action.loanData.alreadyOnReserve
                      ? 'alreadyOnReserve'
                      : action.loanData.creditsLeftForLoanFormat <= 0
                        ? 'reserveNotPossible'
                        : 'reserveConfirmation',
                modalMessages: action.loanData.alreadyOnLoan
                    ? [
                          {
                              messageType: 'info',
                              messageKey: 'alreadyOnLoan',
                              options: { productTitle: action.product.title }
                          }
                      ]
                    : action.loanData.alreadyOnReserve
                      ? [
                            {
                                messageType: 'info',
                                messageKey: 'alreadyOnReserve',
                                options: {
                                    productTitle: action.product.title,
                                    availableDate: action.product.availabilityDate
                                }
                            }
                        ]
                      : action.loanData.creditsLeftForLoanFormat <= 0
                        ? [
                              {
                                  messageType: 'error',
                                  errorType: 'reserve',
                                  messageKey: 'notEnoughCredits',
                                  options: {
                                      productTitle: action.product.title
                                  }
                              }
                          ]
                        : compact([
                              {
                                  messageType: 'info',
                                  messageKey: 'loansLeftAfterReserveConfirm',
                                  options: { count: Math.max(action.loanData.creditsLeftForLoanFormat - 1, 0) }
                              },
                              {
                                  messageType: 'info',
                                  messageKey: 'loanPeriodDays',
                                  options: { count: action.loanData.loanPeriodDays }
                              },
                              action.product.restrictions?.includes('geo')
                                  ? {
                                        messageType: 'info',
                                        messageKey: 'restrictionGeoHint'
                                    }
                                  : undefined
                          ]),
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label:
                            action.loanData.creditsLeftForLoanFormat <= 0 &&
                            !action.loanData.alreadyOnLoan &&
                            !action.loanData.alreadyOnReserve
                                ? 'ok'
                                : 'cancel',
                        className: 'button-neutral'
                    },
                    action.loanData.alreadyOnLoan
                        ? {
                              actionType: FollowUpActionType.download,
                              label: action.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                              className: 'button-positive'
                          }
                        : {
                              actionType: FollowUpActionType.reserveConfirmed,
                              label: 'confirmReserve',
                              className: 'button-confirm-reserve',
                              disabled:
                                  action.loanData.alreadyOnReserve || action.loanData.creditsLeftForLoanFormat <= 0
                          }
                ]
            };

        case FollowUpActionType.reserveConfirmed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.reserveConfirmed,
                        label: 'confirmReserve',
                        className: 'button-confirm-reserve',
                        disabled: true
                    }
                ]
            };

        case FollowUpActionType.reserveSucceeded:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: true,
                modalTitle: 'reserveSuccessful',
                modalMessages: [
                    {
                        messageType: 'info',
                        messageKey: 'isReserved',
                        options: {
                            productTitle: state.product.title,
                            availableDate: state.product.availabilityDate
                        }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-positive'
                    }
                ]
            };

        case FollowUpActionType.reserveFailed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'reserveFailed',
                modalMessages: [
                    {
                        messageType: 'error',
                        errorType: 'reserve',
                        messageKey: action.errorKey,
                        options: {
                            productTitle: state.product.title,
                            availableDate: state.product.availabilityDate
                        }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case InitialActionType.borrowSubscribeIntended:
            return {
                product: action.product,
                loanData: action.loanData,
                modalVisible: true,
                modalWithCoverAndProductTitle: true,
                modalTitle: action.loanData.alreadyOnLoan ? 'alreadyOnLoan' : 'loanConfirmation',
                modalMessages: compact([
                    action.loanData.alreadyOnLoan
                        ? {
                              messageType: 'info',
                              messageKey: 'alreadyOnLoan',
                              options: { productTitle: action.product.title }
                          }
                        : !isProductCurrent({ currentPeriodEnd: action.product.currentPeriodEnd })
                          ? {
                                messageType: 'info',
                                messageKey: 'backIssue'
                            }
                          : action.loanData.creditsLeftForLoanFormat
                            ? {
                                  messageType: 'info',
                                  messageKey: 'loansLeftAfterBorrowConfirm',
                                  options: { count: Math.max(action.loanData.creditsLeftForLoanFormat - 1, 0) }
                              }
                            : {
                                  messageType: 'error',
                                  errorType: 'borrow',
                                  messageKey: 'notEnoughCredits',
                                  options: {
                                      productTitle: action.product.title,
                                      creditsGranted: action.loanData.creditsGrantedForLoanFormat,
                                      context: action.product.loanFormat
                                  }
                              },
                    !action.loanData.alreadyOnLoan && action.loanData.creditsLeftForLoanFormat
                        ? {
                              messageType: 'info',
                              messageKey: 'loanPeriodDays',
                              options: { count: action.loanData.loanPeriodDays }
                          }
                        : undefined,
                    action.loanData.subscription?.automaticallyBorrow
                        ? {
                              messageType: 'info',
                              messageKey: 'alreadySubscribed',
                              options: {
                                  magazine: action.product.rootProductTitle,
                                  context: action.product.releaseFrequency
                              }
                          }
                        : !!action.loanData.subscription
                          ? {
                                messageType: 'info',
                                messageKey: 'alreadySubscribedNoAutoBorrow',
                                options: {
                                    magazine: action.product.rootProductTitle
                                }
                            }
                          : {
                                messageType: 'info',
                                messageKey: 'subscribeAndReceiveLatestIssue',
                                options: {
                                    magazine: action.product.rootProductTitle,
                                    context: action.product.releaseFrequency
                                }
                            },
                    action.product.restrictions?.includes('geo')
                        ? {
                              messageType: 'info',
                              messageKey: 'restrictionGeoHint'
                          }
                        : undefined
                ]),
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    action.loanData.alreadyOnLoan
                        ? {
                              actionType: FollowUpActionType.download,
                              label: action.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                              className: 'button-positive'
                          }
                        : {
                              actionType: FollowUpActionType.borrowConfirmedSubscribeNotConfirmed,
                              label: 'borrowIssueOnly',
                              className: 'button-positive',
                              disabled:
                                  isProductCurrent({ currentPeriodEnd: action.product.currentPeriodEnd }) &&
                                  !action.loanData.creditsLeftForLoanFormat
                          },
                    !!action.loanData.subscription
                        ? {
                              actionType: FollowUpActionType.borrowNotConfirmedSubscribeConfirmed,
                              label: 'alreadySubscribed',
                              className: 'button-positive',
                              disabled: true
                          }
                        : action.loanData.alreadyOnLoan ||
                            (isProductCurrent({ currentPeriodEnd: action.product.currentPeriodEnd }) &&
                                !action.loanData.creditsLeftForLoanFormat)
                          ? {
                                actionType: FollowUpActionType.borrowNotConfirmedSubscribeConfirmed,
                                label: 'subscribe',
                                className: 'button-positive'
                            }
                          : {
                                actionType: FollowUpActionType.borrowConfirmedSubscribeConfirmed,
                                label: 'confirmLoanAndSubscribe',
                                className: 'button-positive'
                            }
                ]
            };

        case FollowUpActionType.borrowConfirmedSubscribeConfirmed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.borrowConfirmedSubscribeNotConfirmed,
                        label: 'borrowIssueOnly',
                        className: 'button-positive',
                        disabled: true
                    },
                    {
                        actionType: FollowUpActionType.borrowConfirmedSubscribeConfirmed,
                        label: 'confirmLoanAndSubscribe',
                        className: 'button-positive',
                        disabled: true
                    }
                ]
            };

        case FollowUpActionType.borrowConfirmedSubscribeNotConfirmed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.borrowConfirmedSubscribeNotConfirmed,
                        label: 'borrowIssueOnly',
                        className: 'button-positive',
                        disabled: true
                    },
                    !!state.loanData.subscription
                        ? {
                              actionType: FollowUpActionType.borrowNotConfirmedSubscribeConfirmed,
                              label: 'alreadySubscribed',
                              className: 'button-positive',
                              disabled: true
                          }
                        : state.loanData.alreadyOnLoan ||
                            (isProductCurrent({ currentPeriodEnd: state.product.currentPeriodEnd }) &&
                                !state.loanData.creditsLeftForLoanFormat)
                          ? {
                                actionType: FollowUpActionType.borrowNotConfirmedSubscribeConfirmed,
                                label: 'subscribe',
                                className: 'button-positive',
                                disabled: true
                            }
                          : {
                                actionType: FollowUpActionType.borrowConfirmedSubscribeConfirmed,
                                label: 'confirmLoanAndSubscribe',
                                className: 'button-positive',
                                disabled: true
                            }
                ]
            };

        case FollowUpActionType.borrowNotConfirmedSubscribeConfirmed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'cancel',
                        className: 'button-neutral'
                    },
                    state.loanData.alreadyOnLoan
                        ? {
                              actionType: FollowUpActionType.download,
                              label: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                              className: 'button-positive',
                              disabled: true
                          }
                        : {
                              actionType: FollowUpActionType.borrowConfirmedSubscribeNotConfirmed,
                              label: 'borrowIssueOnly',
                              className: 'button-positive',
                              disabled: true
                          },
                    {
                        actionType: FollowUpActionType.borrowNotConfirmedSubscribeConfirmed,
                        label: 'subscribe',
                        className: 'button-positive',
                        disabled: true
                    }
                ]
            };
        case FollowUpActionType.borrowSucceededSubscribeSucceeded:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'loanAndSubscriptionSuccessful',
                modalMessages: [
                    {
                        messageType: 'info',
                        messageKey: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow'
                    },
                    {
                        messageType: 'info',
                        messageKey: 'subscriptionSuccessful',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'keepBrowsing',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.download,
                        label: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                        className: 'button-positive'
                    }
                ]
            };

        case FollowUpActionType.borrowSucceededSubscribeFailed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'loanSuccessful',
                modalMessages: [
                    {
                        messageType: 'info',
                        messageKey: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow'
                    },
                    {
                        messageType: 'error',
                        errorType: 'subscribe',
                        messageKey: 'unspecific',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'keepBrowsing',
                        className: 'button-neutral'
                    },
                    {
                        actionType: FollowUpActionType.download,
                        label: state.product.loanFormat === LoanFormatType.eaudiobooks ? 'listenNow' : 'readNow',
                        className: 'button-positive'
                    }
                ]
            };

        case FollowUpActionType.borrowFailedSubscribeSucceeded:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'subscriptionSuccessful',
                modalMessages: [
                    {
                        messageType: 'error',
                        errorType: 'borrow',
                        messageKey: action.errorKey,
                        options: {
                            productTitle: state.product.title,
                            creditsGranted: state.loanData.creditsGrantedForLoanFormat,
                            context: state.product.loanFormat
                        }
                    },
                    {
                        messageType: 'info',
                        messageKey: 'subscriptionSuccessful',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case FollowUpActionType.borrowFailedSubscribeFailed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'loanAndSubscriptionFailed',
                modalMessages: [
                    {
                        messageType: 'error',
                        errorType: 'borrow',
                        messageKey: action.errorKey,
                        options: {
                            productTitle: state.product.title,
                            creditsGranted: state.loanData.creditsGrantedForLoanFormat,
                            context: state.product.loanFormat
                        }
                    },
                    {
                        messageType: 'error',
                        errorType: 'subscribe',
                        messageKey: 'unspecific',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case FollowUpActionType.subscribeSucceeded:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'subscriptionSuccessful',
                modalMessages: [
                    {
                        messageType: 'info',
                        messageKey: 'subscriptionSuccessful',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case FollowUpActionType.subscribeFailed:
            if (!isProductActionStateInitialized(state)) throw new StateNotInitializedError(action);

            return {
                ...state,
                modalWithCoverAndProductTitle: false,
                modalTitle: 'subscriptionFailed',
                modalMessages: [
                    {
                        messageType: 'error',
                        errorType: 'subscribe',
                        messageKey: 'unspecific',
                        options: { magazine: state.product.rootProductTitle }
                    }
                ],
                modalButtons: [
                    {
                        actionType: FollowUpActionType.cancelled,
                        label: 'ok',
                        className: 'button-neutral'
                    }
                ]
            };

        case FollowUpActionType.download:
        case FollowUpActionType.cancelled:
        default:
            return { modalVisible: false };
    }
};
