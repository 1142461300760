import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query';

import { ApiActiveLoansAndReservesResponse, fetchActiveLoansAndReserves } from './apiFetchActiveLoansAndReserves';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { SiteParams } from '../index';

type QueryParams = SiteParams & {
    readonly userId: string;
};

const EMPTY_ACTIVE_LOANS_AND_RESERVES: ApiActiveLoansAndReservesResponse = {
    loans: []
};

const BASE_QUERY_KEY = 'activeLoansAndReserves';

const queryKey = ({ siteId, userId }: QueryParams): QueryKey => [
    BASE_QUERY_KEY,
    { siteId, userId /* language is irrelevant for active loans and reserves */ }
];

export const useActiveLoansAndReserves = (): UseQueryResult<'data', ApiActiveLoansAndReservesResponse> => {
    const { siteId } = useSiteParams();
    const { userId } = usePatronInfo();
    const queryParams = { siteId, userId };

    const { data, ...rest } = useQuery({
        queryKey: queryKey(queryParams),
        queryFn: () => fetchActiveLoansAndReserves(browserClient, queryParams)
    });

    return {
        data: data ?? EMPTY_ACTIVE_LOANS_AND_RESERVES,
        ...rest
    };
};

export const prefetchActiveLoansAndReserves = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    queryParams: QueryParams
) =>
    queryClient.fetchQuery({
        queryKey: queryKey(queryParams),
        queryFn: () => fetchActiveLoansAndReserves(apiClient, queryParams)
    });

export const invalidateActiveLoansAndReserves = (client: QueryClient) =>
    client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
