import intersection from 'lodash/intersection';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';

import { useUserRole } from '../context/AuthenticationContext';
import { useCredits } from '../context/CreditsContext';
import { useRedirect } from '../hooks/events/useRedirect';
import { loginRoutePath } from '../pages/login/[[...any]]';
import { getUserPermissions, UserPermission } from '../utils/domain/authorization';
import { isEmpty, isPresent } from '../utils/objectChecks';
import { REGISTER_PATH } from '../utils/routes/paths';

type IProtectedProps = {
    /**
     * The permission(s) one of which is required to access the protected section (children).
     */
    required: UserPermission | Array<UserPermission>;

    /**
     * Fallback in case the user's permissions are not sufficient to access the protected section.
     *
     * Can be a React element or component which is rendered instead of the component or children.
     * Can also be a path (string) which denotes a path that is being redirected to.
     */
    fallback?: string;

    /**
     * The protected section which is rendered if the users permissions are sufficient.
     */
    children?: ReactElement | ReactNode | null;
};

export function Protected({ required, children, fallback }: IProtectedProps) {
    const router = useRouter();
    const redirectTo = useRedirect();
    const { credits } = useCredits();
    const { role, isTempUser, isFullUser } = useUserRole();
    const userPermissions = getUserPermissions(role);

    const requiredPermissions = Array.isArray(required) ? required : [required];
    const matchingPermissions = intersection(requiredPermissions, userPermissions);
    const authorized = isEmpty(requiredPermissions) || isPresent(matchingPermissions);

    useEffect(() => {
        if (isFullUser && isEmpty(credits)) void redirectTo(loginRoutePath({ redirectTo: router.asPath }));

        if (!authorized) {
            if (isTempUser) {
                void redirectTo(REGISTER_PATH);
                return;
            }

            if (fallback && !router.query.redirectTo) {
                void redirectTo(`${fallback}?redirectTo=${router.asPath}`);
                return;
            }
        }
    }, [fallback, router, authorized, isTempUser, redirectTo, isFullUser, credits]);

    if (!authorized) return null;

    return <>{children}</>;
}
