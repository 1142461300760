import Image, { ImageProps } from 'next/image';
import { FC } from 'react';

export const NextImage: FC<ImageProps> = ({ ...props }) => {
    return (
        <div className="next-image-wrapper">
            <Image width={40} height={40} {...props} />
        </div>
    );
};
