import { IncomingMessage } from 'http';

export class NoSiteAliasError extends Error {
    public readonly host: string;

    constructor(req: IncomingMessage) {
        const headers = JSON.stringify(req.headers);

        super(`no site alias available host: '${req.headers.host}', url: '${req.url}', headers: '${headers}'`);

        this.host = req.headers.host || '';
    }
}

export class UnsupportedLoanFormatError extends Error {
    public readonly loanFormat: string;

    constructor(loanFormat: string) {
        super(`unsupported loan format ${loanFormat}`);

        this.loanFormat = loanFormat;
    }
}
