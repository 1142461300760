import Link from 'next/link';
import { FC } from 'react';

import { SIZES_LIBRARY_LOGO } from '../../../../next-image-sizes';
import { useLibraryContext } from '../../../context/LibraryContext';
import { HOME_PATH } from '../../../utils/routes/paths';
import { NextImage } from '../../next/NextImage';

export const HeaderLogo: FC = () => {
    const { theme, homeLink, frontendSiteName } = useLibraryContext();
    const logoUrl = theme?.logoUrl;

    if (!logoUrl) return null;

    const logoImage = (
        <NextImage
            src={logoUrl}
            title="library logo"
            alt={frontendSiteName ?? ''}
            aria-hidden={!frontendSiteName}
            sizes={SIZES_LIBRARY_LOGO}
            className="logo-img"
        />
    );

    return logoUrl ? (
        <div className="logo">
            {homeLink ? (
                // `homeLink` could be (and probably is) an external link
                <a href={homeLink} rel="noopener noreferrer">
                    {logoImage}
                </a>
            ) : (
                <Link href={HOME_PATH} legacyBehavior>
                    <a>{logoImage}</a>
                </Link>
            )}
        </div>
    ) : null;
};
