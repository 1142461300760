import { ComponentProps, FC, ReactNode } from 'react';

import { Wrapper } from './components/Wrapper';
import { useTooltipFloater } from './hooks/useTooltipFloater';

type TooltipButtonBaseProps = {
    readonly tooltip: ReactNode;
    readonly onClick?: () => void;
    readonly hoverSafePolygon?: boolean;
};

type ButtonWithTooltipProps = { readonly tagName: 'button' } & TooltipButtonBaseProps & ComponentProps<'button'>;
type DivWithTooltipProps = { readonly tagName: 'div' } & TooltipButtonBaseProps & ComponentProps<'div'>;
type LiWithTooltipProps = { readonly tagName: 'li' } & TooltipButtonBaseProps & ComponentProps<'div'>;

type ElementWithTooltipProps = ButtonWithTooltipProps | DivWithTooltipProps | LiWithTooltipProps;

const ElementWithTooltip: FC<ElementWithTooltipProps> = ({
    tooltip,
    tagName: Tag = 'button',
    onClick,
    hoverSafePolygon,
    children,
    ...props
}) => {
    const { open, setOpen, arrowRef, reference, floatingProps, getReferenceProps, getFloatingProps } =
        useTooltipFloater({ hoverSafePolygon });

    const handleClick = () => {
        onClick?.();
        setOpen(false);
    };

    return (
        <>
            <Tag ref={reference} {...getReferenceProps(props)} type="button" onClick={handleClick}>
                {children}
            </Tag>
            <Wrapper open={open} arrowRef={arrowRef} floatingProps={floatingProps} getFloatingProps={getFloatingProps}>
                {tooltip}
            </Wrapper>
        </>
    );
};

export const ButtonWithTooltip = (props: Omit<ButtonWithTooltipProps, 'tagName'>) => {
    return <ElementWithTooltip tagName="button" {...props} />;
};

export const DivWithTooltip = (props: Omit<DivWithTooltipProps, 'tagName'>) => {
    return <ElementWithTooltip tagName="div" {...props} />;
};

export const LiWithTooltip = (props: Omit<DivWithTooltipProps, 'tagName'>) => {
    return <ElementWithTooltip tagName="li" {...props} />;
};
