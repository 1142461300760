import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiEzProxyLoginOptions, ezProxyLogin } from './apiEzProxyLogin';
import { ApiSamlLoginOptions, samlLogin } from './apiSamlLogin';
import { usePatronInfoUpdate } from '../../context/AuthenticationContext';
import { removeCredits } from '../../context/CreditsContext';
import { removeLoanStatus } from '../../context/LoanStatusContext';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseMutationResult } from '../../setup/react-query/queryTypes';
import { ApiPatronInfo } from '../apiFetchPatronInfo';
import { LoginType } from '../apiLibraryContext';

type ExternalLoginOptions = {
    readonly externalLoginType: LoginType | undefined;
};

export const useExternalLoginMutation = ({
    externalLoginType
}: ExternalLoginOptions): UseMutationResult<ApiPatronInfo, ApiSamlLoginOptions | ApiEzProxyLoginOptions> => {
    let fetchFunc: (client: ApiClient, args: ApiSamlLoginOptions | ApiEzProxyLoginOptions) => Promise<ApiPatronInfo>;
    switch (externalLoginType) {
        case 'EZPROXY':
            fetchFunc = ezProxyLogin;
            break;
        case 'SAML':
            fetchFunc = samlLogin;
            break;
        default:
            throw new Error(`Unsupported external login type ${externalLoginType}`);
    }

    const client = useQueryClient();
    const updatePatronInfo = usePatronInfoUpdate();

    return useMutation({
        mutationFn: (options: ApiSamlLoginOptions | ApiEzProxyLoginOptions) => fetchFunc(browserClient, options),
        onSuccess: patronInfo => {
            updatePatronInfo(patronInfo);
            removeCredits(client);
            removeLoanStatus(client);
        }
    });
};
