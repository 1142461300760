import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConsentCookie } from './ConsentCookie';
import { useClickOutside } from '../../../../hooks/events/useClickOutside';
import { customize } from '../../../../utils/localizations/customize';
import { ModalDialog } from '../../../ModalDialog';

type Props = {
    /**
     * Called when the modal is closed.
     */
    onClose(): void;
};

export const CookiePreferencesModal: FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const { consents, set } = useConsentCookie();

    const [analyticsFlag, setAnalyticsFlag] = useState(consents.analytics);

    const modalRef = useRef(null);

    useClickOutside(modalRef, onClose);

    const handleAnalyticsFlagChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAnalyticsFlag(event.currentTarget.checked);
    };

    const handleSave = () => {
        set(analyticsFlag);
        onClose();
    };

    return (
        <ModalDialog
            isOpen={true}
            title={t('cookie_consent.modal.title')}
            onClose={onClose}
            buttons={
                <>
                    <button onClick={onClose} className="button-neutral">
                        {t('cookie_consent.modal.buttons.cancel')}
                    </button>
                    <button onClick={handleSave} className="button-positive">
                        {t('cookie_consent.modal.buttons.save')}
                    </button>
                </>
            }
        >
            <p>{customize(t('cookie_consent.modal.text'))}</p>
            <p>
                <input type="checkbox" id="analytics" checked={analyticsFlag} onChange={handleAnalyticsFlagChange} />
                <label htmlFor="analytics">{t('cookie_consent.modal.consent.analytics')}</label>
            </p>
        </ModalDialog>
    );
};
