import { QueryClient, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { loanProduct } from './apiLoanProduct';
import { invalidateActiveLoansAndReserves } from './useActiveLoansAndReserves';
import { invalidateLoanDetails } from './useLoanDetails';
import { invalidateCredits } from '../../context/CreditsContext';
import { invalidateLoanStatus } from '../../context/LoanStatusContext';
import { ApiLoanResponse, LoanErrorResponse } from '../../domain/loan';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import LoggingUtils from '../../utils/logging/LoggingUtils';

export const invalidateOnBorrowSuccess = async (queryClient: QueryClient) =>
    Promise.all([
        // displayed in the header
        invalidateCredits(queryClient),
        // not prefetched in SSR, needed in the borrow/reserve-modals
        invalidateLoanStatus(queryClient),
        invalidateActiveLoansAndReserves(queryClient),
        // borrowing is possible directly(!) on the loan-details-page
        invalidateLoanDetails(queryClient)
    ]);

export const useBorrowMutation = (): UseMutationResult<
    ApiLoanResponse,
    AxiosError | LoanErrorResponse,
    { productId: string },
    unknown
> => {
    const queryClient = useQueryClient();
    const { siteId, language } = useSiteParams();

    return useMutation({
        mutationFn: ({ productId }) => loanProduct(browserClient, { productId, siteId, language }),
        onSuccess: () => invalidateOnBorrowSuccess(queryClient),
        onError: (error, productId) => {
            LoggingUtils.logErrorWithPayload(`Cannot borrow product`, { error, productId });
        }
    });
};
