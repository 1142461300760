import { FC } from 'react';

import { Icon } from './Icon';

type Props = {
    readonly message: string;
    readonly id?: string;
    readonly className?: string;
};

export const ErrorMessage: FC<Props> = ({ message, id, className }) => {
    return (
        <div className="component error-message">
            <p role="alert" id={id} className={className}>
                <Icon icon="error-circle" />
                {message}
            </p>
        </div>
    );
};
