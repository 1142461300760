import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CookiePreferencesModal } from './cookie-consent/CookiePreferencesModal';
import { LanguageSelector } from './LanguageSelector';
import { LanguageSelectionDisplay } from '../../../api/apiLibraryContext';
import { useDarkOverlayVisible } from '../../../context/DarkOverlayContext';
import { useSiteParams } from '../../../hooks/getters/useSiteParams';
import { usePreventDefault } from '../../../hooks/utils/usePreventDefault';
import { useToggle } from '../../../hooks/utils/useToggle';
import { AppStoreLinks } from '../../../page-components/home/my-loans/access/AppStoreLinks';
import {
    ACCESSIBILITY_ROUTE_PATH,
    COPYRIGHT_ROUTE_PATH,
    HELP_ROUTE_PATH,
    PRIVACY_ROUTE_PATH,
    SITE_MAP_ROUTE_PATH,
    TOS_ROUTE_PATH
} from '../../../utils/routes/paths';

export const Footer: FC = () => {
    const { t } = useTranslation();
    const { languageSelectionDisplay } = useSiteParams();
    const [visible, toggleVisible] = useToggle();
    const darkOverlayVisible = useDarkOverlayVisible();

    const showLanguageSelector = languageSelectionDisplay === LanguageSelectionDisplay.STANDARD;

    return (
        <>
            <footer inert={darkOverlayVisible ? '' : null}>
                <div className="top">
                    <AppStoreLinks color="Transparent" />
                </div>
                <div className="bottom">
                    <div className="left">
                        <img alt="Bolinda BorrowBox" src="/images/borrowBoxLogo.svg" loading="lazy" />
                        Copyright © Bolinda Digital Pty Ltd 2010-{new Date().getFullYear()}. All rights reserved.
                    </div>
                    <div className="right">
                        <ul>
                            <li>
                                <a href="#" onClick={usePreventDefault(toggleVisible)}>
                                    {t('footer.links.cookie_preferences')}
                                </a>
                            </li>
                            <li>
                                <FooterLink to={PRIVACY_ROUTE_PATH} text={t('footer.links.privacy_policy')} />
                            </li>
                            <li>
                                <FooterLink to={TOS_ROUTE_PATH} text={t('footer.links.terms_and_conditions')} />
                            </li>
                            <li>
                                <FooterLink to={COPYRIGHT_ROUTE_PATH} text={t('footer.links.copyright_matters')} />
                            </li>
                            <li>
                                <FooterLink to={ACCESSIBILITY_ROUTE_PATH} text={t('footer.links.accessibility')} />
                            </li>
                            <li>
                                <FooterLink to={SITE_MAP_ROUTE_PATH} text={t('footer.links.site_map')} />
                            </li>
                            <li>
                                <FooterLink to={HELP_ROUTE_PATH} text={t('footer.links.help')} />
                            </li>
                            {showLanguageSelector ? (
                                <li>
                                    <LanguageSelector />
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
            </footer>
            {visible ? <CookiePreferencesModal onClose={toggleVisible} /> : null}
        </>
    );
};

interface FooterLinkProps {
    to: string;
    text: string;
}

function FooterLink({ to, text }: FooterLinkProps) {
    return <Link href={to}>{text}</Link>;
}
