/**
 * Creates a debounced function that delays invoking `task` until `delay` has elapsed since the last call.
 *
 * @param task Function that returns a promise.
 * @param delay The number of milliseconds by which `task` should be debounced.
 */
export const debouncePromise = <Task extends (...args: Array<any>) => Promise<any>>(task: Task, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    return ((...args: Parameters<Task>) => {
        clearTimeout(timeoutId);

        return new Promise<Awaited<ReturnType<Task>>>((resolve, reject) => {
            timeoutId = setTimeout(async () => {
                try {
                    const result = await task(...args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }, delay);
        });
    }) as Task;
};
