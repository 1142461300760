import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import {
    ApiContextNavigation,
    ApiContextNavigationAgeCategory,
    ApiContextNavigationLoanFormats
} from '../../api/apiLibraryContext';
import { ProductsPageType } from '../../components/layout/header/Navigation';
import { AgeCategoryType, parseAgeCategoryType } from '../../domain/ageCategory';
import { DEFAULT_COLLECTION_SLUG } from '../constants';
import { LoanFormatType, parseLoanFormatType } from '../domain/loanFormat';
import { isPresent } from '../objectChecks';

export interface INavigationState {
    collections: INavCollectionsState;
}

interface INavCollectionsState {
    [DEFAULT_COLLECTION_SLUG]: INavCollectionState;
}

export interface INavCollectionState {
    name: string;
    loanFormats: {
        [L in LoanFormatType]?: INavFormatState<L>;
    };
}

export interface INavFormatState<L extends LoanFormatType> {
    loanFormat: L;
    ageCategories: {
        [A in AgeCategoryType]?: INavAgeCategoryState<A>;
    };
}

export interface INavAgeCategoryState<A extends AgeCategoryType> {
    ageCategory: A;
    [ProductsPageType.Featured]: { visible: boolean };
    [ProductsPageType.Explore]: { visible: boolean };
    [ProductsPageType.Genres]: { visible: boolean };
    [ProductsPageType.All]: { visible: boolean };
    [ProductsPageType.AllEMagazines]: { visible: boolean; productGroupId?: number };
    [ProductsPageType.AllENews]: { visible: boolean; productGroupId?: number };
}

function convertAgeCategory(
    navigationAgeCategory: ApiContextNavigationAgeCategory
): INavAgeCategoryState<AgeCategoryType> {
    const productGroupIdMagazine = navigationAgeCategory.allEMagazinePage?.productGroupId;
    const productGroupIdNews = navigationAgeCategory.allENewsPage?.productGroupId;

    return {
        ageCategory: parseAgeCategoryType(navigationAgeCategory.name),
        [ProductsPageType.Featured]: {
            visible: (navigationAgeCategory.featuredPage?.slots?.length ?? 0) > 0
        },
        [ProductsPageType.Explore]: {
            visible: (navigationAgeCategory.categoriesPage?.slots?.length ?? 0) > 0
        },
        [ProductsPageType.Genres]: {
            visible: (navigationAgeCategory.genresPage?.genres?.length ?? 0) > 0
        },
        [ProductsPageType.All]: {
            visible: true
        },
        [ProductsPageType.AllEMagazines]: {
            visible: isPresent(productGroupIdMagazine),
            productGroupId: productGroupIdMagazine
        },
        [ProductsPageType.AllENews]: {
            visible: isPresent(productGroupIdNews),
            productGroupId: productGroupIdNews
        }
    };
}

function convertLoanFormat(navigationLoanFormats: ApiContextNavigationLoanFormats): INavFormatState<LoanFormatType> {
    const loanFormat = parseLoanFormatType(navigationLoanFormats.name);

    return {
        loanFormat,
        ageCategories: Object.fromEntries(
            navigationLoanFormats.ageCategories.map(navItem => [
                parseAgeCategoryType(navItem.name),
                convertAgeCategory(navItem)
            ])
        )
    };
}

export function parseNavigationState(navigation: ApiContextNavigation): INavigationState {
    const loanFormatsObject = keyBy(navigation.defaultCollection.loanFormats, it => parseLoanFormatType(it.name));

    return {
        collections: {
            [DEFAULT_COLLECTION_SLUG]: {
                name: 'Home',
                loanFormats: mapValues(loanFormatsObject, value => convertLoanFormat(value))
            }
        }
    };
}
