import isEqual from 'lodash/isEqual';
import { DependencyList, useRef } from 'react';

export function useDeepMemo<T>(memoFn: () => T, deps: DependencyList): T {
    const ref = useRef<{ deps: DependencyList; value: T }>();

    if (!ref.current || !areDepsEqual(ref.current.deps, deps)) {
        const newValue = memoFn();
        if (!ref.current || !isEqual(newValue, ref.current.value)) {
            ref.current = {
                deps,
                value: newValue
            };
        }
    }

    return ref.current.value;
}

function areDepsEqual(prevDeps: DependencyList, nextDeps: DependencyList): boolean {
    if (prevDeps.length !== nextDeps.length) {
        throw new Error('the length of a dependencies array may not change between renders');
    }

    for (let i = 0; i < prevDeps.length; ++i) {
        if (prevDeps[i] !== nextDeps[i]) {
            return false;
        }
    }

    return true;
}
