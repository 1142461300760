import { Performer } from './performer';
import { LoanFormatType } from '../utils/domain/loanFormat';

export type FormatSpecificEbook = {
    readonly loanFormat: LoanFormatType.ebooks;
    readonly fileSize: number; // FIXME: not specific to format and not used in ShortProduct -> move to ProductDetails
    readonly numberOfPages: number;
    readonly illustrated: boolean;
};

export type FormatSpecificEAudiobook = {
    readonly loanFormat: LoanFormatType.eaudiobooks;
    readonly fileSize: number; // FIXME: not specific to format and not used in ShortProduct -> move to ProductDetails
    readonly totalDuration: number;
    readonly totalTrackNumber: number;
};

export type FormatSpecificEMagazineIssue = {
    readonly loanFormat: LoanFormatType.eMagazineIssues;
    readonly fileSize: number; // FIXME: not specific to format and not used in ShortProduct -> move to ProductDetails
};

type FormatSpecificEMagazine = {
    readonly loanFormat: LoanFormatType.eMagazines;
    readonly fileSize: number; // FIXME: not specific to format and not used in ShortProduct -> move to ProductDetails
    readonly releaseFrequency: string;
    readonly releaseCountPerCalendarYear: number;
};

export type FormatSpecific =
    | FormatSpecificEbook
    | FormatSpecificEAudiobook
    | FormatSpecificEMagazineIssue
    | FormatSpecificEMagazine;

export type RootProduct = {
    readonly title: string;
    readonly formatSpecifics: FormatSpecific;
};

export enum DownloadProtection {
    unencrypted = 'unencrypted',
    encryptedAcs = 'encryptedAcs'
}

export enum AvailabilityStatus {
    Available = 'AVAILABLE',
    OnLoan = 'ON_LOAN',
    New = 'NEW',
    Unavailable = 'UNAVAILABLE'
}

export type Availability = {
    readonly nextAvailableDate?: number; // FIXME: only relevant if status is 'onLoan' or 'New'
    readonly status: AvailabilityStatus;
    readonly latestAvailableIssueId?: string; // FIXME: only relevant for eMagazines -> move to FormatSpecifics?
};

export type Restrictions = ReadonlyArray<'geo'>;

export type ShortProduct = {
    readonly authors?: ReadonlyArray<Performer>; // FIXME: only relevant for eBooks/eAudiobooks -> to FormatSpecifics?
    readonly availability: Availability;
    readonly coverUrl: string;
    readonly currentPeriodEnd?: number; // FIXME: only relevant for ePress -> move to FormatSpecifics?
    readonly downloadProtection?: DownloadProtection; // FIXME: only used by RenewLoanModal. Can we do without?
    readonly formatSpecifics: FormatSpecific;
    readonly hasPreview?: boolean;
    readonly previewUrl?: string; // FIXME: only used for eAudiobooks -> could be moved to FormatSpecificEAudiobook
    readonly productId: string;
    readonly restrictions?: Restrictions;
    readonly rootProduct?: RootProduct; // FIXME: only relevant for eMagazineIssue -> move to FormatSpecifics?
    readonly rootProductId?: string; // FIXME: move to rootProduct?
    readonly seriesId?: string; // FIXME: only relevant for eBooks/eAudiobooks -> move to FormatSpecifics?
    readonly seriesName?: string; // FIXME: only relevant for eBooks/eAudiobooks -> move to FormatSpecifics?
    readonly seriesNumber?: number; // FIXME: only relevant for eBooks/eAudiobooks -> move to FormatSpecifics?
    readonly title: string;
};

/**
 * Returns true if the currentPeriodEnd date is today or in the future. If `product.currentPeriodEnd` is
 * undefined, this method returns `true` since only current products may have an undefined period end.
 */
export function isProductCurrent(product: { currentPeriodEnd?: ShortProduct['currentPeriodEnd'] }): boolean {
    if (!product.currentPeriodEnd) {
        return true;
    }

    const today = new Date(Date.now()).setHours(0, 0, 0, 0);

    return today <= product.currentPeriodEnd;
}

/**
 * Returns the full title for the given product.
 *
 * For eBooks and eAudiobooks this is just the title.
 *
 * eMagazineIssues only have the current edition in their title, e.g. "August 2020". We need to combine this with the
 * eMagazine title, which is available as the rootProduct. The combined title is then for example "BBC Good Food: August
 * 2020".
 */
export const getFullTitle = ({
    title,
    rootProduct
}: Pick<ShortProduct, 'title'> & { rootProduct?: Pick<RootProduct, 'title'> }) => {
    return rootProduct ? `${rootProduct.title}: ${title}` : title;
};
