import { ApiClient } from '../../setup/axios';
import { ApiPatronInfo } from '../apiFetchPatronInfo';
import { SiteParams } from '../index';

export type ApiEzProxyLoginOptions = SiteParams & {
    readonly ssoToken: string;
    readonly timestamp: string;
};

export function ezProxyLogin(
    client: ApiClient,
    { ssoToken, timestamp, ...params }: ApiEzProxyLoginOptions
): Promise<ApiPatronInfo> {
    return client
        .post<ApiPatronInfo>(
            '/api/v1/auth/sso/ezproxy',
            {
                ssoToken,
                timestamp
            },
            params
        )
        .then(result => result.data);
}
