import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

type ApiGetSubscriptionOptions = {
    readonly rootProductId: string;
};

export type ApiSubscription = {
    readonly automaticallyBorrow: boolean;
    readonly notifications: boolean;
};

export function getSubscription(
    client: ApiClient,
    params: SiteParams,
    { rootProductId }: ApiGetSubscriptionOptions
): Promise<ApiSubscription> {
    return client.get<ApiSubscription>(`/api/v1/subscriptions/${rootProductId}`, params).then(res => res.data);
}
