import axios from 'axios';

import { parseAgeCategoryType } from '../../domain/ageCategory';
import { ProductDetails } from '../../domain/productDetails';
import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

type ProductDetailsParams = SiteParams & {
    readonly productId: string;
};

export function fetchProductDetails(
    client: ApiClient,
    { productId, ...params }: ProductDetailsParams
): Promise<ProductDetails> {
    // eslint-disable-next-line import/no-named-as-default-member
    const source = axios.CancelToken.source();

    const promise = client
        .get<ProductDetails>(`/api/v2/product/${productId}`, {
            ...params,
            cancelToken: source.token
        })
        .then(result => ({
            ...result.data,
            ageCategory: result.data.ageCategory && parseAgeCategoryType(result.data.ageCategory)
        }));

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
    };

    return promise;
}
