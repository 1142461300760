import Link from 'next/link';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderAccount } from './HeaderAccount';
import { HeaderLogo } from './HeaderLogo';
import { HeaderSearch } from './HeaderSearch';
import { IProductsPageRef, LoanFormatSlugs, productsPagePath, ProductsPageType } from './Navigation';
import { useLibraryContext } from '../../../context/LibraryContext';
import { AgeCategoryType } from '../../../domain/ageCategory';
import { useIsPartiallyCurrentRoute } from '../../../hooks/utils/useIsPartiallyCurrentRoute';
import { useMounted } from '../../../hooks/utils/useMounted';
import { usePreventDefault } from '../../../hooks/utils/usePreventDefault';
import { loanFormatToGildClass, LoanFormatType } from '../../../utils/domain/loanFormat';
import { i18nLoanFormatMap } from '../../../utils/localizations/i18nextMaps';
import { selectAvailableLoanFormats } from '../../../utils/navigation/navSelectors';
import { ADVANCED_SEARCH_PATH, HOME_PATH, SEARCH_PATH } from '../../../utils/routes/paths';
import { usePageRef } from '../../ProductsPageLink';

type HeaderNavigationFirstLineProps = {
    readonly loanFormat: LoanFormatType | undefined;
    readonly ageCategory: AgeCategoryType | undefined;
    readonly pageType: ProductsPageType | undefined;
};

export const HeaderNavigationFirstLine: FC<HeaderNavigationFirstLineProps> = ({
    loanFormat,
    ageCategory,
    pageType
}) => {
    const [showSearch, setShowSearch] = useState(false);
    const isMounted = useMounted();

    const handleHideSearch = useCallback(() => {
        if (isMounted()) {
            setShowSearch(false);
        }
    }, [isMounted]);
    const handleShowSearch = useCallback(() => {
        if (isMounted()) {
            setShowSearch(true);
        }
    }, [isMounted]);

    return (
        <div className="top-level">
            {showSearch ? <HeaderSearch onClose={handleHideSearch} /> : null}
            <RowOne
                loanFormat={loanFormat}
                ageCategory={ageCategory}
                pageType={pageType}
                onShowSearch={handleShowSearch}
            />
            <RowTwo loanFormat={loanFormat} ageCategory={ageCategory} pageType={pageType} />
        </div>
    );
};

export const useLoanFormatLinks = (): Array<{ loanFormat: LoanFormatType; label: string }> => {
    const libraryContext = useLibraryContext();
    const availableLoanFormats = selectAvailableLoanFormats(libraryContext);
    return useSelectLoanFormatsWithLabels(availableLoanFormats, true);
};

export const useSelectLoanFormatsWithLabels = (loanFormats: Iterable<LoanFormatType>, short?: boolean) => {
    const { t } = useTranslation();

    return Array.from(loanFormats).map((loanFormat: LoanFormatType) => ({
        loanFormat,
        label: t(`defaults.plural.${i18nLoanFormatMap[loanFormat]}`, { context: short ? 'short' : '' })
    }));
};

type RowOneProps = HeaderNavigationFirstLineProps & {
    readonly onShowSearch: () => void;
};

const RowOne: FC<RowOneProps> = ({ onShowSearch, loanFormat, ageCategory, pageType }) => {
    return (
        <div className="row-one">
            <HeaderLogo />
            <IconNav
                loanFormat={loanFormat}
                ageCategory={ageCategory}
                pageType={pageType}
                onShowSearch={onShowSearch}
            />
            <HeaderAccount onSearchClick={onShowSearch} />
        </div>
    );
};

type RowTwoProps = HeaderNavigationFirstLineProps;

const RowTwo: FC<RowTwoProps> = ({ loanFormat, ageCategory, pageType }) => {
    return (
        <div className="row-two">
            <IconNav loanFormat={loanFormat} ageCategory={ageCategory} pageType={pageType} />
        </div>
    );
};

type IconNavProps = HeaderNavigationFirstLineProps & {
    readonly onShowSearch?: () => void;
};

const IconNav: FC<IconNavProps> = ({ onShowSearch, ageCategory }) => {
    const { t } = useTranslation();

    const navItems = useLoanFormatLinks();

    const { isCurrent: isRoot } = useIsPartiallyCurrentRoute(HOME_PATH);
    const { isPartiallyCurrent: isPartiallyHome } = useIsPartiallyCurrentRoute('/home');
    const { isPartiallyCurrent: isPartiallySearch } = useIsPartiallyCurrentRoute(SEARCH_PATH);
    const { isPartiallyCurrent: isPartiallyAdvancedSearch } = useIsPartiallyCurrentRoute(ADVANCED_SEARCH_PATH);

    const onSearch = usePreventDefault(() => onShowSearch?.());

    return (
        <div className="icon-nav">
            <nav aria-label={t('header.nav.first_line_label')}>
                <ul className="section">
                    <li className={isRoot || isPartiallyHome ? 'active' : ''}>
                        <Link href={HOME_PATH} legacyBehavior>
                            <a className="libcolor-text-active">
                                <div
                                    className={`icon home-${isRoot || isPartiallyHome ? 'filled' : 'outline'}`}
                                    aria-hidden={true}
                                />
                                <div className="text">{t('header.nav.home')}</div>
                            </a>
                        </Link>
                    </li>
                    {navItems.map(({ loanFormat: collectionLoanFormat, label }) => (
                        <NavLink
                            key={label}
                            loanFormat={collectionLoanFormat}
                            ageCategory={ageCategory}
                            label={label}
                        />
                    ))}
                </ul>
                {onShowSearch ? (
                    <ul className="section">
                        <li className={isPartiallySearch || isPartiallyAdvancedSearch ? 'active' : ''}>
                            <a className="libcolor-text-active" href="#" onClick={onSearch}>
                                <div className="icon search-outline" aria-hidden={true} />
                                <div className="text">{t('defaults.search.button_description')}</div>
                            </a>
                        </li>
                    </ul>
                ) : null}
            </nav>
        </div>
    );
};

type NavLinkProps = IProductsPageRef;

const NavLink: FC<NavLinkProps> = ({ loanFormat, ageCategory, pageType, label }) => {
    const pageRef = usePageRef(loanFormat, ageCategory, pageType);
    const path = productsPagePath(pageRef);
    const { isPartiallyCurrent } = useIsPartiallyCurrentRoute(loanFormat ? `/${LoanFormatSlugs[loanFormat]}` : path);

    const icon = loanFormat ? loanFormatToGildClass(loanFormat) : 'other-nav';
    const status = isPartiallyCurrent ? 'filled' : 'outline';

    return (
        <li className={isPartiallyCurrent ? 'active' : ''}>
            <Link href={path} legacyBehavior>
                <a className="libcolor-text-active">
                    <div className={`icon ${icon}-${status}`} aria-hidden={true} />
                    <div className="text">{label}</div>
                </a>
            </Link>
        </li>
    );
};
