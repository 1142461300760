import classNames from 'classnames';
import { Dispatch, FC, forwardRef, MouseEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useSiteParams } from '../../../hooks/getters/useSiteParams';
import { getSupportedLanguages, setLocaleCookie, useLocale } from '../../../utils/localizations/localization';
import { ButtonWithPopover } from '../../floater/popover/ElementWithPopover';
import { Icon } from '../../Icon';

type LanguageSelectorProps = {
    readonly setPopoverOpen?: Dispatch<SetStateAction<boolean>>;
};

export const LanguageSelector: FC<LanguageSelectorProps> = ({ setPopoverOpen }) => {
    const { t } = useTranslation();
    const { siteId } = useSiteParams();

    const locale = useLocale();

    const handleLanguageClick = (event: MouseEvent<HTMLButtonElement>, newLocale: string) => {
        event?.preventDefault();

        if (newLocale !== locale) {
            setLocaleCookie(newLocale);
            window.location.reload();
        }
    };

    const supportedLanguages = getSupportedLanguages(siteId);
    const supportedLanguageKeys = Object.keys(supportedLanguages);
    const languageLabel = supportedLanguages[locale as keyof typeof supportedLanguages];

    const popover = (
        <div className="component popover-list">
            <ul>
                {supportedLanguageKeys.map(locale => (
                    <LanguageSelectButton
                        key={locale}
                        locale={locale}
                        supportedLanguages={supportedLanguages}
                        handleLanguageClick={handleLanguageClick}
                    />
                ))}
            </ul>
        </div>
    );

    return (
        <ButtonWithPopover
            popover={popover}
            className="button-text expandable-button"
            aria-label={t('footer.links.language.select')}
            setPopoverOpen={setPopoverOpen}
        >
            <Icon icon="globe-grid" />
            <span aria-label={t('footer.links.language.current', { language: languageLabel })}>{languageLabel}</span>
            <span aria-hidden className="chevron" />
        </ButtonWithPopover>
    );
};

type LanguageSelectButtonProps = {
    readonly locale: string;
    readonly supportedLanguages: Record<string, string>;
    readonly handleLanguageClick: (event: MouseEvent<HTMLButtonElement>, locale: string) => void;
};

const LanguageSelectButton = forwardRef<HTMLButtonElement, LanguageSelectButtonProps>(
    ({ locale, supportedLanguages, handleLanguageClick, ...props }, ref) => {
        const currentLocale = useLocale();

        const langOptionLabel = supportedLanguages[locale as keyof typeof supportedLanguages] || locale;
        const isActive = locale === currentLocale;

        return (
            <li className={classNames({ active: isActive })}>
                <button
                    {...props}
                    ref={ref}
                    onClick={event => handleLanguageClick(event, locale)}
                    className={classNames('libcolor-text-hover', { 'libcolor-text': isActive })}
                >
                    <span>{langOptionLabel}</span>
                    {isActive ? <Icon icon="check" /> : null}
                </button>
            </li>
        );
    }
);
