import { handleLoanError, handleLoanSuccess } from './handleLoanSuccessAndError';
import { ApiLoanResponse, Loan } from '../../domain/loan';
import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

type LoanProductParams = SiteParams & {
    readonly productId: string;
};

export function loanProduct(client: ApiClient, { productId, ...params }: LoanProductParams): Promise<ApiLoanResponse> {
    return client
        .put<Loan>(`/api/v1/loans/checkout?productId=${productId}&loanType=loan`, undefined, params)
        .then(handleLoanSuccess)
        .catch(handleLoanError);
}
