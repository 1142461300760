import classNames from 'classnames';
import Link from 'next/link';
import { ComponentProps, forwardRef, useMemo } from 'react';

import { useIsPartiallyCurrentRoute } from '../../../hooks/utils/useIsPartiallyCurrentRoute';

export type INavLinkProps = ComponentProps<'a'> & { href: string };

export const NavLink = forwardRef<HTMLAnchorElement, INavLinkProps>(
    ({ className, href, children, ...props }: INavLinkProps, ref) => {
        const { isCurrent, isPartiallyCurrent } = useIsPartiallyCurrentRoute(href);

        const styledClassName = useMemo(
            () => classNames(className, { active: isCurrent || isPartiallyCurrent, disabled: isCurrent }),
            [className, isCurrent, isPartiallyCurrent]
        );

        return (
            <Link href={href} legacyBehavior>
                <a ref={ref} {...props} className={styledClassName}>
                    {children}
                </a>
            </Link>
        );
    }
);
