/**
 * Checks if given parameter is `null` or `undefined`
 */
export const isNone = (obj: unknown): obj is null | undefined => {
    return obj === null || obj === undefined;
};

/**
 * Checks if given parameter is "blank".
 *
 * An object is "blank" if it is one of the following:
 * - `null` or `undefined`
 * - an empty string
 * - a whitespace string
 */
export const isBlank = (obj: string | null | undefined): boolean => {
    return !obj || obj.trim().length === 0;
};

/**
 * Checks if given parameter is "present".
 *
 * An object is "present" if it is one of the following:
 * - a non-empty string
 * - a non-whitespace string
 * - a boolean
 * - a number
 * - an array with values
 * - an object with keys
 * - a non-empty HTML Element Collection
 *
 * Be aware: The type guard is not correct for empty or whitespace strings. It will argue in these cases that `obj` is
 *           not of type string. Semantically it will work as expected for i.e.:
 *           `isPresent(username) alert('Hi ' + username) else alert('username must not be empty')`
 */
export const isPresent = <T>(obj: T | null | undefined): obj is T => {
    if (typeof obj === 'string') return !isBlank(obj);
    return !isEmpty(obj);
};

/**
 * Checks if given parameter is "empty".
 *
 * An object is "empty" if it is one of the following:
 * - `null` or `undefined`
 * - an empty string
 * - an empty array or object
 * - an empty HTML Element Collection
 */
export const isEmpty = (obj: unknown): boolean => {
    if (isNone(obj)) return true;
    if (typeof obj === 'boolean') return false;
    if (typeof obj === 'string') return obj.length === 0;
    if (obj instanceof Function) return false;
    if (obj instanceof Array) return obj.length === 0;
    if (obj instanceof Object) return Object.keys(obj).length === 0;

    // `HTMLCollection` passes the handlings above and must be handled separately
    if (obj && typeof obj === 'object' && 'length' in obj) {
        try {
            return (obj as HTMLCollection).length === 0;
        } catch (_) {}
    }

    return false;
};
