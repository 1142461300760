import { IncomingMessage } from 'http';
import { GetServerSidePropsContext, NextPageContext } from 'next';

import { NoSiteAliasError } from './errors';
import { LongLifetimeCache } from './longLifetimeCache';
import { ApiContextLibrary, fetchLibraryContext, LanguageSelectionDisplay } from '../../api/apiLibraryContext';
import { ApiClient } from '../../setup/axios';
import { parseQuery } from '../routes/queryString';
import { extractQueryString } from '../routes/routing';

// The site alias may be set statically for branch deployments or site specific deployments (which do not yet exist)
const siteAliasOverwrite = process.env['SITE_ALIAS'];

const CACHE_MAX_ENTRIES = 1000;

const CACHE_ENTRY_MAX_AGE_MS = 5 * 60 * 1000;

const siteContextCache = new LongLifetimeCache<ApiContextLibrary>(CACHE_MAX_ENTRIES, CACHE_ENTRY_MAX_AGE_MS);

const PREVIEW_READER_SITE_ALIAS = 'webpreview';

const PREVIEW_READER_CONTEXT: ApiContextLibrary = {
    siteId: '0',
    siteName: 'BorrowBox Preview',
    frontendSiteName: 'BorrowBox Preview',
    friendlyLinkName: PREVIEW_READER_SITE_ALIAS,
    timezone: 'Australia/Sydney',
    languageSelectionDisplay: LanguageSelectionDisplay.STANDARD,
    userManagementType: 'INTERNAL',
    ssoServerUrl: undefined,
    supportsInternalPasswordReset: false,
    loanPeriods: {},
    logoUrl: '',
    design: { pp_bar_color: '#7fc31c' },
    homeLink: '',
    networkSite: false
};

export const getSiteContext = async (
    client: ApiClient,
    ctx: NextPageContext | GetServerSidePropsContext
): Promise<ApiContextLibrary> => {
    const req = ctx.req!;
    const siteAlias = getSiteAliasFromRequest(req);

    if (!siteAlias) throw new NoSiteAliasError(req);

    // the "faked" Preview Reader Context is used for the Preview Reader Site Alias only at the `/preview` route
    if (siteAlias === PREVIEW_READER_SITE_ALIAS && req.url?.startsWith('/preview/')) return PREVIEW_READER_CONTEXT;

    const cachedSiteContext = siteContextCache.get(siteAlias);

    if (cachedSiteContext && siteContextCache.isFresh(siteAlias)) return cachedSiteContext;

    const newSiteContext = await fetchLibraryContext(client, siteAlias);

    siteContextCache.update(siteAlias, newSiteContext);

    return newSiteContext;
};

const getSiteAliasFromRequest = (req: IncomingMessage): string | undefined => {
    // If a fixed site alias is present it will be used unconditionally (even if there is a subdomain and/or query
    // parameter).
    if (siteAliasOverwrite) return siteAliasOverwrite;

    // If there is no fixed site alias the next option is to parse the site alias from the domain/subdomain.
    // This strategy is intended to be used for production deployments.
    const host = req.headers.host;
    const siteSubdomain = host ? parseSiteAliasFromHostname(host) : undefined;
    if (siteSubdomain) return siteSubdomain;

    // As a last resort check if there is a ?site=... query parameter. If present it will be used as the site alias.
    if (req.url) {
        const { site } = parseQuery(extractQueryString(req.url));

        if (typeof site === 'string') return site;
    }

    return;
};

function parseSiteAliasFromHostname(hostname: string): string | undefined {
    const parts = hostname.split('.');

    if (parts.length === 3) {
        // use subdomains only if they are of the form `site-id.domain.tld`
        //                                              ^^^^^^^
        return parts[0];
    }

    return;
}
