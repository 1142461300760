import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { internalLogin, LoginOptions } from './apiInternalLogin';
import { usePatronInfoUpdate } from '../../context/AuthenticationContext';
import { removeCredits } from '../../context/CreditsContext';
import { removeLoanStatus } from '../../context/LoanStatusContext';
import { browserClient } from '../../setup/axios';
import { UseMutationResult } from '../../setup/react-query/queryTypes';
import { ApiPatronInfo } from '../apiFetchPatronInfo';

type InternalLoginOptions = {
    readonly fetchFunc?: typeof internalLogin;
};

export const useLoginMutation = ({ fetchFunc = internalLogin }: InternalLoginOptions = {}): UseMutationResult<
    ApiPatronInfo,
    LoginOptions,
    AxiosError<any>
> => {
    const client = useQueryClient();
    const updatePatronInfo = usePatronInfoUpdate();

    return useMutation({
        mutationFn: (options: LoginOptions) => fetchFunc(browserClient, options),
        onSuccess: patronInfo => {
            updatePatronInfo(patronInfo);
            removeCredits(client);
            removeLoanStatus(client);
        }
    });
};
