import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';

/**
 * Safely dehydrate the React query client. This removes any undefined objects from the client as next.js cannot
 * stringify them to JSON.
 *
 * See: https://github.com/vercel/next.js/discussions/11209
 *      https://github.com/tannerlinsley/react-query/issues/1370
 */
export const safeDehydrate = (queryClient: QueryClient) => {
    const dehydratedQueryClient = JSON.parse(JSON.stringify(dehydrate(queryClient)));

    // When dehydrating the query client, we also clear the cache.
    // This is because the cache on the server is not needed anymore.
    queryClient.clear();

    return dehydratedQueryClient as DehydratedState;
};
