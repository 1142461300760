import { useMemo } from 'react';

import { SiteParams } from '../../api';
import { useLibraryContext } from '../../context/LibraryContext';
import { useLocale } from '../../utils/localizations/localization';

export function useSiteParams(): SiteParams {
    const { siteId, languageSelectionDisplay } = useLibraryContext();
    const language = useLocale();

    return useMemo(
        () => ({ siteId, language, languageSelectionDisplay }),
        [siteId, language, languageSelectionDisplay]
    );
}
