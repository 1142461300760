import classNames from 'classnames';
import compact from 'lodash/compact';
import Link from 'next/link';
import {
    ChangeEvent,
    Dispatch,
    FC,
    forwardRef,
    KeyboardEventHandler,
    MouseEvent,
    MouseEventHandler,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderAccount } from './HeaderAccount';
import { HeaderLogo } from './HeaderLogo';
import { useLoanFormatLinks } from './HeaderNavigationFirstLine';
import { DarkOverlayActivator } from '../../../context/DarkOverlayContext';
import { useScopeContext } from '../../../context/ScopeContext';
import { useClickOutside } from '../../../hooks/events/useClickOutside';
import { useRedirect } from '../../../hooks/events/useRedirect';
import {
    mapFilterParamsUserFriendly,
    ProductFilterParams,
    useProductListParams
} from '../../../hooks/getters/useProductListParams';
import { useIsPartiallyCurrentRoute } from '../../../hooks/utils/useIsPartiallyCurrentRoute';
import { SEARCH_INPUT_MAX_LENGTH } from '../../../utils/constants';
import { loanFormatToGildClass, LoanFormatType, parseLoanFormatType } from '../../../utils/domain/loanFormat';
import { i18nLoanFormatMap } from '../../../utils/localizations/i18nextMaps';
import { isPresent } from '../../../utils/objectChecks';
import { ADVANCED_SEARCH_PATH, SEARCH_PATH } from '../../../utils/routes/paths';
import { stringifyQuery } from '../../../utils/routes/queryString';
import { LinkWithPopover } from '../../floater/popover/ElementWithPopover';
import { Icon } from '../../Icon';

type SearchHeaderProps = {
    readonly onClose: () => void;
};

export const HeaderSearch: FC<SearchHeaderProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const redirectTo = useRedirect();

    const loanFormatScope = useScopeContext().loanFormat; // /eBooks or /eAudiobooks
    const loanFormatFilter = useProductListParams().params.filters.loanFormat?.toString(); // ?q=harry&loanFormat=eBooks
    const urlLoanFormat = loanFormatScope ?? parseLoanFormat(loanFormatFilter);

    const [selectedLoanFormat, setSelectedLoanFormat] = useState<LoanFormatType | undefined>(
        sanitizeLoanFormat(urlLoanFormat)
    );

    // overwrite the user's dropdown selection when URL parameters changed
    useEffect(() => setSelectedLoanFormat(sanitizeLoanFormat(urlLoanFormat)), [urlLoanFormat]);

    const [searchString, setSearchString] = useState('');
    const [hasValidSearchString, setHasValidSearchString] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const { isCurrent: isOnAdvancedSearchRoute } = useIsPartiallyCurrentRoute(ADVANCED_SEARCH_PATH);

    const setLoanFormat = useCallback((loanFormat: LoanFormatType | undefined) => {
        setSelectedLoanFormat(loanFormat);
    }, []);

    const handleSearch = useCallback(async () => {
        const searchQuery = `q=${encodeURIComponent(searchString)}`;

        const filterParams: ProductFilterParams = selectedLoanFormat ? { loanFormat: [selectedLoanFormat] } : {};
        const loanFormatQuery = stringifyQuery(mapFilterParamsUserFriendly(filterParams));

        const params = compact([searchQuery, loanFormatQuery]).join('&');
        const redirect = [SEARCH_PATH, params].join('?');

        await redirectTo(redirect);

        onClose();
    }, [onClose, redirectTo, searchString, selectedLoanFormat]);

    const handleSearchKeyPressed = useCallback(
        async event => {
            if (event.key === 'Enter' && hasValidSearchString) {
                await handleSearch();
                return;
            }

            if (event.key === 'Escape') onClose();
        },
        [handleSearch, onClose, hasValidSearchString]
    ) as KeyboardEventHandler<HTMLInputElement>;

    const handleSearchStringChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
        setHasValidSearchString(isPresent(e.target.value));
    }, []);

    const inlineSearchRef = useRef(null);

    useClickOutside(inlineSearchRef, popoverOpen ? () => {} : onClose);

    const handleAdvancedSearch = useCallback(() => {
        if (isOnAdvancedSearchRoute) {
            onClose();
        }
    }, [isOnAdvancedSearchRoute, onClose]);

    return (
        <DarkOverlayActivator overlay="inlineSearch">
            <div className="inline-search" ref={inlineSearchRef}>
                <HeaderLogo />
                <div className="search-section">
                    <ul className="format-select">
                        <li>
                            <LoanFormatDropdown
                                selectedLoanFormat={selectedLoanFormat}
                                setPopoverOpen={setPopoverOpen}
                                onSetLoanFormat={loanFormat => setLoanFormat(loanFormat)}
                            />
                        </li>
                    </ul>
                    <div className="input-wrapper">
                        <button
                            className={classNames('button-icon', { 'libcolor-text': hasValidSearchString })}
                            disabled={!hasValidSearchString}
                            onClick={handleSearch}
                        >
                            <Icon icon="search-outline" label={t('defaults.search.button_description')} />
                        </button>
                        <form onSubmit={event => event.preventDefault()}>
                            <input
                                autoFocus
                                type="search"
                                placeholder={t('defaults.search.description')}
                                value={searchString}
                                maxLength={SEARCH_INPUT_MAX_LENGTH}
                                onChange={handleSearchStringChange}
                                onKeyDown={handleSearchKeyPressed}
                            />
                        </form>
                        <button className="button-icon" onClick={onClose}>
                            <Icon icon="close-outline" label={t('defaults.search.close_button_label')} />
                        </button>
                    </div>
                </div>
                <div className="advanced-search">
                    <Link href={ADVANCED_SEARCH_PATH} onClick={handleAdvancedSearch}>
                        {t('defaults.search.advanced')} <Icon icon="caret-right" />
                    </Link>
                </div>
                <HeaderAccount setPopoverOpen={setPopoverOpen} />
            </div>
        </DarkOverlayActivator>
    );
};

type LoanFormatDropdownProps = {
    readonly onSetLoanFormat: (loanFormat: LoanFormatType | undefined) => void;
    readonly setPopoverOpen: Dispatch<SetStateAction<boolean>>;
    readonly selectedLoanFormat?: LoanFormatType;
};

const LoanFormatDropdown: FC<LoanFormatDropdownProps> = ({ onSetLoanFormat, setPopoverOpen, selectedLoanFormat }) => {
    const loanFormatRefs = useLoanFormatLinks();

    const handleLoanFormatClick = useCallback<MouseEventHandler>(
        (event: MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            onSetLoanFormat(event.currentTarget.dataset.loanformat as LoanFormatType | undefined);
        },
        [onSetLoanFormat]
    );

    const popover = (
        <div className="component popover-list theme-expanded">
            <ul>
                <LoanFormatOption handleClick={handleLoanFormatClick}>
                    <i aria-hidden className="icon star-outline" /> {useLoanFormatToLabel()}
                </LoanFormatOption>
                {loanFormatRefs.map(({ loanFormat, label }) => (
                    <LoanFormatOption
                        label={label}
                        key={label}
                        loanFormat={loanFormat}
                        handleClick={handleLoanFormatClick}
                    >
                        <i className={`icon ${loanFormatToGildClass(loanFormat)}-outline`} aria-hidden={true} />
                        &nbsp;
                        {label}
                    </LoanFormatOption>
                ))}
            </ul>
        </div>
    );

    return (
        <LinkWithPopover
            popover={popover}
            className="libcolor-text"
            setPopoverOpen={setPopoverOpen}
            aria-label={useLoanFormatToLabel(selectedLoanFormat)}
        >
            <i
                aria-hidden
                className={`icon ${selectedLoanFormat ? loanFormatToGildClass(selectedLoanFormat) : 'star'}-outline`}
            />
            <div className="text">{useLoanFormatToLabel(selectedLoanFormat)}</div>
            <i aria-hidden className="icon caret-down" />
        </LinkWithPopover>
    );
};

type LoanFormatOptionProps = PropsWithChildren<{
    readonly handleClick: MouseEventHandler;
    readonly loanFormat?: string;
    readonly label?: string;
}>;

const LoanFormatOption = forwardRef<HTMLAnchorElement, LoanFormatOptionProps>(
    ({ handleClick, loanFormat, label, children, ...props }, ref) => {
        return (
            <li key={label}>
                <a {...props} ref={ref} href="#" data-loanformat={loanFormat} onClick={handleClick}>
                    {children}
                </a>
            </li>
        );
    }
);

const useLoanFormatToLabel = (loanFormat?: LoanFormatType): string => {
    const { t } = useTranslation();
    return t(loanFormat ? `defaults.plural.${i18nLoanFormatMap[loanFormat]}` : 'defaults.plural.all');
};

const parseLoanFormat = (loanFormat?: string): LoanFormatType | undefined => {
    try {
        return parseLoanFormatType(loanFormat || '');
    } catch {
        return undefined;
    }
};

/** Replace given `loanFormat` eMagazineIssues with eMagazines, as eMagazineIssues is no valid search parameter. */
const sanitizeLoanFormat = (loanFormat?: LoanFormatType): LoanFormatType | undefined => {
    return loanFormat === LoanFormatType.eMagazineIssues ? LoanFormatType.eMagazines : loanFormat;
};
