import { Loan, LoanErrorResponse, LoanSuccessResponse } from '../../domain/loan';
import { ApiResponse } from '../../setup/axios';
import { isPresent } from '../../utils/objectChecks';
import { isApiError } from '../index';

export function handleLoanSuccess(res: ApiResponse<Loan>): Promise<LoanSuccessResponse> {
    return Promise.resolve({ success: true, loan: res.data });
}

export function handleLoanError(error: Error): Promise<LoanErrorResponse> {
    return new Promise((_resolve, reject) => {
        if (isApiError(error)) {
            const apiErrorKey = isPresent(error.response?.data.errorKeys)
                ? error.response?.data.errorKeys[0]
                : LOAN_API_ERROR_DEFAULT;

            reject({ success: false, error: mapApiErrorKey(apiErrorKey) });
        } else {
            reject(error);
        }
    });
}

const LOAN_API_ERROR_DEFAULT = 'err.loan.request.invalid';
const LOAN_API_ERROR_AGE_RESTRICTION = 'err.loan.request.invalid.ageRestriction';
const LOAN_API_ERROR_ALREADY_ON_LOAN = 'err.loan.request.invalid.alreadyOnLoan';
const LOAN_API_ERROR_ALREADY_ON_RESERVE = 'err.loan.request.invalid.alreadyOnReserve';
const LOAN_API_ERROR_ALREADY_RETURNED_OR_CANCELED = 'err.loan.request.invalid.alreadyReturnedOrCancelled';
const LOAN_API_ERROR_AVAILABLE_ONLY_FOR_RESERVE = 'err.loan.request.invalid.availableOnlyForReserve';
const LOAN_API_ERROR_AVAILABLE_ONLY_FOR_LOAN = 'err.loan.request.invalid.availableOnlyForLoan';
const LOAN_API_ERROR_NOT_ENOUGH_CREDITS = 'err.loan.request.invalid.notEnoughCredits';
const LOAN_API_ERROR_RETURN_FAILED_FULFILLED_BY_ADE = 'err.loan.return.failed.fulfilled_by_ade';
const LOAN_API_ERROR_RETURN_FAILED_DAILY_LIMIT_EXCEEDED = 'err.loan.return.daily_limit_exceeded';
const LOAN_API_ERROR_RETURN_FAILED_WEEKLY_LIMIT_EXCEEDED = 'err.loan.return.weekly_limit_exceeded';
const LOAN_API_ERROR_RETURN_FAILED_MONTHLY_LIMIT_EXCEEDED = 'err.loan.return.monthly_limit_exceeded';

const LOAN_ERROR_KEY_DEFAULT = 'unspecific';
export const LOAN_ERROR_KEY_AGE_RESTRICTION = 'ageRestriction';
export const LOAN_ERROR_KEY_ALREADY_ON_LOAN = 'alreadyOnLoan';
const LOAN_ERROR_KEY_ALREADY_ON_RESERVE = 'alreadyOnReserve';
export const LOAN_ERROR_KEY_ALREADY_RETURNED_OR_CANCELED = 'alreadyReturnedOrCancelled';
const LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_RESERVE = 'availableOnlyForReserve';
const LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_LOAN = 'availableOnlyForLoan';
export const LOAN_ERROR_KEY_NOT_ENOUGH_CREDITS = 'notEnoughCredits';
export const LOAN_ERROR_KEY_RETURN_FAILED_FULFILLED_BY_ADE = 'fulfilledViaADE';
export const LOAN_ERROR_KEY_RETURN_FAILED_DAILY_LIMIT_EXCEEDED = 'dailyLimitExceeded';
export const LOAN_ERROR_KEY_RETURN_FAILED_WEEKLY_LIMIT_EXCEEDED = 'weeklyLimitExceeded';
export const LOAN_ERROR_KEY_RETURN_FAILED_MONTHLY_LIMIT_EXCEEDED = 'monthlyLimitExceeded';

export type LoanErrorKey =
    | typeof LOAN_ERROR_KEY_DEFAULT
    | typeof LOAN_ERROR_KEY_AGE_RESTRICTION
    | typeof LOAN_ERROR_KEY_ALREADY_ON_LOAN
    | typeof LOAN_ERROR_KEY_ALREADY_ON_RESERVE
    | typeof LOAN_ERROR_KEY_ALREADY_RETURNED_OR_CANCELED
    | typeof LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_RESERVE
    | typeof LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_LOAN
    | typeof LOAN_ERROR_KEY_NOT_ENOUGH_CREDITS
    | typeof LOAN_ERROR_KEY_RETURN_FAILED_FULFILLED_BY_ADE
    | typeof LOAN_ERROR_KEY_RETURN_FAILED_DAILY_LIMIT_EXCEEDED
    | typeof LOAN_ERROR_KEY_RETURN_FAILED_WEEKLY_LIMIT_EXCEEDED
    | typeof LOAN_ERROR_KEY_RETURN_FAILED_MONTHLY_LIMIT_EXCEEDED;

function mapApiErrorKey(apiErrorKey: string | undefined | null): LoanErrorKey {
    if (apiErrorKey) {
        switch (apiErrorKey) {
            case LOAN_API_ERROR_AGE_RESTRICTION:
                return LOAN_ERROR_KEY_AGE_RESTRICTION;
            case LOAN_API_ERROR_ALREADY_ON_LOAN:
                return LOAN_ERROR_KEY_ALREADY_ON_LOAN;
            case LOAN_API_ERROR_ALREADY_ON_RESERVE:
                return LOAN_ERROR_KEY_ALREADY_ON_RESERVE;
            case LOAN_API_ERROR_ALREADY_RETURNED_OR_CANCELED:
                return LOAN_ERROR_KEY_ALREADY_RETURNED_OR_CANCELED;
            case LOAN_API_ERROR_AVAILABLE_ONLY_FOR_LOAN:
                return LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_LOAN;
            case LOAN_API_ERROR_AVAILABLE_ONLY_FOR_RESERVE:
                return LOAN_ERROR_KEY_AVAILABLE_ONLY_FOR_RESERVE;
            case LOAN_API_ERROR_NOT_ENOUGH_CREDITS:
                return LOAN_ERROR_KEY_NOT_ENOUGH_CREDITS;
            case LOAN_API_ERROR_RETURN_FAILED_FULFILLED_BY_ADE:
                return LOAN_ERROR_KEY_RETURN_FAILED_FULFILLED_BY_ADE;
            case LOAN_API_ERROR_RETURN_FAILED_DAILY_LIMIT_EXCEEDED:
                return LOAN_ERROR_KEY_RETURN_FAILED_DAILY_LIMIT_EXCEEDED;
            case LOAN_API_ERROR_RETURN_FAILED_WEEKLY_LIMIT_EXCEEDED:
                return LOAN_ERROR_KEY_RETURN_FAILED_WEEKLY_LIMIT_EXCEEDED;
            case LOAN_API_ERROR_RETURN_FAILED_MONTHLY_LIMIT_EXCEEDED:
                return LOAN_ERROR_KEY_RETURN_FAILED_MONTHLY_LIMIT_EXCEEDED;
            case LOAN_API_ERROR_DEFAULT:
            default:
                return LOAN_ERROR_KEY_DEFAULT;
        }
    }

    return LOAN_ERROR_KEY_DEFAULT;
}

export type ApiReturnLoanSuccess = {
    readonly success: true;
};

export function handleReturnSuccess(): ApiReturnLoanSuccess {
    return { success: true };
}
