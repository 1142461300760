/* eslint-disable import/no-unused-modules */

// This file should contain all polyfills that we need for js apis or behaviour.
// if possible, we should use polyfills provided by core-js.
// We can require without an actual browser-check, because the polyfills itself contain code to check whether they are
// needed at all.

if (typeof window === 'object') {
    require('core-js/actual/object/from-entries');
    require('core-js/actual/string/replace-all');
}
