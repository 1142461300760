import { HighlightableText } from './HighlightedText';

/**
 * Props for `<HighlightingFilterOptionLabel>`.
 */
export interface HighlightingFilterOptionLabelProps {
    /**
     * Label text.
     */
    label: string;

    /**
     * Keywords that should be highlighted within the label.
     */
    keywords: ReadonlyArray<string>;
}

/**
 * Highlights keywords within a filter option label.
 */
export const HighlightingFilterOptionLabel = ({ keywords, label }: HighlightingFilterOptionLabelProps) => (
    <HighlightableText text={label} keywords={keywords} />
);
