import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query';
import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { usePatronInfo, useUserRole } from './AuthenticationContext';
import { SiteParams } from '../api';
import { ApiAllLoanStatusResponse, ApiLoanStatus, getAllLoanStatus } from '../api/loan/apiGetAllLoanStatus';
import { useSiteParams } from '../hooks/getters/useSiteParams';
import { useLoanStatusBroadcast } from '../hooks/utils/useLoanStatusBroadcast';
import { browserClient } from '../setup/axios';

type AllLoanStatus = ApiAllLoanStatusResponse;
const emptyLoanStatus: AllLoanStatus = { loans: {} };

const ctx = createContext<AllLoanStatus>(emptyLoanStatus);

export const LoanStatusContext: FC<PropsWithChildren> = ({ children }) => {
    const { isFullUser } = useUserRole();

    if (!isFullUser) {
        return <ctx.Provider value={emptyLoanStatus}>{children}</ctx.Provider>;
    }

    return <LoanStatusSignedIn>{children}</LoanStatusSignedIn>;
};

const LoanStatusSignedIn: FC<PropsWithChildren> = ({ children }) => {
    const { data, isError } = useAllLoanStatusQuery();

    if (isError) {
        return <ctx.Provider value={emptyLoanStatus}>{children}</ctx.Provider>;
    }

    const loanStatus = data ?? emptyLoanStatus;
    return <ctx.Provider value={loanStatus}>{children}</ctx.Provider>;
};

export const useLoanStatus = ({ productId }: { productId: string | undefined }): ApiLoanStatus => {
    const { loans } = useContext(ctx);
    return loans[productId ?? ''] ?? emptyLoanStatus;
};

type QueryParams = SiteParams & {
    readonly userId: string;
};

const BASE_QUERY_KEY = 'AllLoanStatus';

function queryKey({ siteId, userId }: QueryParams): QueryKey {
    return [BASE_QUERY_KEY, { userId, siteId /* language is irrelevant for loan status */ }];
}

/**
 * Do not move the react-query hook to the `api` folder. We don't want to access the loan status via
 * react query, but rather via this context to have exactly one source of truth, which is here.
 */
function useAllLoanStatusQuery() {
    const { siteId, language } = useSiteParams();
    const { userId } = usePatronInfo();
    const { isFullUser } = useUserRole();

    const queryParams = { siteId, language, userId };
    const allLoanStatusQueryKey = queryKey(queryParams);

    const { initialized, broadcastLoanStatus } = useLoanStatusBroadcast(allLoanStatusQueryKey);

    const queryFn = async () => {
        const allLoanStatus = await getAllLoanStatus(browserClient, queryParams);
        broadcastLoanStatus(allLoanStatus);
        return allLoanStatus;
    };

    return useQuery({
        queryKey: allLoanStatusQueryKey,
        queryFn,
        enabled: isFullUser && initialized,
        staleTime: 60_000,
        refetchOnWindowFocus: true
    });
}

export const invalidateLoanStatus = (client: QueryClient) => {
    return client.invalidateQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};

export const removeLoanStatus = (client: QueryClient) => {
    return client.removeQueries({
        queryKey: [BASE_QUERY_KEY]
    });
};
