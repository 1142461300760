import { ReferenceType, UseFloatingReturn } from '@floating-ui/react-dom-interactions';
import { FC, MutableRefObject } from 'react';

import { DEFAULT_PLACEMENT, PLACEMENT_MIRRORED, PlacementBase } from '../utils/constants';

type TooltipArrowProps = {
    readonly arrowRef: MutableRefObject<null>;
    readonly floatingProps: UseFloatingReturn<ReferenceType>;
};

export const Arrow: FC<TooltipArrowProps> = ({ arrowRef, floatingProps }) => {
    const {
        placement,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
    } = floatingProps;

    const arrowPlacement = PLACEMENT_MIRRORED[placement.split('-')[0] as PlacementBase] ?? DEFAULT_PLACEMENT;

    return (
        <div
            className="arrow"
            ref={arrowRef}
            style={{
                position: 'absolute',
                width: '10px',
                height: '10px',
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                [arrowPlacement]: ['bottom', 'right'].includes(placement) ? '-4px' : '-7px'
            }}
        />
    );
};
