import { createContext, Dispatch, FC, PropsWithChildren, useContext, useReducer } from 'react';

import { ProductActionAction, productActionReducer, ProductActionState } from './ProductActionReducer';

const ProductActionContext = createContext<ProductActionState | null>(null);
const ProductActionDispatchContext = createContext<Dispatch<ProductActionAction> | null>(null);

export const ProductActionProvider: FC<PropsWithChildren> = ({ children }) => {
    const [actionState, dispatch] = useReducer(productActionReducer, {
        modalVisible: false
    });

    return (
        <ProductActionContext.Provider value={actionState}>
            <ProductActionDispatchContext.Provider value={dispatch}>{children}</ProductActionDispatchContext.Provider>
        </ProductActionContext.Provider>
    );
};

export const useProductAction = () => {
    const context = useContext(ProductActionContext);
    if (context === null) throw new Error('useProductAction must be used within ProductActionProvider');
    return context;
};

export const useProductActionDispatch = () => {
    const context = useContext(ProductActionDispatchContext);
    if (context === null) throw new Error('useProductActionDispatch must be used within ProductActionProvider');
    return context;
};
