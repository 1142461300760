import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountStatus } from './AccountStatus';
import { LanguageSelectionDisplay } from '../../../api/apiLibraryContext';
import { useUserRole } from '../../../context/AuthenticationContext';
import { useSiteParams } from '../../../hooks/getters/useSiteParams';
import { useIsPartiallyCurrentRoute } from '../../../hooks/utils/useIsPartiallyCurrentRoute';
import { loginRoutePath } from '../../../pages/login/[[...any]]';
import { LOGIN_PATH } from '../../../utils/routes/paths';
import { Icon } from '../../Icon';
import { LanguageSelector } from '../footer/LanguageSelector';

type Props = {
    readonly onSearchClick?: () => void;
    readonly setPopoverOpen?: Dispatch<SetStateAction<boolean>>;
};

export const HeaderAccount: FC<Props> = ({ onSearchClick, setPopoverOpen }) => {
    const { t } = useTranslation();
    const { isGuestUser } = useUserRole();
    const { isCurrent } = useIsPartiallyCurrentRoute(LOGIN_PATH);
    const { languageSelectionDisplay } = useSiteParams();
    const routerPath = useRouter().asPath;

    const loginUrl = isCurrent ? routerPath : loginRoutePath({ redirectTo: routerPath });

    const showLanguageSelector = languageSelectionDisplay === LanguageSelectionDisplay.PROMINENT;

    return (
        <div className="account-wrapper">
            {showLanguageSelector ? <LanguageSelector setPopoverOpen={setPopoverOpen} /> : null}
            {onSearchClick ? (
                <div className="search">
                    <button className="button-icon" onClick={onSearchClick}>
                        <Icon icon="search-outline" label={t('defaults.search.button_description')} />
                    </button>
                </div>
            ) : null}
            {!isGuestUser ? (
                <AccountStatus setPopoverOpen={setPopoverOpen} />
            ) : (
                <Link
                    href={loginUrl}
                    /* We prevent the click event if the user is already on the login page,
                       avoiding adding multiple redirect links to the address bar
                       if the login button is clicked multiple times  */
                    onClick={event => (isCurrent ? event.preventDefault() : null)}
                    className={classNames(
                        'sign-in',
                        'libcolor-text-1419-or-smaller',
                        'libcolor-bg-1420-or-higher',
                        'libcolor-bg-dark-hover-1420-or-higher',
                        'no-libcolor-text-on-link-hover'
                    )}
                >
                    {t('header.sign_in')}
                </Link>
            )}
        </div>
    );
};
