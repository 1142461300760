import { LoanFormatType } from '../domain/loanFormat';

export const PLACEHOLDER_SQUARED =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQAQMAAAC6caSPAAAAA1BMVEWlpaUNBb2aAAAAKklEQVR4Ae3BgQAAAADDoPtTH2AK1QAAAAAAAAAAAAAAAAAAAAAAAACAOE+wAAH1KUxJAAAAAElFTkSuQmCC';

export const PLACEHOLDER_EAUDIOBOOK = PLACEHOLDER_SQUARED;

const PLACEHOLDER_EBOOK =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAJYAQMAAAB2IiLLAAAAA1BMVEWmpqa9ZwG6AAAANElEQVR42u3BMQEAAADCIPuntsYOYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHZ3iAABHpSzmQAAAABJRU5ErkJggg==';

const PLACEHOLDER_EMAGAZINE =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAIIAQMAAAB6rCo8AAAAA1BMVEWmpqa9ZwG6AAAAMElEQVR42u3BAQ0AAADCIPuntscHDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABCDmeYAAFATMXAAAAAAElFTkSuQmCC';

export const PLACEHOLDER_GENRE_BANNER =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAADIAQMAAAAOUQ31AAAAA1BMVEWlpaUNBb2aAAAAJElEQVR4Ae3BgQAAAADDoPtTH2TVQgAAAAAAAAAAAAAAAAAA4DtgAAGfzFPgAAAAAElFTkSuQmCC';

export function getPlaceholderCoverByFormat(loanFormat: LoanFormatType) {
    switch (loanFormat) {
        case LoanFormatType.eaudiobooks:
            return PLACEHOLDER_EAUDIOBOOK;
        case LoanFormatType.eMagazines:
        case LoanFormatType.eMagazineIssues:
            return PLACEHOLDER_EMAGAZINE;
        default:
            return PLACEHOLDER_EBOOK;
    }
}
