import { isSSR } from '../../utils/nextjs/ssr';

export const GTAG_LIB_SCRIPT_ID = 'google-analytics-gtag-lib';
export const GTAG_SETUP_SCRIPT_ID = 'google-analytics-gtag-setup';

export const getGtagLibScriptSrc = () => `https://www.googletagmanager.com/gtag/js?id=${getGtagId()}`;

export const getGtagSetupScriptContent = () => `
    window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${getGtagId()}');
    gtag('consent', 'default', { analytics_storage: 'denied' });
`;

export function isGoogleAnalyticsEnabled(): boolean {
    if (isSSR()) {
        // this environment variable is injected into the server process via
        // gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        return process.env['GOOGLE_ANALYTICS_ENABLED']?.toLowerCase() === 'true';
    }

    return document.documentElement.getAttribute('data-google-analytics-enabled')?.toLowerCase() === 'true';
}

export function getGtagId(): string {
    if (isSSR()) {
        // this environment variable is injected into the server process via
        // gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        return process.env['GOOGLE_ANALYTICS_TRACKING_ID'] ?? '';
    }

    return document.documentElement.getAttribute('data-google-analytics-tracking-id') ?? '';
}

export function isGtagLibScriptPresent(): boolean {
    if (typeof document === 'undefined') return false;

    return document.querySelector(`script#${GTAG_LIB_SCRIPT_ID}`) !== null;
}

export function isGtagSetupScriptPresent(): boolean {
    if (typeof document === 'undefined') return false;

    return document.querySelector(`script#${GTAG_SETUP_SCRIPT_ID}`) !== null;
}
