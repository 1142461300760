import i18n, { Resource } from 'i18next';
import invert from 'lodash/invert';
import merge from 'lodash/merge';

import { ApiClient } from './axios';
import { SiteParams } from '../api';
import { fetchSiteSpecificLocalization } from '../api/apiLocalization';
import { transformLocalizations } from '../utils/localizations/transformLocalizations';
import LoggingUtils from '../utils/logging/LoggingUtils';
import { isEmpty, isPresent } from '../utils/objectChecks';

export type TranslationI18nType = typeof import('../../localizations/en-AU/common.json');
export type FiltersI18nType = typeof import('../../localizations/en-AU/filters.json');
type HelpI18nType = typeof import('../../localizations/en-AU/help.json');

export type LocalResourceType = {
    common: TranslationI18nType;
    filters: FiltersI18nType;
    help: HelpI18nType;
};

// List of keys that are set per site and can be missing on some. They are not set in our fallback / default
// localization.
const keyIgnoreList = ['login.help_text', 'login.password_link_url', 'login.join_link'];

// List of keys whose sub keys are allowed to be missing in each environment.
const keyPartIgnoreListProd = ['product_group_titles.'];
const keyPartIgnoreListStage = [...keyPartIgnoreListProd, 'genre.', 'languages.Abkhaz'];
const keyPartIgnoreListDev = [...keyPartIgnoreListStage];

const keyPartIgnoreList =
    process.env.NODE_ENV === 'development'
        ? keyPartIgnoreListDev
        : process.env.BOL_ENVIRONMENT === 'stage'
          ? keyPartIgnoreListStage
          : keyPartIgnoreListProd;

// List of available namespaces
const AVAILABLE_NAMESPACES = ['common', 'filters', 'help'] as const;

export const DEFAULT_I18NEXT_NAMESPACES: Array<i18nextNamespace> = ['filters', 'common'];

// Create a type i18nextNamespace which is a string union of the elements of AVAILABLE_NAMESPACES
export type i18nextNamespace = (typeof AVAILABLE_NAMESPACES)[number];
export type PropertyServiceNamespace = 'website' | 'website_filters' | 'website_page_help';

const i18nextToPropertyServiceNamespace: Record<i18nextNamespace, PropertyServiceNamespace> = {
    common: 'website',
    filters: 'website_filters',
    help: 'website_page_help'
};

export const propertyServiceToI18nextNamespace = invert(i18nextToPropertyServiceNamespace);

export const LOCALE_COOKIE_NAME = 'LOCALE';
export const LOCALE_COOKIE_MAY_AGE = 180 * 24 * 60 * 60;
export const LOCALE_COOKIE_REFRESH_THRESHOLD = 1; // [days]

export const DEFAULT_LANGUAGE = 'en-AU';

export const DEFAULT_SUPPORTED_LANGUAGES = {
    'cy-GB': 'Cymraeg',
    'en-AU': 'English'
};

// We should make sure that the polyfills in src/setup/polyfills/checkIntlPolyfills.js are in sync with this list.
const ALL_SUPPORTED_LANGUAGES = {
    'cy-GB': 'Cymraeg',
    'de-DE': 'Deutsch',
    'en-AU': 'English'
};

export const FALLBACK_COUNTRY_REGION = {
    cy: 'GB',
    de: 'DE',
    en: 'AU'
};

// For now, we maintain this list for testing and demo purposes. In the long run, the configuration of
// generally supported languages for a specific site should be handled elsewhere (NBO?)
// and we should get this info i.e. with the siteContext.
export const SITE_SUPPORTED_LANGUAGES = {
    '0': ALL_SUPPORTED_LANGUAGES, // // Preview Online Reader: preview-test.borrowbox.io
    '1803': ALL_SUPPORTED_LANGUAGES, // YSL: ysl.borrowbox.io
    '6541': ALL_SUPPORTED_LANGUAGES, // Solution Mgmt Tests: solman.borrowbox.com
    '6765': ALL_SUPPORTED_LANGUAGES // DE demo site: dedemo.borrowbox.com
};

export function createInstance(resources: Resource) {
    let resourcesAvailable = true;
    // we only use one language (with already merged english fallbacks) in our resources,
    // so this single object key is our selected language
    const lng = Object.keys(resources)[0];

    // eslint-disable-next-line import/no-named-as-default-member
    return i18n.createInstance({
        debug: false,
        resources,
        defaultNS: 'common',
        nsSeparator: ':',
        pluralSeparator: '+',
        lng,
        interpolation: {
            prefix: '{{',
            suffix: '}}',
            escapeValue: false // react already safes from xss
        },
        initImmediate: false,
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue) => {
            if (!resourcesAvailable) return;

            if (isEmpty(resources)) {
                resourcesAvailable = false;
                LoggingUtils.logError(new Error(`No i18n resources available.`));
            }

            if (
                isPresent(resources) &&
                !keyIgnoreList.includes(key) &&
                !keyPartIgnoreList.some(ignore => key.startsWith(ignore))
            ) {
                LoggingUtils.logErrorWithPayload(
                    // eslint-disable-next-line max-len
                    `Could not find translation for key "${key}" in namespace "${ns}". Fallback is "${fallbackValue}". Langs = "${lngs}"`,
                    { key, ns, lng }
                );
            }
        }
    });
}

export async function importLocales(
    locale: string,
    namespaces: Array<i18nextNamespace> = DEFAULT_I18NEXT_NAMESPACES
): Promise<LocalResourceType> {
    const locales = {
        common: namespaces.includes('common') ? await import(`../../localizations/${locale}/common.json`) : {},
        filters: namespaces.includes('filters') ? await import(`../../localizations/${locale}/filters.json`) : {},
        help: namespaces.includes('help') ? await import(`../../localizations/${locale}/help.json`) : {}
    };

    // We need to deep clone the dynamic imported JSON files.
    // Otherwise, merging the site specific translations will directly mutate the dynamic imported JSON files. This may
    // cause sites to have site specific translations from other sites. This is because the dynamic imported JSON files
    // are js files with JSON.parse when built, so they are imported as a reference to the same object.
    try {
        return structuredClone(locales);
    } catch (error) {
        // Fallback if structuredClone is not supported. (high memory usage)
        return JSON.parse(JSON.stringify(locales)) as LocalResourceType;
    }
}

export async function loadI18nResources(
    client: ApiClient,
    siteParams: SiteParams,
    locale: string,
    namespaces: Array<i18nextNamespace> = DEFAULT_I18NEXT_NAMESPACES
): Promise<Resource> {
    const fetchedSiteSpecificLocalization = await fetchSiteSpecificLocalization(client, siteParams);
    const siteSpecificTranslations = transformLocalizations([fetchedSiteSpecificLocalization]);
    const siteSpecificLocale = siteSpecificTranslations['en'] ?? {};
    const userLocale = await importLocales(locale, namespaces);

    // Note: merge will mutate the first object.
    const mergedLocales = merge({}, userLocale, siteSpecificLocale);

    return { [locale]: mergedLocales };
}
