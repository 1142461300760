// react normalizes the value of `event.key` for use in DOM event handlers
export enum KEY {
    ARROW_DOWN = 'ArrowDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_UP = 'ArrowUp',
    END = 'End',
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    HOME = 'Home',
    MINUS = '-',
    PLUS = '+',
    SPACE = ' ',
    TAB = 'Tab'
}
