/* eslint-disable import/no-unused-modules */

// This file should contain all polyfills that we need for certain DOM apis or behaviour.
// We can require without an actual browser-check, because the polyfills itself contain code to check whether they are
// needed at all.

import ResizeObserverPolyfill from 'resize-observer-polyfill';
import smoothScroll from 'smoothscroll-polyfill';

import { isCSR } from '../../utils/nextjs/ssr';

if (isCSR()) {
    smoothScroll.polyfill();

    // Needed because of support for [Safari 12+ and Safari 13.0](https://caniuse.com/resizeobserver)
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}
