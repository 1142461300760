import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';

import { isCSR } from '../../utils/nextjs/ssr';

/**
 * Check if Intl polyfills are needed and load them if so.
 *
 * The main reason for having this Polyfill logic is, that as of April 2023 Chromium based browsers do not properly
 * support Welsh locale, see https://crbug.com/1426462. Should this change, we might get rid of this function.
 */
export async function checkIntlPolyfills(locale: string) {
    if (isCSR()) {
        if (shouldPolyfillGetCanonicalLocales()) {
            await import('@formatjs/intl-getcanonicallocales/polyfill');
        }

        if (shouldPolyfillLocale()) {
            await import('@formatjs/intl-locale/polyfill');
        }

        if (shouldPolyfillPluralRules(locale)) {
            await import('@formatjs/intl-pluralrules/polyfill-force');

            switch (locale) {
                case 'cy-GB':
                    await import('@formatjs/intl-pluralrules/locale-data/cy');
                    break;
                case 'de-DE':
                    await import('@formatjs/intl-pluralrules/locale-data/de');
                    break;
                default:
                    await import('@formatjs/intl-pluralrules/locale-data/en');
                    break;
            }
        }

        if (shouldPolyfillNumberFormat(locale)) {
            await import('@formatjs/intl-numberformat/polyfill-force');

            switch (locale) {
                case 'cy-GB':
                    await import('@formatjs/intl-numberformat/locale-data/cy');
                    break;
                case 'de-DE':
                    await import('@formatjs/intl-numberformat/locale-data/de');
                    break;
                default:
                    await import('@formatjs/intl-numberformat/locale-data/en-AU');
                    break;
            }
        }

        if (shouldPolyfillDateTimeFormat(locale)) {
            await import('@formatjs/intl-datetimeformat/polyfill-force');
            await import('@formatjs/intl-datetimeformat/add-all-tz');

            switch (locale) {
                case 'cy-GB':
                    await import('@formatjs/intl-datetimeformat/locale-data/cy');
                    break;
                case 'de-DE':
                    await import('@formatjs/intl-datetimeformat/locale-data/de');
                    break;
                default:
                    await import('@formatjs/intl-datetimeformat/locale-data/en-AU');
                    break;
            }
        }
    }
}
