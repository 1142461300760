import compact from 'lodash/compact';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { ADD_TO_LIST_ACTION } from './MyListButton';
import { useReadingLists } from '../api/reading-list/useReadingLists';
import { useRedirect } from '../hooks/events/useRedirect';
import { stringifyQuery } from '../utils/routes/queryString';
import { extractBasePath, filterNonProductListQueryParams } from '../utils/routes/routing';

export const HandleRequestedAddToListProduct = () => {
    const router = useRouter();
    const redirectTo = useRedirect();
    const { addToReadingLists } = useReadingLists();

    const productId =
        typeof router.query[ADD_TO_LIST_ACTION] === 'string' ? (router.query[ADD_TO_LIST_ACTION] as string) : undefined;

    useEffect(() => {
        if (!productId) return;

        addToReadingLists(productId);

        const newQuery = filterNonProductListQueryParams(router.query);
        delete newQuery[ADD_TO_LIST_ACTION];
        const newUrl = compact([extractBasePath(router.asPath), stringifyQuery(newQuery)]).join('?');
        void redirectTo(newUrl, true);
    }, [addToReadingLists, productId, redirectTo, router.asPath, router.query]);

    return null;
};
