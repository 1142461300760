import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode, useEffect, useId, useRef } from 'react';
import ReactDOM from 'react-dom';
import ReactFocusLock from 'react-focus-lock';

import { useClickOutside } from '../hooks/events/useClickOutside';
import { useEscape } from '../hooks/events/useEscape';
import { customize } from '../utils/localizations/customize';

interface IModalProps {
    isOpen: boolean;
    totalNumberButtons?: number;
    className?: string;
    title?: string;
    text?: string;
    errorMessage?: string | ReactNode;
    buttons?: ReactNode;
    onClose: () => void;
}
/* FIXME: ModalDialog can currently only be used with isOpen set to true unconditionally. Attempting to use isOpen to
     control the modal state results in broken scrolling of the main page due to the useEffect which disables scrolling
     unconditionally. Showing/Hiding the modal only works by rendering/not rendering the whole ModalDialog component. */
export const ModalDialog: FC<PropsWithChildren<IModalProps>> = ({
    isOpen,
    title,
    text,
    className,
    buttons,
    onClose,
    errorMessage,
    totalNumberButtons = 2,
    children
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    useClickOutside(modalRef, onClose);
    const onKeyDown = useEscape(onClose);

    const id = useId();
    const titleId = id + '-title';
    const contentId = id + '-content';

    // This effect holds the modal in place and fixes the background at the current scroll position.
    useEffect(() => {
        if (!isOpen) return;

        const scrollBarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflowY = 'scroll';
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.width = `${window.innerWidth - scrollBarWidth}px`;
        document.body.style.position = 'fixed';

        return () => {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            document.body.style.overflowY = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        };
    });

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <>
            <div className="component modal-backdrop" data-state={errorMessage ? 'error' : undefined} />
            <div
                data-state={errorMessage ? 'error' : undefined}
                className={classNames('component', 'modal', { 'buttons-right': errorMessage }, className)}
                role="dialog"
                aria-labelledby={titleId}
                aria-describedby={contentId}
                aria-modal={true}
                onKeyDown={onKeyDown}
            >
                <div className="panel-wrapper" role="document">
                    <ReactFocusLock returnFocus={{ preventScroll: true }} autoFocus={true}>
                        <div ref={modalRef} className="panel">
                            <div className="header">
                                <h4 id={titleId}>{title}</h4>
                            </div>
                            <div className="body" id={contentId}>
                                {text ? <p>{customize(text)}</p> : null}
                                {errorMessage ? (
                                    <div className="component error-message">
                                        <p>
                                            <span className="icon error-circle" />
                                            {errorMessage}
                                        </p>
                                    </div>
                                ) : null}
                                {children}
                            </div>
                            {buttons ? (
                                <div className="footer" data-buttoncount={totalNumberButtons}>
                                    {buttons}
                                </div>
                            ) : null}
                        </div>
                    </ReactFocusLock>
                </div>
            </div>
        </>,
        document.body
    ) as ReactNode;
};
