import { MouseEvent, useCallback, useState } from 'react';

export type DispatchToggleAction = (newState?: boolean | MouseEvent<HTMLElement>) => void;

/**
 * Helper for `boolean` type React states.
 *
 * @param initialState
 * @return [state, toggle]
 *   - `state` is the React state.
 *   - `toggle` is the `state` toggle function which toggles between `true` and `false`.
 *      The setter can be called with a boolean value to set a specific state (default `useState` behaviour).
 *      For convenience, it can also be called implicitly with a `MouseEvent` i.e. in `<button onClick={toggle}/>`.
 */
export const useToggle = (initialState: boolean = false): [boolean, DispatchToggleAction] => {
    const [state, setState] = useState<boolean>(initialState);

    const toggle = useCallback((newState?: boolean | MouseEvent<HTMLElement>) => {
        const isForcedState = typeof newState === 'boolean';
        isForcedState ? setState(newState) : setState(current => !current);
    }, []);

    return [state, toggle];
};
