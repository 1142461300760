import { QueryClient, QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import filter from 'lodash/filter';
import map from 'lodash/map';
import without from 'lodash/without';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { addProductToReadingLists } from './apiAddProductToReadingLists';
import { ApiReadingListProducts, EMPTY_READING_LIST } from './apiFetchReadingListProducts';
import {
    ApiReadingListProductsByFormat,
    EMPTY_READING_LIST_BY_FORMAT,
    isReadingListSortOrder,
    ReadingListSortOrder
} from './apiFetchReadingListProductsByFormat';
import { ApiReadingListsProductIdsResponse, fetchReadingListsProductIds } from './apiFetchReadingListsProductIds';
import { removeProductFromReadingLists } from './apiRemoveProductFromReadingLists';
import {
    buildReadingListProductsByFormatQueryKey,
    invalidateReadingListProductsByFormat,
    ReadingListsProductsQueryKey
} from './useReadingListProductsByFormat';
import { buildReadingListsProductsQueryKey, invalidateReadingListsProducts } from './useReadingListsProducts';
import { usePatronInfo, useUserRole } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { performOptimisticUpdate } from '../../utils/performOptimisticUpdate';

const READING_LISTS_PRODUCT_IDS_QUERY_KEY = 'reading-lists-product-ids';

type UseReadingLists = {
    isOnReadingLists(productId: string): boolean;
    addToReadingLists(productId: string): void;
    removeFromReadingLists(productId: string): void;
};

export function useReadingLists(): UseReadingLists {
    const { siteId, language } = useSiteParams();
    const { userId } = usePatronInfo();
    const { isFullUser } = useUserRole();

    const queryClient = useQueryClient();

    const productIdsQueryKey = buildQueryKey(userId, siteId);
    const productsQueryKey = buildReadingListsProductsQueryKey({ userId, siteId });

    const productsByFormatQueryKeys: Record<string, ReadingListsProductsQueryKey> = {};

    const params = useRouteParams();
    const loanFormats = [LoanFormatType.ebooks, LoanFormatType.eaudiobooks, LoanFormatType.eMagazineIssues];

    loanFormats.forEach(loanFormat => {
        productsByFormatQueryKeys[loanFormat] = buildReadingListProductsByFormatQueryKey({
            loanFormat,
            userId,
            siteId,
            params
        });
    });

    const { data: response } = useQuery({
        queryKey: productIdsQueryKey,
        queryFn: () => fetchReadingListsProductIds(browserClient, { siteId, language }),
        enabled: isFullUser
    });

    const { mutate: addProduct } = useMutation({
        mutationFn: (productId: string) => addProductToReadingLists(browserClient, productId, { siteId, language }),
        // perform an optimistic update on the reading lists product IDs and products
        onMutate: async (productId: string) =>
            performOptimisticUpdate(
                queryClient,
                productIdsQueryKey,
                (old: ApiReadingListsProductIdsResponse | undefined) =>
                    old?.productIds
                        ? {
                              ...old,
                              productIds: [productId, ...old.productIds]
                          }
                        : { productIds: [productId] }
            ),
        onError: (_err, _variables, previousProductIds) => {
            // rollback optimistic update if query fails
            queryClient.setQueryData(productIdsQueryKey, previousProductIds);
        },
        onSuccess: async () => {
            await Promise.all([
                invalidateReadingListsProductIds(queryClient),
                invalidateReadingListsProducts(queryClient),
                invalidateReadingListProductsByFormat(queryClient)
            ]);
        }
    });

    const { mutate: removeProduct } = useMutation({
        mutationFn: (productId: string) =>
            removeProductFromReadingLists(browserClient, productId, { siteId, language }),
        // perform an optimistic update on the reading lists product IDs and products
        onMutate: async (productId: string) => {
            const [
                previousProductIds,
                previousProducts,
                previousProductsEbooks,
                previousProductsEAudiobooks,
                previousProductsEmagazineIssues
            ] = await Promise.all([
                performOptimisticUpdate(
                    queryClient,
                    productIdsQueryKey,
                    (old: ApiReadingListsProductIdsResponse | undefined) =>
                        old?.productIds
                            ? {
                                  ...old,
                                  productIds: without(old.productIds, productId)
                              }
                            : { productIds: [] }
                ),
                performOptimisticUpdate(queryClient, productsQueryKey, (old: ApiReadingListProducts | undefined) =>
                    old ? removeByProductId(old, productId) : EMPTY_READING_LIST
                ),
                ...loanFormats.map(loanFormat =>
                    performOptimisticUpdate(
                        queryClient,
                        productsByFormatQueryKeys[loanFormat],
                        (old: ApiReadingListProductsByFormat | undefined) =>
                            old ? removeByFormatByProductId(old, productId) : EMPTY_READING_LIST_BY_FORMAT
                    )
                )
            ]);

            return {
                previousProductIds,
                previousProducts,
                [LoanFormatType.ebooks]: previousProductsEbooks,
                [LoanFormatType.eaudiobooks]: previousProductsEAudiobooks,
                [LoanFormatType.eMagazines]: undefined,
                [LoanFormatType.eMagazineIssues]: previousProductsEmagazineIssues
            };
        },
        onError: (_err, _variables, previousValues) => {
            // rollback optimistic updates if query fails
            queryClient.setQueryData(productIdsQueryKey, previousValues?.previousProductIds);
            queryClient.setQueryData(productsQueryKey, previousValues?.previousProducts);

            loanFormats.forEach(loanFormat => {
                const previous = previousValues?.[loanFormat];
                if (previous) queryClient.setQueryData(productsByFormatQueryKeys[loanFormat], previous);
            });
        },
        onSuccess: async () => {
            await Promise.all([
                invalidateReadingListsProductIds(queryClient),
                invalidateReadingListsProducts(queryClient),
                invalidateReadingListProductsByFormat(queryClient)
            ]);
        }
    });

    const isOnReadingLists = useCallback(
        (productId: string) => response?.productIds.includes(productId) ?? false,
        [response]
    );

    return useMemo(
        () => ({
            isOnReadingLists,
            addToReadingLists: addProduct,
            removeFromReadingLists: removeProduct
        }),
        [isOnReadingLists, addProduct, removeProduct]
    );
}

function invalidateReadingListsProductIds(queryClient: QueryClient): Promise<void> {
    return queryClient.invalidateQueries({
        queryKey: [READING_LISTS_PRODUCT_IDS_QUERY_KEY]
    });
}

export function buildQueryKey(userId: string, siteId: string | null | undefined): QueryKey {
    return [READING_LISTS_PRODUCT_IDS_QUERY_KEY, userId, { siteId }];
}

function removeByProductId(
    readingLists: ApiReadingListProducts | undefined,
    productId: string
): ApiReadingListProducts {
    if (!readingLists) return EMPTY_READING_LIST;

    return {
        ...readingLists,
        items: map(readingLists.items, readingList => ({
            ...readingList,
            totalCount: readingList.totalCount - 1,
            products: filter(readingList.products, product => product.productId !== productId)
        }))
    };
}

const removeByFormatByProductId = (
    readingList: ApiReadingListProductsByFormat | undefined,
    productId: string
): ApiReadingListProductsByFormat => {
    if (!readingList) return EMPTY_READING_LIST_BY_FORMAT;

    return {
        ...readingList,
        items: filter(readingList.items, product => product.productId !== productId),
        totalCount: readingList.totalCount - 1
    };
};

const useRouteParams = () => {
    const router = useRouter();

    const routeDependantLimit = router.query.loanFormatPath ? 60 : 40;
    const limit = typeof router.query.limit === 'string' ? parseInt(router.query.limit) : routeDependantLimit;

    const offset = typeof router.query.offset === 'string' ? parseInt(router.query.offset) : 0;

    const sortOrder: ReadingListSortOrder | undefined = isReadingListSortOrder(router.query.sort)
        ? router.query.sort
        : undefined;

    return { limit, offset, sortOrder };
};
