import sum from 'lodash/sum';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

const GILD_CLASSNAME = 'dark-overlay';
const GILD_INLINE_SEARCH_MODIFIER_CLASSNAME = 'inline-search-is-open';

type Overlay = 'default' | 'inlineSearch';

type Context = {
    overlays: Record<Overlay, number>;
    setVisibleOverlays: (stateAction: (prevValue: Record<Overlay, number>) => Record<Overlay, number>) => void;
};

const Ctx = createContext<Context>({
    overlays: { default: 0, inlineSearch: 0 },
    setVisibleOverlays: () => {}
});

export const DarkOverlayProvider: FC<PropsWithChildren> = ({ children }) => {
    const [overlays, setVisibleOverlays] = useState({ default: 0, inlineSearch: 0 });
    const value = useMemo(
        () => ({
            overlays,
            setVisibleOverlays
        }),
        [overlays, setVisibleOverlays]
    );

    return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};

export const useDarkOverlayVisible = () => {
    return sum(Object.values(useContext(Ctx).overlays)) > 0;
};

export const useDarkOverlayClassNames = () => {
    const overlays = useContext(Ctx).overlays;
    if (overlays.default === 0 && overlays.inlineSearch > 0) {
        return [GILD_CLASSNAME, GILD_INLINE_SEARCH_MODIFIER_CLASSNAME];
    } else if (overlays.default > 0) {
        return [GILD_CLASSNAME];
    } else {
        return [];
    }
};

type ActivatorProps = PropsWithChildren<{
    readonly overlay?: Overlay;
}>;

export const DarkOverlayActivator: FC<ActivatorProps> = ({ overlay = 'default', children }) => {
    const setVisibleOverlays = useContext(Ctx).setVisibleOverlays;
    useEffect(() => {
        setVisibleOverlays(old => ({
            ...old,
            [overlay]: old[overlay] + 1
        }));
        return () => {
            setVisibleOverlays(old => ({
                ...old,
                [overlay]: old[overlay] - 1
            }));
        };
    }, [setVisibleOverlays, overlay]);

    return <>{children}</>;
};
