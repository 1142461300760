import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiCreateOrUpdateSubscriptionBody, createOrUpdateSubscription } from './apiCreateOrUpdateSubscription';
import { invalidateSubscription } from './useSubscription';
import { invalidateLoanStatus } from '../../context/LoanStatusContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import { invalidateActiveLoansAndReserves } from '../loan/useActiveLoansAndReserves';
import { invalidateLoanDetails } from '../loan/useLoanDetails';

export const useSubscribeMutation = () => {
    const queryClient = useQueryClient();
    const { siteId, language } = useSiteParams();

    return useMutation({
        mutationFn: (options: ApiCreateOrUpdateSubscriptionBody) =>
            createOrUpdateSubscription(browserClient, { siteId, language }, options),
        onSuccess: () => invalidateOnSubscribeSuccess(queryClient)
    });
};

export const invalidateOnSubscribeSuccess = async (queryClient: QueryClient) =>
    Promise.all([
        // not prefetched in SSR, needed in the borrow/reserve-modals
        invalidateSubscription(queryClient),
        invalidateLoanStatus(queryClient),
        // subscribing is possible directly(!) on the loan-details-page
        invalidateLoanDetails(queryClient),
        invalidateActiveLoansAndReserves(queryClient)
    ]);
