import { ShortProduct } from '../../domain/product';
import { ApiClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { SiteParams } from '../index';
import { SortOrder } from '../searchProducts';

type ApiReadingListItem = {
    readonly key: 'EBOOKS' | 'EAUDIOBOOKS' | 'EMAGAZINEISSUES';
    readonly label: 'eBooks' | 'eAudiobooks' | 'eMagazineIssues';
    readonly products: ReadonlyArray<ShortProduct>;
    readonly totalCount: number;
};

export type ApiReadingListProducts = {
    readonly items: ReadonlyArray<ApiReadingListItem>;
};

export const EMPTY_READING_LIST: ApiReadingListProducts = { items: [] };

/**
 * Represents the request/query parameter which can be included when fetching
 * historical loans.
 */
type ApiReadingListProductsRequestParams = {
    /**
     * The loan format for which historical loans should be fetched. If no loan
     * format is given, historical loans for all formats should be fetched.
     */
    readonly loanFormat?: LoanFormatType;
    readonly pageSize?: number;
    readonly nextPosition?: number;
    readonly sortOrder?: SortOrder;
};

export function fetchReadingListProducts(
    apiClient: ApiClient,
    { siteId, language, ...params }: ApiReadingListProductsRequestParams & SiteParams
): Promise<ApiReadingListProducts> {
    return apiClient
        .get<ApiReadingListProducts>('/api/v2/reading-lists', { siteId, language, params })
        .then(res => res.data);
}
