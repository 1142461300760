import {
    createContext,
    FC,
    PropsWithChildren,
    ReactEventHandler,
    SyntheticEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

type AudioControls = {
    readonly pause: () => void;
    readonly destroy: () => void;
};

type PlayOptions = {
    readonly src: string | undefined;
    readonly onTimeUpdate: (progress: number) => void;
    readonly onEnded: () => void;
    readonly onError?: ReactEventHandler<HTMLAudioElement>;
};

type AudioPlayerContext = {
    /**
     * Replaces current player with new source.
     * Note: You are not supposed to use the playControls after options.onEnded() was called
     */
    readonly play: (options: PlayOptions) => { playControls: AudioControls; playPromise: Promise<void> };
};

const AudioCtx = createContext<AudioPlayerContext>({
    play() {
        return {
            playControls: {
                pause: () => {},
                destroy: () => {}
            },
            playPromise: Promise.resolve()
        };
    }
});

/**
 * Provides a context for audio players which are implemented via the useAudio hook. It ensures only one player is
 * running at a time.
 */
export const AudioPlayerContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [playOptions, setPlayOptions] = useState<PlayOptions>();

    const onTimeUpdate = useCallback(() => {
        if (audioRef.current) {
            const { currentTime, duration } = audioRef.current;
            playOptions?.onTimeUpdate(currentTime / duration);
        }
    }, [audioRef, playOptions]);

    const play = useCallback(
        (options: PlayOptions) => {
            // signal previous caller that his audio ended
            if (playOptions) playOptions.onEnded();

            setPlayOptions(options);

            const playControls = {
                pause: () => {
                    audioRef.current?.pause();
                },
                destroy: () => {
                    audioRef.current?.pause();
                    setPlayOptions(undefined);
                }
            };

            const playPromise = new Promise<void>((resolve, reject) => {
                requestAnimationFrame(() => {
                    audioRef.current?.play().then(resolve, reject);
                });
            });

            return { playControls, playPromise };
        },
        [playOptions]
    );

    const ctx: AudioPlayerContext = useMemo(() => ({ play }), [play]);

    return (
        <AudioCtx.Provider value={ctx}>
            <audio
                ref={audioRef}
                preload="none"
                src={playOptions?.src}
                onTimeUpdate={onTimeUpdate}
                onEnded={playOptions?.onEnded}
                onError={playOptions?.onError}
            />
            {children}
        </AudioCtx.Provider>
    );
};

type UseAudioOptions = {
    readonly src: string | undefined;
};

type UseAudio = {
    readonly playing: boolean;

    /**
     * Percentage of the audio track that has already been played
     */
    readonly progress: number;

    readonly error?: SyntheticEvent<HTMLAudioElement, Event> | null | true;

    start(): void;
    stop(): void;
    toggle(): void;
};

export function useAudio({ src }: UseAudioOptions): UseAudio {
    type AudioPlayerState = 'idle' | 'loading' | 'playing';

    const [state, setState] = useState<AudioPlayerState>('idle');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState<SyntheticEvent<HTMLAudioElement, Event> | null | true>(null);
    const controls = useRef<AudioControls | null>(null);
    const { play } = useContext(AudioCtx);

    if (!src && error === null) setError(true);

    const resetError = useDebouncedCallback(
        () => {
            setError(null);
            controls.current?.destroy();
        },
        1000,
        { leading: false, trailing: true }
    );

    const start = useCallback(() => {
        if (!src) return;

        const { playControls, playPromise } = play({
            src,
            onTimeUpdate: setProgress,
            onEnded: () => {
                controls.current = null;
                setState('idle');
                setProgress(0);
            },
            onError: error => {
                setError(error);
                resetError();
            }
        });

        controls.current = playControls;
        setState('loading');

        playPromise.then(() => setState('playing')).catch(() => setState('idle'));
    }, [controls, play, src, resetError]);

    const stop = useCallback(() => {
        if (controls.current) {
            controls.current.pause();
            setState('idle');
        }
    }, [controls]);

    const toggle = useCallback(() => {
        state === 'idle' ? start() : stop();
    }, [state, start, stop]);

    useEffect(() => () => controls.current?.destroy(), []);

    return useMemo(
        () => ({
            playing: state !== 'idle',
            start,
            stop,
            toggle,
            progress,
            error
        }),
        [state, start, stop, toggle, progress, error]
    );
}
