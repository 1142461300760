import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiCreateOrUpdateSubscriptionBody, createOrUpdateSubscription } from './apiCreateOrUpdateSubscription';
import { invalidateOnSubscribeSuccess } from './useSubscribeMutation';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import { loanProduct } from '../loan/apiLoanProduct';
import { invalidateOnBorrowSuccess } from '../loan/useBorrowMutation';

type Options = {
    readonly borrowOptions: { productId: string };
    readonly subscribeOptions: ApiCreateOrUpdateSubscriptionBody;
};
export const useBorrowSubscribeMutation = () => {
    const queryClient = useQueryClient();
    const { siteId, language } = useSiteParams();

    return useMutation({
        mutationFn: async ({ borrowOptions, subscribeOptions }: Options) => {
            const [borrowResult, subscribeResult] = await Promise.allSettled([
                loanProduct(browserClient, { ...borrowOptions, siteId, language }),
                createOrUpdateSubscription(browserClient, { siteId, language }, subscribeOptions)
            ]);
            return { borrowResult, subscribeResult };
        },
        onSuccess: ({ borrowResult, subscribeResult }) => {
            const isBorrowOk = borrowResult.status === 'fulfilled';
            const isSubscribeOk = subscribeResult.status === 'fulfilled';

            Promise.all([
                isBorrowOk ? invalidateOnBorrowSuccess(queryClient) : null,
                isSubscribeOk ? invalidateOnSubscribeSuccess(queryClient) : null
            ]);
        }
    });
};
