import { NextPage } from 'next';
import Link from 'next/link';
import { useEffect } from 'react';

import { ErrorPage } from '../components/ErrorPage';

const PAGE_AUTO_RELOAD_TIME = 60_000; // ms

const NextjsErrorPage: NextPage = () => {
    useEffect(() => {
        const redirectTimeout = setTimeout(() => reload(), PAGE_AUTO_RELOAD_TIME);
        return () => clearTimeout(redirectTimeout);
    });

    const reload = () => {
        document.location.reload();
    };

    return (
        <ErrorPage>
            <p>Something went wrong.</p>
            <p>
                Try to{' '}
                <Link
                    href="#"
                    onClick={event => {
                        event.preventDefault();
                        reload();
                    }}
                    className="f-green text-underline"
                >
                    refresh this page
                </Link>{' '}
                or feel free to contact our{' '}
                <Link href="mailto:borrowbox@bolinda.com" className="f-green text-underline">
                    support team
                </Link>{' '}
                if the problem persists.
            </p>
        </ErrorPage>
    );
};

export default NextjsErrorPage;
