import { ApiClient } from '../../setup/axios';
import { SiteParams } from '../index';

export type ApiCreateOrUpdateSubscriptionBody = {
    readonly rootProductId: string;
    readonly automaticallyBorrow?: boolean;
    readonly notifications?: boolean;
};

export function createOrUpdateSubscription(
    client: ApiClient,
    params: SiteParams,
    body: ApiCreateOrUpdateSubscriptionBody
) {
    return client.put('/api/v1/subscriptions', body, params).then(res => res.data);
}
