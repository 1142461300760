import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { reserveProduct } from './apiReserveLoan';
import { invalidateLoanDetails } from './useLoanDetails';
import { invalidateCredits } from '../../context/CreditsContext';
import { invalidateLoanStatus } from '../../context/LoanStatusContext';
import { ApiLoanResponse, LoanErrorResponse } from '../../domain/loan';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import LoggingUtils from '../../utils/logging/LoggingUtils';

export const useReserveMutation = (): UseMutationResult<
    ApiLoanResponse,
    AxiosError | LoanErrorResponse,
    { productId: string },
    unknown
> => {
    const client = useQueryClient();
    const { siteId, language } = useSiteParams();

    return useMutation({
        mutationFn: ({ productId }) => reserveProduct(browserClient, { productId, siteId, language }),
        onSuccess: () => {
            return Promise.all([
                invalidateCredits(client), // displayed in the header
                invalidateLoanStatus(client), // not prefetched in SSR, needed in the borrow/reserve-modals
                invalidateLoanDetails(client) //reserves are also possible on the loanDetailsPage
            ]);
        },
        onError: (error, productId) => {
            LoggingUtils.logErrorWithPayload(`Cannot reserve product`, { error, productId });
        }
    });
};
