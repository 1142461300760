import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { extendSession } from './apiSessionExtension';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { useUserLoggedIn } from '../../hooks/getters/useUserLoggedIn';
import { useInterval } from '../../hooks/utils/useInterval';
import { browserClient } from '../../setup/axios';

// 5 minutes
const SESSION_EXTENSION_INTERVAL_IN_MS = 5 * 60 * 1000;

type UseSessionExtension = {
    readonly success: boolean;
};

export function useSessionExtension(): UseSessionExtension {
    const { siteId, language } = useSiteParams();

    const { mutate, isSuccess } = useMutation({
        mutationFn: () => extendSession(browserClient, { siteId, language })
    });

    const isUserLoggedIn = useUserLoggedIn();

    const extendIfLoggedIn = useCallback(() => {
        if (isUserLoggedIn) {
            mutate();
        }
    }, [isUserLoggedIn, mutate]);

    useInterval({ callback: extendIfLoggedIn, delay: SESSION_EXTENSION_INTERVAL_IN_MS });

    return {
        success: isSuccess
    };
}
