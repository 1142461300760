import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { ProductGroupProductsParams } from '../../api/product-group/useProductGroupProducts';
import { ApiProductsRequestFilters } from '../../api/products';
import { SearchField, SearchOperation, SortOrder } from '../../api/searchProducts';
import { standardizeFilterParams } from '../../components/filter/standardizeFilters';
import { AgeCategoryType } from '../../domain/ageCategory';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { isEmpty, isPresent } from '../../utils/objectChecks';
import { getOffsetForPage, getPageForOffset } from '../../utils/pagination';
import { parseQuery, stringifyQuery } from '../../utils/routes/queryString';
import { filterNonProductListQueryParams, QueryParam } from '../../utils/routes/routing';
import { stripAnchor } from '../../utils/string';
import { useRedirect } from '../events/useRedirect';

export const DEFAULT_PAGINATION_LIMIT = 60;

enum UrlParam {
    availability = 'available',
    offset = 'offset',
    limit = 'limit',
    sort = 'sort',
    query = 'q',
    // we need some prefix here, since there filters with the same name
    title = 'q-title',
    genre = 'q-genre',
    series = 'q-series',
    author = 'q-author',
    narrator = 'q-narrator',
    isbn = 'q-isbn'
}

export const PRODUCT_LIST_QUERY_PARAMS = Object.values(UrlParam);

/**
 * Common parameters for product list pages.
 */
export interface ProductListParams {
    /**
     * Indicates whether products that are available should be fetched only.
     */
    readonly availableOnly: boolean;

    /**
     * The page of products that should be fetched, starting from 1.
     *
     * @deprecated Use `offset` instead where possible.
     */
    readonly currentPage: number;

    /**
     * The size of a single page of products (number of products per page).
     *
     * @deprecated Use `limit` instead where possible.
     */
    readonly pageSize: number;

    /**
     * The offset of the first product which is fetched within the list of all
     * products that could be fetched.
     */
    readonly offset: number;

    /**
     * The number of products that should be fetched starting with the product
     * defined by `offset`.
     */
    readonly limit: number;

    /**
     * The filters that should be applied when products are fetched.
     */
    readonly filters: ProductFilterParams;

    /**
     * The sort order that should be applied when products are fetched.
     */
    readonly currentOrder?: SortOrder;

    /**
     * The search terms that should be applied when products are fetched.
     */
    readonly search: ProductsSearchParams;
}

/**
 * Represents the filter parameters for a product list page.
 */
export interface ProductFilterParams {
    /**
     * The filters (represented by their `name`) mapped to their selected
     * options.
     */
    readonly [name: string]: ReadonlyArray<string>;
}

/**
 * Represents the search parameters for a product list page.
 */
export interface ProductsSearchParams {
    /**
     * The search operation that should be used when fetching products.
     */
    readonly operation: SearchOperation;

    /**
     * The search terms that should be used when fetching products.
     */
    readonly terms: ProductsSearchFields;
}

/**
 * Represents the search terms for a product list page.
 *
 * The search terms are user entered text mapped to the fields the user want's
 * to search by.
 */
export type ProductsSearchFields = {
    readonly [F in SearchField]?: string;
};

type ReplacementMapping = [internal: string, friendly: string];

const USER_FRIENDLY_URL_FILTER_NAME_MAP: ReadonlyArray<ReplacementMapping> = [
    ['loanFormat', 'format'],
    ['releaseDate', 'released'],
    ['newlyAdded', 'added']
];

const USER_FRIENDLY_URL_FILTER_PARAM_MAP: { [name: string]: ReadonlyArray<ReplacementMapping> } = {
    ageCategory: [
        ['Adult', 'adult'],
        ['Young Adult', 'youngAdult'],
        ['Children', 'children']
    ],
    ageGroup: [
        ['[0,2]', '0-2'],
        ['[3,4]', '3-4'],
        ['[5,6]', '5-6'],
        ['[7,9]', '7-9'],
        ['[10,11]', '10-11'],
        ['[12,13]', '12-13'],
        ['[14,17]', '14-17']
    ],
    duration: [
        ['(*,1800)', '30m'],
        ['(*,3600)', '1h'],
        ['(*,7200)', '2h'],
        ['(*,18000)', '5h'],
        ['(*,36000)', '10h'],
        ['(*,72000)', '20h'],
        ['(*,108000)', '30h'],
        ['[108000,*)', '30h-plus']
    ],
    loanFormat: [
        ['eMagazines', 'ePress'], // TODO: should be removed as soon as loanFormat `eMagazines` is renamed to `ePress`
        ['E_MAGAZINE', 'eMagazines'],
        ['E_NEWS', 'eNews']
    ],
    newlyAdded: [
        ['[-7d,0]', '7d'],
        ['[-14d,0]', '2w'],
        ['[-1M,0]', '1M'],
        ['[-3M,0]', '3M'],
        ['[-6M,0]', '6M'],
        ['[-1y,0]', '1y'],
        ['[-2y,0]', '2y'],
        ['(*,-2y]', '2y-plus']
    ],
    pages: [
        ['(*,25)', '25'],
        ['(*,50)', '50'],
        ['(*,100)', '100'],
        ['(*,250)', '250'],
        ['(*,500)', '500'],
        ['(*,750)', '750'],
        ['[750,*)', '750-plus']
    ],
    productionFormat: [
        ['Abridged', 'abridged'],
        ['Unabridged', 'unabridged']
    ],
    releaseDate: [
        ['[-14d,0)', '2w'],
        ['[-1M,0)', '1M'],
        ['[-3M,0)', '3M'],
        ['[-6M,0)', '6M'],
        ['[-1y,0)', '1y'],
        ['[-2y,0)', '2y'],
        ['(*,-2y)', '2y-plus']
    ]
};

interface UseProductListParams {
    params: ProductListParams;
    getPageLink(page: number): string;
    onParamsChange(newParams: ProductListParams): Promise<void>;
}

export function useProductListParams(): UseProductListParams {
    const router = useRouter();
    const redirectTo = useRedirect();

    const [path, query = ''] = router.asPath.split('?');
    const params = useMemo(() => decodeProductListQueryParams(query), [query]);

    const onParamsChange = useCallback(
        async (newParams: ProductListParams) => {
            let page = shouldResetPagination(params, newParams) ? 1 : params.currentPage;
            // Set the route shallow so the SSR does not kick in
            await redirectTo(createUri(path, newParams, page), true);
        },
        [params, redirectTo, path]
    );

    const getPageLink = useCallback((currentPage: number) => createUri(path, params, currentPage), [params, path]);

    return useMemo(
        () => ({
            params,
            getPageLink,
            onParamsChange
        }),
        [params, getPageLink, onParamsChange]
    );
}

function shouldResetPagination(memoizedParams: ProductListParams, newParams: ProductListParams): boolean {
    return (
        isEffectiveFilterChange(memoizedParams.filters, newParams.filters) ||
        isEffectiveSortOrderChange(memoizedParams.currentOrder, newParams.currentOrder) ||
        isEffectiveAllAvailableToggleChange(memoizedParams.availableOnly, newParams.availableOnly)
    );
}

function isEffectiveFilterChange(oldFilters: ProductFilterParams, newFilters: ProductFilterParams) {
    const effectiveOldFilters = pickBy(oldFilters, isPresent);
    const effectiveNewFilters = pickBy(newFilters, isPresent);
    return !isEqual(effectiveOldFilters, effectiveNewFilters);
}

function isEffectiveSortOrderChange(oldOrder?: SortOrder, newOrder?: SortOrder): boolean {
    return !isEqual(oldOrder, newOrder);
}

function isEffectiveAllAvailableToggleChange(oldAvailableOnly?: boolean, newAvailableOnly?: boolean): boolean {
    return !isEqual(oldAvailableOnly, newAvailableOnly);
}

/**
 * Rename internal filter parameter names into a user-friendly format and back.
 *
 * - internal name `loanFormat` to URL `...?format=eNews` and
 * - URL params from `...?format=eNews` to internal `loanFormat`.
 *
 * @param name Name of a Product Filter Parameter.
 * @param [direction='friendly'] Omit or use `friendly` to map into user-friendly format for a URL.
 *   Use `internal` to map from user-friendly format into internal and backend representation.
 */
export const mapFilterNameUserFriendly = (name: string, direction: 'friendly' | 'internal' = 'friendly') => {
    // non-destructively friendly/internal sort according to `direction` flag
    const applyDirection = (list: ReplacementMapping) => (direction !== 'internal' ? list : [...list].reverse());

    const mappingsSorted = USER_FRIENDLY_URL_FILTER_NAME_MAP.map(applyDirection);
    const [, replacement] = mappingsSorted.find(([target]) => target === name) ?? [];

    return replacement ?? name;
};

/**
 * Rename internal filter params into a user-friendly format and back.
 *
 * - internal loan format `E_NEWS` to URL `...?loanFormat=eNews` and
 * - URL params from `...?loanFormat=eNews` to internal `E_NEWS`.
 *
 * @param filters A list of Product Filter Parameters.
 * @param [direction='friendly'] Omit or use `friendly` to map into user-friendly format for a URL.
 *   Use `internal` to map from user-friendly format into internal and backend representation.
 */
export const mapFilterParamsUserFriendly = (
    filters: ProductFilterParams,
    direction: 'friendly' | 'internal' = 'friendly'
) => {
    // non-destructively friendly/internal sort according to `direction` flag
    const applyDirection = (list: ReplacementMapping) => (direction !== 'internal' ? list : [...list].reverse());

    const mappedFilters: Array<ProductFilterParams> = Object.entries(filters).map(([filterName, filterList]) => {
        // get filter name for i.e. `loanFormat` (`format` maps to `loanFormat`, `loanFormat` maps to `format`)
        const name = mapFilterNameUserFriendly(filterName, direction);

        return {
            [name]: filterList.map(filterValue => {
                // get "internal" filter name for i.e. `loanFormat` as the keys in USER_FRIENDLY_URL_FILTER_PARAM_MAP
                // are static (`format` maps to `loanFormat`, but `loanFormat` stays `loanFormat`)
                const name = mapFilterNameUserFriendly(filterName, 'internal');

                // mappings for i.e. `loanFormat`
                const mappings = USER_FRIENDLY_URL_FILTER_PARAM_MAP[name];

                if (isEmpty(mappings)) return filterValue;

                // sort mappings so that `[before, after]` items are in the correct friendly/internal replacement order
                const mappingsSorted = [...mappings].map(applyDirection);

                // find a replacement for the current `filterValue`
                const [, replacement] = mappingsSorted.find(([target]) => target === filterValue) ?? [];

                return replacement ?? filterValue; // use replacement if there is one, otherwise keep original value
            })
        };
    });

    // merge list of filter objects into single filter object, i.e.:
    // [ { format: ['ePress'] }, { ageCategory: ['Adult'] }]  =>  { format: ['ePress'], ageCategory: ['Adult'] }
    return Object.assign({}, ...mappedFilters);
};

function encodeQueryParams({
    filters,
    currentPage,
    pageSize,
    availableOnly,
    currentOrder,
    search
}: ProductListParams): string {
    const normalizedFilters = mapFilterParamsUserFriendly(filters);

    const { terms } = search;

    return stringifyQuery({
        ...normalizedFilters,
        [UrlParam.offset]: currentPage > 1 ? getOffsetForPage(currentPage, pageSize) : undefined,
        [UrlParam.limit]: pageSize === DEFAULT_PAGINATION_LIMIT ? null : pageSize,
        [UrlParam.availability]: availableOnly ? true : undefined,
        [UrlParam.sort]: currentOrder,
        [UrlParam.query]: terms[SearchField.All],
        [UrlParam.title]: terms[SearchField.Title],
        [UrlParam.series]: terms[SearchField.Series],
        [UrlParam.genre]: terms[SearchField.Genre],
        [UrlParam.author]: terms[SearchField.Author],
        [UrlParam.narrator]: terms[SearchField.Narrator],
        [UrlParam.isbn]: terms[SearchField.Isbn]
    });
}

export function decodeProductListQueryParams(queryRaw: string): ProductListParams {
    const query = stripAnchor(queryRaw);

    const {
        [UrlParam.offset]: offsetParam,
        [UrlParam.limit]: limitParam,
        [UrlParam.availability]: availableOnly,
        [UrlParam.sort]: sort,
        [UrlParam.query]: q,
        [UrlParam.title]: title,
        [UrlParam.series]: series,
        [UrlParam.genre]: genre,
        [UrlParam.author]: author,
        [UrlParam.narrator]: narrator,
        [UrlParam.isbn]: isbn,
        ...filters
    } = filterNonProductListQueryParams(parseQuery(query));

    const offset = parsePaginationQueryParam(offsetParam);
    const limit = parsePaginationQueryParam(limitParam) || DEFAULT_PAGINATION_LIMIT;
    const searchTerms = {
        [SearchField.All]: parseSearchQueryParam(q),
        [SearchField.Title]: parseSearchQueryParam(title),
        [SearchField.Series]: parseSearchQueryParam(series),
        [SearchField.Genre]: parseSearchQueryParam(genre),
        [SearchField.Author]: parseSearchQueryParam(author),
        [SearchField.Narrator]: parseSearchQueryParam(narrator),
        [SearchField.Isbn]: parseSearchQueryParam(isbn)
    };

    const normalizedFilters = mapFilterParamsUserFriendly(mapValues(filters, parseFilterQueryParams), 'internal');

    return {
        availableOnly: !!availableOnly,
        currentPage: getPageForOffset(offset, limit),
        pageSize: limit,
        offset,
        limit,
        currentOrder: parseSortOrderQueryParam(sort),
        filters: normalizedFilters,
        search: {
            operation: getSearchOperation(searchTerms),
            terms: searchTerms
        }
    };
}

type GetProductGroupFetchParams = {
    params: ProductListParams | ProductGroupProductsParams;
    ageCategory?: AgeCategoryType;
    loanFormat?: LoanFormatType;
};

export const getProductGroupFetchParams = ({ params, ageCategory, loanFormat }: GetProductGroupFetchParams) => {
    return {
        filters: params.filters,
        loanFormat,
        ageCategory,
        offset: params.offset,
        limit: params.limit,
        sortBy: 'currentOrder' in params ? params.currentOrder : undefined,
        availableOnly: params.availableOnly
    };
};

const getSearchOperation = (terms: ProductsSearchFields): SearchOperation => {
    if (terms[SearchField.All]) {
        return SearchOperation.Or;
    }
    const nrOfNonEmptyTerms = Object.values(SearchField)
        .filter(it => it !== SearchField.All)
        .reduce((acc, curr) => acc + (!!terms[curr] ? 1 : 0), 0);
    return nrOfNonEmptyTerms > 1 ? SearchOperation.And : SearchOperation.Or;
};

const parseSearchQueryParam = (value: QueryParam): string | undefined => {
    return parseNonFilterQueryParam(value);
};

const parsePaginationQueryParam = (value: QueryParam): number => {
    return parseUInt(parseNonFilterQueryParam(value));
};

const parseSortOrderQueryParam = (value: QueryParam): SortOrder => {
    return parseNonFilterQueryParam(value) as SortOrder;
};

function parseNonFilterQueryParam(value: QueryParam): string | undefined {
    if (Array.isArray(value)) {
        // In our filter-query-params we encode arrays as x|y|z.
        // Such a value could also be supplied in non-filter-query-params (e.g. title-search), since these are
        // user input (search) and/or can be manipulated directly in the url by the user too.
        // However, we decided to only work with the first value in this case, e.g. if the user searches for
        // "harry|potter" (for whatever reason), we only work with "harry" and disregard the rest.
        return parseNonFilterQueryParam(compact(value)[0]);
    }
    if (typeof value !== 'string') {
        return undefined;
    }
    const trimmed = value.trim();
    return trimmed.length ? trimmed : undefined;
}

function parseFilterQueryParams(value: QueryParam): Array<string> {
    if (typeof value === 'string' && value !== '') {
        return [value];
    } else if (Array.isArray(value)) {
        return value as Array<string>;
    }
    return [];
}

export const createUri = (pathName: string, params: ProductListParams, currentPage: number): string => {
    const newQueryParams = encodeQueryParams({ ...params, currentPage });
    return `${pathName}${newQueryParams.length ? '?' : ''}${newQueryParams}`;
};

const parseUInt = (value: string | null | undefined): number => {
    return Math.max(parseInt(value || '', 10) || 0, 0);
};

export function toProductRequestFilters(filters: ProductFilterParams): ApiProductsRequestFilters {
    return standardizeFilterParams(filters) as ApiProductsRequestFilters;
}
