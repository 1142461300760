import isEqual from 'lodash/isEqual';
import { LRUCache } from 'lru-cache';

export class LongLifetimeCache<T extends {}> extends LRUCache<string, T> {
    /**
     * Adaption of the LRU Cache that allows to check if a cache entry is fresh and to update it.
     *
     * Following options are set which changes the default behaviour of the LRU Cache:
     * - allowStale: true (https://github.com/isaacs/node-lru-cache#allowstale)
     * - noDeleteOnStaleGet: true (https://github.com/isaacs/node-lru-cache#nodeleteonstaleget)
     *
     * Therefor the cache is allowed to return stale entries while a new entry is being fetched and stale entries are
     * not deleted when they are accessed. This means that cache entries have a __longer lifetime__ than the given TTL.
     *
     * @param max number of entries that can be stored in the cache
     * @param ttl time to live in milliseconds
     */
    constructor(max: number, ttl: number) {
        super({
            max,
            ttl,
            allowStale: true,
            noDeleteOnStaleGet: true
        });
    }

    /**
     * Returns `true` if the remaining TTL of the cache entry is greater than 0.
     *
     * @param key key of the cache entry
     */
    public isFresh(key: string): boolean {
        return this.getRemainingTTL(key) > 0;
    }

    /**
     * Updates the cache entry with the given key.
     *
     * If the new value is the same as the cached value, the cached entry will be set again to update the TTL.
     * Otherwise, the new value will replace the cached value and the TTL will be reset.
     *
     * @param key key of the cache entry
     * @param newValue new value of the cache entry
     */
    public update(key: string, newValue: T): void {
        const cachedValue = this.get(key);
        const hasNoUpdate = isEqual(newValue, cachedValue);

        this.set(key, cachedValue && hasNoUpdate ? cachedValue : newValue);
    }
}
