import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import { AgeCategoryType } from '../domain/ageCategory';
import { LoanFormatType } from '../utils/domain/loanFormat';

type Context = {
    readonly ageCategory?: AgeCategoryType;
    readonly loanFormat?: LoanFormatType;
};

const initialValue: Context = {};

const ScopeContext = createContext<Context>(initialValue);

export function useScopeContext(): Context {
    return useContext(ScopeContext);
}

type ProviderProps = PropsWithChildren<{
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
}>;

export const ScopeContextProvider: FC<ProviderProps> = ({ loanFormat, ageCategory, children }) => {
    const value = useMemo(() => ({ loanFormat, ageCategory }), [loanFormat, ageCategory]);
    return <ScopeContext.Provider value={value}>{children}</ScopeContext.Provider>;
};
