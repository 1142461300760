import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';

import { logout } from './apiLogout';
import { usePatronInfoUpdate } from '../../context/AuthenticationContext';
import { removeCredits } from '../../context/CreditsContext';
import { removeLoanStatus } from '../../context/LoanStatusContext';
import { useRedirect } from '../../hooks/events/useRedirect';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { browserClient } from '../../setup/axios';
import { UseMutationResult } from '../../setup/react-query/queryTypes';
import { HOME_PATH, LOGIN_PATH } from '../../utils/routes/paths';

type Options = {
    readonly fetchFunc?: typeof logout;
};

export const useLogoutMutation = ({ fetchFunc = logout }: Options = {}): UseMutationResult<unknown> => {
    const router = useRouter();
    const redirectTo = useRedirect();
    const { siteId, language } = useSiteParams();
    const client = useQueryClient();
    const updatePatronInfo = usePatronInfoUpdate();

    return useMutation({
        mutationFn: () => fetchFunc(browserClient, { siteId, language }),
        onSuccess: async () => {
            await redirectTo(HOME_PATH);
            updatePatronInfo(undefined);
            removeCredits(client);
            removeLoanStatus(client);
        },
        onError: async (error: AxiosError) => {
            if (error?.response?.status === 400) {
                await redirectTo(`${LOGIN_PATH}?redirectTo=${router.asPath}`);
            }
        }
    });
};
