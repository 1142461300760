import { FC, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useDebouncedCallback } from 'use-debounce';

import { Icon } from './Icon';

type ValidatedFieldProps = {
    readonly name: string;
    readonly id: string;
    readonly label: string;
    readonly helpText?: string;
    readonly type: 'text' | 'password';
    readonly autoFocus?: boolean;
    readonly initialValue?: string;
    readonly disabled?: boolean;
    readonly convertEmptyValuesToUndefined?: boolean;
};

export const ValidatedField: FC<ValidatedFieldProps> = ({
    name,
    id,
    label,
    helpText,
    type,
    autoFocus,
    initialValue,
    disabled,
    convertEmptyValuesToUndefined = true
}) => {
    const [validationTimer, setValidationTimer] = useState(false);

    function timeoutHandler() {
        setValidationTimer(false);
        debouncedCallback();
    }

    const debouncedCallback = useDebouncedCallback(() => {
        setValidationTimer(true);
    }, 3000);

    // https://final-form.org/docs/react-final-form/types/FieldProps#parse
    // The default behaviour of react-final-form is to convert empty values ` ` to `undefined`.
    // To overwrite this behaviour it is suggested to pass an identity-function as parse-function.
    const parseFieldValue = useMemo(
        () => (convertEmptyValuesToUndefined ? undefined : (v: string) => v),
        [convertEmptyValuesToUndefined]
    );

    return (
        <Field name={name} initialValue={initialValue} parse={parseFieldValue}>
            {({ input, meta }) => (
                <div
                    className="input-group"
                    data-state={meta.error && (meta.touched || validationTimer) ? 'error' : ''}
                >
                    <div className="mini-placeholder-input" data-state={input.value ? 'filled' : ''}>
                        <label htmlFor={id}>{label}</label>
                        <input
                            type={type}
                            {...input}
                            onChange={event => {
                                timeoutHandler();
                                input.onChange(event);
                            }}
                            placeholder={label}
                            id={id}
                            disabled={disabled}
                            autoFocus={!!autoFocus}
                            autoComplete="on"
                            aria-invalid={meta.error ? 'true' : undefined}
                            aria-describedby={meta.error ? 'error-message' : undefined}
                        />
                    </div>
                    {meta.error && (meta.touched || validationTimer) ? (
                        <div className="component error-message">
                            <p>
                                <Icon icon={'error-circle'} />
                                {meta.error}
                            </p>
                        </div>
                    ) : null}
                    <p>{helpText}</p>
                </div>
            )}
        </Field>
    );
};
