import { setContext } from '@sentry/react';
import { GetServerSideProps, NextPage } from 'next';
import { useEffect } from 'react';

import { ApiPatronInfo } from '../../api/apiFetchPatronInfo';
import { ApiContextLibrary } from '../../api/apiLibraryContext';
import { initOidcLogin } from '../../api/authentication/apiOidcInit';
import { BasicLayout } from '../../components/layout/BasicLayout';
import { PageTitleShareMeta } from '../../components/PageTitleShareMeta';
import { usePatronInfoUpdate } from '../../context/AuthenticationContext';
import { useCredits } from '../../context/CreditsContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { useRedirect } from '../../hooks/events/useRedirect';
import { PageTitleFormats } from '../../hooks/getters/usePageTitle';
import { useUserLoggedIn } from '../../hooks/getters/useUserLoggedIn';
import { ExternalLogin } from '../../page-components/login/ExternalLogin';
import { InternalLogin } from '../../page-components/login/InternalLogin';
import { SiteSelector } from '../../page-components/login/SiteSelector';
import { initSSR } from '../../setup/ssr';
import { appendSetCookieHeader, rewriteCookieDomainForDevelopment } from '../../utils/cookies';
import LoggingUtils from '../../utils/logging/LoggingUtils';
import { isSSR } from '../../utils/nextjs/ssr';
import { isEmpty, isPresent } from '../../utils/objectChecks';
import { HOME_PATH, LOGIN_PATH, REGISTER_PATH } from '../../utils/routes/paths';

type LoginRoutePathOptions = {
    readonly redirectTo?: string | null;
};

export function loginRoutePath({ redirectTo }: LoginRoutePathOptions) {
    const redirect = isPresent(redirectTo) && redirectTo !== '/' ? `?redirectTo=${encodeURIComponent(redirectTo)}` : '';
    return `${LOGIN_PATH}${redirect}`;
}

type Props =
    | {
          readonly patronInfo?: never;
          readonly redirectTo?: never;
          readonly siteContext?: never;
      }
    | {
          readonly patronInfo: ApiPatronInfo;
          readonly redirectTo: string;
          readonly siteContext?: never;
      }
    | {
          readonly patronInfo?: never;
          readonly redirectTo: string;
          readonly siteContext: ApiContextLibrary;
      };

const Login: NextPage<Props> = ({ patronInfo, redirectTo, siteContext }) => {
    const updatePatronInfo = usePatronInfoUpdate();

    if (patronInfo) updatePatronInfo(patronInfo); // sync given patronInfo into client context

    const isUserLoggedIn = useUserLoggedIn();
    const { credits } = useCredits();
    const doRedirectTo = useRedirect();

    const { login } = useLibraryContext();
    const userManagementType = login?.userManagementType;
    const triggerRedirect = isUserLoggedIn && redirectTo && !isEmpty(credits);

    useEffect(() => {
        if (triggerRedirect) void doRedirectTo(redirectTo);
    }, [doRedirectTo, redirectTo, triggerRedirect]);

    // Add context to Sentry errors happen on Login page
    setContext(`Login [${isSSR() ? 'SSR' : 'CSR'}]`, {
        userManagementType,
        redirectTo,
        isUserLoggedIn
    });

    if (triggerRedirect) return null;

    if (siteContext) return <SiteSelector redirectTo={redirectTo} siteContext={siteContext} />;

    switch (userManagementType) {
        case 'INTERNAL':
            return (
                <BasicLayout className="single-form-page">
                    <PageTitleShareMeta page={PageTitleFormats.login} />
                    <InternalLogin />
                </BasicLayout>
            );
        case 'SAML':
        case 'EZPROXY':
            return (
                <BasicLayout>
                    <PageTitleShareMeta page={PageTitleFormats.login} />
                    <ExternalLogin />
                </BasicLayout>
            );
        case 'OIDC':
            return null;
        default:
            // TODO: Implement error handling
            return (
                <BasicLayout>
                    <div>Unknown login type "{userManagementType}"</div>
                </BasicLayout>
            );
    }
};

export const getServerSideProps: GetServerSideProps<Props> = async context => {
    try {
        const { patronInfo, siteContext, siteParams, ssrClient } = await initSSR(context, { fetchPatronInfo: true });
        const redirectTo = typeof context.query.redirectTo === 'string' ? context.query.redirectTo.trim() : HOME_PATH;

        if (siteContext.networkSite) {
            return { props: { siteContext, redirectTo } };
        }

        if (patronInfo) {
            const destination = patronInfo.fullyRegistered ? redirectTo : REGISTER_PATH;
            return { props: { patronInfo, redirectTo: destination } };
        }

        if (siteContext.userManagementType === 'OIDC') {
            const { redirectUri, setCookieHeader } = await initOidcLogin(ssrClient, {
                ...siteParams,
                frontendRedirectUri: redirectTo
            });
            appendSetCookieHeader(context.res, rewriteCookieDomainForDevelopment(context.req, setCookieHeader));
            return { redirect: { destination: redirectUri, permanent: false } };
        }

        return { props: {} };
    } catch (error) {
        LoggingUtils.logSSRError('failed to fetch site context on login page', error);
        return { redirect: { destination: HOME_PATH, permanent: false } };
    }
};

export default Login;
