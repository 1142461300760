import { FC, PropsWithChildren, ReactNode } from 'react';

type Props = Partial<PropsWithChildren> & {
    readonly title: string;
    readonly subtitle?: string | ReactNode;
};

export const StatusMessage: FC<Props> = ({ title, subtitle, children }) => {
    return (
        <div className="component status-container">
            <h1>{title}</h1>
            {subtitle ? <p>{subtitle}</p> : null}
            <div>{children}</div>
        </div>
    );
};
