import { ChangeEvent, DependencyList, FormEvent, useCallback, useId, useMemo, useState } from 'react';

import { useDeepMemo } from './useDeepMemo';
import { ValidationCallback } from '../../utils/validation';

/**
 * Options for the useFormInput hook.
 */
interface UseFormInputOptions<T> {
    /**
     * A function that extracts a new value from a React.FormEvent.
     */
    extract(e: FormEvent<HTMLInputElement>): T;

    /**
     * An optional validation function.
     */
    validate?: ValidationCallback<T>;
}

/**
 * Returned by the useFormInput hook.
 */
interface UseFormInput<T> {
    /**
     * The value managed by the hook.
     */
    readonly value: T;

    /**
     * An onChange handler for input.
     */
    onChange(e: ChangeEvent<HTMLInputElement>): void;

    /**
     * Indicates whether the input is valid according to the validation.
     */
    readonly valid: boolean;

    /**
     * The validation error message as it has been returned from the validate callback.
     */
    readonly validationError: string | undefined;

    /**
     * Indicates whether the value is pristine.
     */
    readonly pristine: boolean;

    /**
     * A unique ID that can be used on the corresponding input.
     */
    readonly inputId: string;

    /**
     * Resets the input to the initial value.
     */
    reset(): void;
}

function useFormInput<T>(
    initialValue: T,
    { validate, extract }: UseFormInputOptions<T>,
    deps?: DependencyList
): UseFormInput<T> {
    const [value, setValue] = useState<T>(initialValue);
    const [pristine, setPristine] = useState(true);

    const inputId = useId();

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setValue(extract(event));
            setPristine(false);
        },
        [extract]
    );

    const memoizedDeps = useDeepMemo(() => deps, [deps]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const valid = useMemo(() => (validate ? validate(value) === true : true), [value, validate, memoizedDeps]);
    const validationError = useMemo(() => {
        if (validate) {
            const validationResult = validate(value);

            if (typeof validationResult === 'string') {
                return validationResult;
            }
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, validate, memoizedDeps]);

    const reset = useCallback(() => {
        setValue(initialValue);
    }, [initialValue]);

    return useMemo(
        () => ({
            value,
            onChange,
            valid,
            validationError,
            pristine,
            inputId,
            reset
        }),
        [value, onChange, valid, validationError, pristine, inputId, reset]
    );
}

interface UseTextInputOptions {
    validate?: ValidationCallback<string>;
}

export function useTextInput(initialValue: string): UseFormInput<string>;
// eslint-disable-next-line no-redeclare
export function useTextInput(initialValue: string, options: UseTextInputOptions): UseFormInput<string>;
// eslint-disable-next-line no-redeclare
export function useTextInput(initialValue: string, deps: DependencyList): UseFormInput<string>;
// eslint-disable-next-line no-redeclare
export function useTextInput(
    initialValue: string,
    options: UseTextInputOptions,
    deps: DependencyList
): UseFormInput<string>;

// eslint-disable-next-line no-redeclare
export function useTextInput(
    initialValue: string,
    secondParam?: UseTextInputOptions | DependencyList,
    thirdParam?: DependencyList
): UseFormInput<string> {
    const options: UseTextInputOptions = Array.isArray(secondParam) ? {} : (secondParam as UseTextInputOptions) ?? {};
    const deps = Array.isArray(secondParam) ? secondParam : thirdParam;

    return useFormInput(
        initialValue,
        {
            extract: (e: ChangeEvent<HTMLInputElement>) => e.target.value,
            ...options
        },
        deps
    );
}

export function useCheckboxInput(initialValue: boolean): UseFormInput<boolean> {
    return useFormInput(initialValue, {
        extract: (e: ChangeEvent<HTMLInputElement>) => e.target.checked
    });
}
