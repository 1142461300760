// We are importing from @sentry/node here, so it works when rendered on the server. When this import is done on the
// client side, the @sentry/node module is replaced with an alias to @sentry/browser (see next.config.js)
import { RewriteFrames } from '@sentry/integrations';
import { init } from '@sentry/node';
import { StackFrame } from '@sentry/react';
import { Event, Options as SentryInitOptions } from '@sentry/types';
import get from 'lodash/get';
import getNextConfig from 'next/config';

import { isDeprecatedBrowser } from '../utils/browserslist/isDeprecatedBrowser';
import { getReleaseVersion } from '../utils/constants';
import LoggingUtils from '../utils/logging/LoggingUtils';
import { isSSR } from '../utils/nextjs/ssr';
import { redactEzProxySsoToken } from '../utils/scrubbing';

export function initSentry() {
    const enabled = isSentryEnabled();
    const dsn = getSentryDSN();
    const release = getReleaseVersion();
    const environment = getSentryEnvironment();

    const {
        serverRuntimeConfig: {
            // The root dir is the absolute path to the project directory, taken from the machine where the current
            // release has been built. For example /home/foobar/projects/patron-point-ui. It is injected at build time
            // (see next.config.js).
            rootDir
        }
    } = getNextConfig();

    if (!environment) {
        LoggingUtils.logSSRWarning('No Sentry environment setting found, using "unknown" instead');
    }

    if (enabled && !dsn) {
        LoggingUtils.logSSRWarning('No Sentry DSN found, skipping Sentry initialization');
        return;
    }

    init({
        enabled,
        dsn,
        environment: environment ?? 'unknown',
        release,
        beforeSend,
        integrations: [
            new RewriteFrames({
                iteratee: frame => {
                    // remove local file path prefixes (which are written at build time)
                    frame.filename = frame.filename?.replace(`${rootDir}/_next`, 'app:///_next');
                    return frame;
                }
            })
        ],
        ignoreErrors: [
            // Seems to be caused by a browser extension messing with the userAgent:
            // https://stackoverflow.com/q/66033349
            'can\'t redefine non-configurable property "userAgent"',
            // Might be related to Instana, but we cannot reproduce this error:
            // https://sentry.io/organizations/bolinda/issues/2353210718/
            'Non-Error promise rejection captured with value:',
            // Denied access is not an error in most cases
            'Request failed with status code 401'
        ].concat(
            environment === 'stage'
                ? [
                      // Don't report eMagazine has no latest available issue warnings in stage
                      'ActionableProduct: eMagazine has no latestAvailableIssue'
                  ]
                : []
        )
    });
}

const isExtension = (frame: StackFrame) => {
    const EXTENSION_PREFIXES = ['chrome-extension://', 'safari-extension://', 'safari-web-extension://', '://hidden/'];
    return EXTENSION_PREFIXES.some(extensionPrefix => frame.filename?.startsWith(extensionPrefix));
};

function isExtensionError(event: Event) {
    const frames = get(event, 'exception.values[0].stacktrace.frames', []) as Array<StackFrame>;
    return frames.some(isExtension);
}

function isRenderAnnotationsError(event: Event) {
    const breadcrumb = event.breadcrumbs?.pop();

    if (!breadcrumb) return false;

    return (
        breadcrumb.level === 'error' &&
        breadcrumb.data?.method === 'POST' &&
        /\/render_annotations$/.test(breadcrumb.data?.url)
    );
}

export const beforeSend: Required<SentryInitOptions>['beforeSend'] = event => {
    // Do not send errors from extensions
    if (isExtensionError(event)) return null;

    // Do not send failed POST render_annotations request
    if (isRenderAnnotationsError(event)) return null;

    if (event.request?.url) {
        event.request.url = redactEzProxySsoToken(event.request.url);
    }

    const breadcrumbs = event.breadcrumbs;
    if (breadcrumbs) {
        for (const breadcrumb of breadcrumbs) {
            const data = breadcrumb.data;
            if (data) {
                for (const key of Object.keys(data)) {
                    if (typeof data[key] === 'string') {
                        data[key] = redactEzProxySsoToken(data[key]);
                    }
                }
            }
        }
    }

    // We want all errors relating to a specific missing key to be grouped,
    // but we do not want different missing keys grouped together.
    // That is wy we derive a fingerprint from namespace and key
    if (event.message?.startsWith('Could not find translation for key') && event.extra?.payload) {
        const { ns, key } = event.extra.payload as Record<string, string>;
        event.fingerprint = [`${ns}:${key}`];
    }

    return event;
};

export function isSentryEnabled(): boolean {
    if (isDeprecatedBrowser()) return false;

    if (isSSR()) {
        // this environment variable is injected into the server process via gitops:
        // - platform/gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        // - platform/gitops/us-east-1/stage/team-website/patron-point-ui/values.yaml
        return process.env['SENTRY_ENABLED']?.toLowerCase() === 'true';
    }

    return document.documentElement.getAttribute('data-sentry-enabled')?.toLowerCase() === 'true';
}

/**
 * Retrieves the current sentry DSN.
 */
export function getSentryDSN(): string | undefined {
    if (isSSR()) {
        // this environment variable is injected into the server process via gitops:
        // - platform/gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        // - platform/gitops/us-east-1/stage/team-website/patron-point-ui/values.yaml
        return process.env['SENTRY_DSN'];
    }

    // the sentry DSN is injected into the HTML when the _document is being rendered on the server
    return document.documentElement.getAttribute('data-sentry-dsn') ?? undefined;
}

/**
 * Retrieves the current sentry environment.
 */
export function getSentryEnvironment(): string | undefined {
    if (typeof window === 'undefined') {
        // this environment variable is injected into the server process via gitops:
        // - platform/gitops/us-east-1/prod/team-website/patron-point-ui/values.yaml
        // - platform/gitops/us-east-1/stage/team-website/patron-point-ui/values.yaml
        return process.env['BOL_ENVIRONMENT'];
    }

    // the sentry environment is injected into the HTML when the _document is being rendered on the server
    return document.documentElement.getAttribute('data-sentry-env') ?? undefined;
}
