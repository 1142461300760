export function allKeywordsIncludedInOrder(term: string, keywords: ReadonlyArray<string>): boolean {
    if (!term) return true;

    let result = keywords.reduce(
        ({ included: lastIncluded, index: lastIndex, length: lastLength }, keyword: string) => ({
            included: lastIncluded && indexOfIgnoreCase(term, keyword) > lastIndex + lastLength,
            index: indexOfIgnoreCase(term, keyword),
            length: keyword.length
        }),
        { included: true, index: -1, length: 0 }
    );

    return result.included;
}

function indexOfIgnoreCase(term: string, keyword: string): number {
    return term.toLowerCase().indexOf(keyword.toLowerCase());
}

export function splitIntoKeywords(term: string): ReadonlyArray<string> {
    return term.split(/\s+/).filter(Boolean);
}

export function separateFirstWordsAndLastWord(message?: string | null): { firstWords: string; lastWord: string } {
    const words = message ? message.split(' ') : [''];
    const numberOfWords = words.length;

    return {
        firstWords: words.slice(0, numberOfWords - 1).join(' '),
        lastWord: words[numberOfWords - 1]
    };
}

/**
 * Splits a URL at the `#` character into the basic URL and Anchor (aka Fragment Identifier or Hash Fragment)
 * @example `page.html#paragraph-23` -> `['page.html', 'paragraph-23']`.
 */
export const splitUrlAnchor = (url: string) => {
    return url.split('#');
};

/**
 * Splits URLs at the `#` character and returns the URL without the Anchor (aka Fragment Identifier or Hash Fragment)
 * @example `page.html#paragraph-23` -> `page.html`.
 */
export const stripAnchor = (url: string) => {
    return splitUrlAnchor(url)[0];
};

/**
 * Splits URLs at the `#` character and returns only the Anchor (aka Fragment Identifier or Hash Fragment)
 * @example `page.html#paragraph-23` -> `paragraph-23`.
 */
export const getAnchor = (url: string) => {
    return splitUrlAnchor(url)[1] ?? '';
};

/**
 * Remove GET parameters and Anchor (aka Fragment Identifier or Hash Fragment) from given URL.
 *
 * @example
 * - `image.jpg?size=42` -> `image.jpg`
 * - `path/to/page.html#paragraph-23` -> `path/to/page.html`
 */
export const stripToStaticPath = (url: string) => {
    return stripAnchor(url.split('?')[0]);
};
