import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useIsPartiallyCurrentRoute = (href: string | Array<string>) => {
    const router = useRouter();
    const path = router.asPath.split('?')[0];

    const sanitizedHref = (Array.isArray(href) ? href : [href]).map(h => h.split('?')[0]);

    const isCurrent = useMemo(() => {
        return sanitizedHref.some(url => url === path);
    }, [sanitizedHref, path]);

    const isPartiallyCurrent = useMemo(() => {
        return sanitizedHref.some(url => path.startsWith(url));
    }, [sanitizedHref, path]);

    return {
        isCurrent,
        isPartiallyCurrent
    };
};
