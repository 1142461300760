import { forwardRef, useId } from 'react';

import { INavLinkProps, NavLink } from './NavLink';
import { createProductsPageLink } from '../../ProductsPageLink';

type DropdownLinkProps = {
    description?: string;
};

const DropdownLink = forwardRef<HTMLAnchorElement, DropdownLinkProps & INavLinkProps>(
    ({ className, role = 'menuitem', description, children, ...linkProps }, ref) => {
        const descriptionHtmlId = useId();

        return (
            <NavLink
                {...linkProps}
                ref={ref}
                className={className}
                aria-describedby={description && descriptionHtmlId}
                role={role}
                tabIndex={0}
            >
                {children}
                {description && (
                    <span id={descriptionHtmlId} style={{ display: 'none' }}>
                        {description}
                    </span>
                )}
            </NavLink>
        );
    }
);

export const ProductsPageDropdownLink = createProductsPageLink(DropdownLink);
