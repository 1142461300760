import get from 'lodash/get';

/**
 * Check if the given error is an unauthorized error (http status code 401).
 */
export const isUnauthorizedError = (error: unknown) => {
    // General error object with a status property
    const isStatus401 = get(error, 'status') === 401;
    // AxiosError has a response property containing the status code
    const isResponse401 = get(error, 'response.status') === 401;

    return isStatus401 || isResponse401;
};
