import { QueryClient } from '@tanstack/react-query';

import { isSSR } from '../../utils/nextjs/ssr';

let queryClient: QueryClient | undefined;

export function getQueryClient() {
    // On server side, we create a new query client on every invocation.
    if (isSSR()) return createQueryClient();

    // On client side, we save the query client in a variable so that it is not recreated on every invocation.
    if (!queryClient) queryClient = createQueryClient();

    return queryClient;
}

function createQueryClient() {
    // We use a cacheTime and staleTime of Infinity for all queries, independent if it's running on the server or
    // client.
    //
    // On the server react-query is used to prefetch data while processing the request. Every initial request will
    // create a new query client when running CustomApp.getInitialProps. Almost every page with a getServersideProps
    // function will also create a new query client (using our `initSSR()` function). Those query clients are
    // short-lived and only used to fetch data for the current request. When dehydrating its cache, we clear the query
    // clients cache, so it will be garbage collected after the request has been processed. So it is safe to use
    // Infinity as cacheTime and staleTime.
    //
    // Both caches (from CustomApp.getInitialProps and from getServersideProps) are merged when the page is rendered.
    // The dehydrated cache from getServersideProps takes precedence over the cache from CustomApp.getInitialProps.
    //
    // On the client, we use a cacheTime and staleTime of Infinity to avoid unnecessary requests. The cache is cleared
    // when the user navigates away from the page or refreshes the page.
    return new QueryClient({
        defaultOptions: {
            queries: {
                gcTime: Infinity,
                staleTime: Infinity,
                refetchOnWindowFocus: false
            }
        }
    });
}
