import { ApiClient } from '../../setup/axios';
import { debouncePromise } from '../../utils/debouncePromise';
import { isEmpty } from '../../utils/objectChecks';
import { SiteParams } from '../index';

export type ApiLoanStatus = {
    readonly active: boolean;
    readonly reserved: boolean;
    readonly inLoanHistory: boolean;
    readonly productOpened: boolean;
};

export type ApiAllLoanStatusResponse = {
    readonly loans: Record<string, ApiLoanStatus>;
};

export const getAllLoanStatus = debouncePromise(
    (client: ApiClient, params: SiteParams): Promise<ApiAllLoanStatusResponse> => {
        return client.get<ApiAllLoanStatusResponse>('/api/v1/loan-status', params).then(res => res.data);
    },
    500
);

export const isApiAllLoanStatusResponse = (data: unknown): data is ApiAllLoanStatusResponse => {
    if (isEmpty(data) || isEmpty((data as ApiAllLoanStatusResponse).loans)) return false;

    return Object.values((data as ApiAllLoanStatusResponse).loans).every(isApiLoanStatus);
};

const isApiLoanStatus = (data: unknown): data is ApiLoanStatus => {
    if (isEmpty(data)) return false;

    const activeIsBoolean = 'active' in (data as ApiLoanStatus);
    const reservedIsBoolean = 'reserved' in (data as ApiLoanStatus);
    const inLoanHistoryIsBoolean = 'inLoanHistory' in (data as ApiLoanStatus);
    const productOpenedIsBoolean = 'productOpened' in (data as ApiLoanStatus);

    return activeIsBoolean && reservedIsBoolean && inLoanHistoryIsBoolean && productOpenedIsBoolean;
};
