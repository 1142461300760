import { QueryClient, useQuery } from '@tanstack/react-query';

import { ApiReadingListProducts, EMPTY_READING_LIST, fetchReadingListProducts } from './apiFetchReadingListProducts';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { SiteParams } from '../index';

const QUERY_KEY = 'reading-lists-products';

/**
 * Fetches historical loans for a specific loan format.
 */
export const useReadingListsProducts = (): UseQueryResult<'data', ApiReadingListProducts> => {
    const { siteId, language } = useSiteParams();
    const userId = usePatronInfo().userId;

    const { data, ...rest } = useQuery({
        queryKey: buildReadingListsProductsQueryKey({ userId, siteId }),
        queryFn: () => fetchReadingListProducts(browserClient, { siteId, language })
    });

    return {
        data: data ?? EMPTY_READING_LIST,
        ...rest
    };
};

export const prefetchReadingsListsProducts = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    userId: string,
    { siteId, language }: SiteParams
) => {
    return queryClient.fetchQuery({
        queryKey: buildReadingListsProductsQueryKey({ userId, siteId }),
        queryFn: () => fetchReadingListProducts(apiClient, { siteId, language })
    });
};

export function invalidateReadingListsProducts(queryClient: QueryClient): Promise<void> {
    return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY]
    });
}

type BuildQueryKeyParams = {
    readonly userId: string;
    readonly siteId: string | undefined | null;
};

type ReadingListsProductsQueryKey = [typeof QUERY_KEY, BuildQueryKeyParams];

export const buildReadingListsProductsQueryKey = ({
    siteId,
    userId
}: BuildQueryKeyParams): ReadingListsProductsQueryKey => {
    return [QUERY_KEY, { siteId, userId }];
};
