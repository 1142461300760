import { useEffect } from 'react';

import { refreshConsentCookie } from './layout/footer/cookie-consent/ConsentCookie';
import { refreshLocaleCookie } from '../utils/localizations/localization';

export const CookieRefresh = () => {
    useEffect(() => {
        refreshConsentCookie();
        refreshLocaleCookie();
    }, []);

    return null;
};
