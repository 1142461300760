import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useExternalLoginMutation } from '../../api/authentication/useExternalLoginMutation';
import { StatusMessage } from '../../components/StatusMessage';
import { useUserRole } from '../../context/AuthenticationContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { useRedirect } from '../../hooks/events/useRedirect';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import LoggingUtils from '../../utils/logging/LoggingUtils';
import { HOME_PATH, REGISTER_PATH } from '../../utils/routes/paths';

const LOCAL_STORAGE_REDIRECT_KEY = 'external-login-redirect';

export function ExternalLogin() {
    const { t } = useTranslation();
    const router = useRouter();
    const redirectTo = useRedirect();

    const { redirectTo: redirectQuery, sso, ts } = router.query;

    const [isRedirecting, setIsRedirecting] = useState(false);

    const { login } = useLibraryContext();
    const userManagementType = login?.userManagementType;
    const ssoServerUrl = login?.ssoServerUrl;
    const errorText =
        userManagementType === 'SAML'
            ? t('login.saml.error')
            : userManagementType === 'EZPROXY'
            ? t('login.ezproxy.error')
            : t('login.login_failed');

    const { siteId, language } = useSiteParams();

    const { isTempUser, isFullUser } = useUserRole();
    const { mutate: doLogin, isError } = useExternalLoginMutation({ externalLoginType: userManagementType });

    useEffect(() => {
        if (sso && ts) {
            doLogin({
                ssoToken: sso as string,
                timestamp: ts as string,
                siteId,
                language
            });
        } else {
            if (ssoServerUrl) {
                setIsRedirecting(true);
                if (typeof redirectQuery === 'string') {
                    localStorage.setItem(LOCAL_STORAGE_REDIRECT_KEY, redirectQuery);
                }
                navigateToExternalUrl(ssoServerUrl);
            } else {
                LoggingUtils.logErrorWithPayload('Missing SSO Server URL', { siteId, login });
            }
        }
    }, [sso, ts, ssoServerUrl, doLogin, siteId, language, login, redirectQuery]);

    useEffect(() => {
        if (isFullUser) {
            const redirect = localStorage.getItem(LOCAL_STORAGE_REDIRECT_KEY) ?? HOME_PATH;
            localStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY);

            void redirectTo(redirect);
            return;
        }

        if (isTempUser) {
            localStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY);
            void redirectTo(REGISTER_PATH);
            return;
        }
    }, [isFullUser, isTempUser, redirectTo]);

    if (!isError && (isRedirecting || (sso && ts))) {
        return <StatusMessage title={t('login.please_wait')} />;
    }

    return <StatusMessage title={errorText} />;
}

function navigateToExternalUrl(url: string) {
    if (window.location) {
        window.location.assign(url);
    }
}
