import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, InternalAxiosRequestConfig } from 'axios';
import * as AxiosLogger from 'axios-logger';
import { GetServerSidePropsContext } from 'next';
import { NextPageContext } from 'next/dist/shared/lib/utils';

import { API_PATRON_ME } from '../../api/apiFetchPatronInfo';
import { API_INTERNAL_LOGIN } from '../../api/authentication/apiInternalLogin';
import { API_EXTEND_SESSION } from '../../api/authentication/apiSessionExtension';
import { API_MDP } from '../../api/mdp/apiFetchMdpUrl';
import { removePatronInfo } from '../../context/AuthenticationContext';
import { readCookie } from '../../utils/cookies';
import { isUnauthorizedError } from '../../utils/isUnauthorizedError';
import { isCSR, isSSR } from '../../utils/nextjs/ssr';
import { stringifyQuery } from '../../utils/routes/queryString';
import { getQueryClient } from '../react-query/reactQueryClient';

const http = axios.create({
    // `baseURL` will be prepended to the `url` given in the actual request (get, post, put, delete)
    // unless `url` is absolute.
    // For SSR, we want to use the BACKEND_URL as `baseURL`.
    // For the Browser, we want no `baseURL`, so that all request are made to the current browser base url.
    baseURL: isSSR() ? process.env['BACKEND_URL'] : undefined,
    responseType: 'json',
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    /**
     * By default, Axios serializes Arrays of values by adding "[]" to the parameter name. Example: { ids: [1, 2] }
     * serializes to "ids[]=1,ids[]=2"
     *
     * This doesn't play well with our Spring Boot API, which expects multi value parameters to be serialized
     * without square brackets. This changes the default params serializer to use URLSearchParams, which use
     * the expected serialization format.
     */
    paramsSerializer: { serialize: params => stringifyQuery(params, 'api') },
    timeout: 29_000 // should be lower than CloudFronts 30 seconds timeout
});

// set up SSR only interceptors
if (isSSR()) {
    http.interceptors.request.use(applyXsrf);
    http.interceptors.request.use(applyPatronId);

    if (process.env.NODE_ENV === 'development') {
        // log axios errors to the console
        AxiosLogger.setGlobalConfig({ headers: true });
        http.interceptors.response.use(undefined, AxiosLogger.errorLogger);
    }
}

// set up Browser only interceptors
if (isCSR()) {
    http.interceptors.response.use(undefined, applyAuthorizationErrorHandling);
    http.interceptors.response.use(applyCommitIdHandling);
}

type ApiRequestOptions = {
    readonly siteId?: string | null;
    readonly language?: string | null;
    readonly sessionToken?: string | null;
    readonly clientIp?: string;
    readonly cancelToken?: CancelToken;
    readonly params?: any;
    readonly withCredentials?: any;
    readonly headerAccept?: string;
};

export type ApiResponse<T> = {
    readonly data: T;
    readonly headers: { [key: string]: string | Array<string> | undefined };
    readonly status: number;
};

export interface ApiClient {
    get<T>(url: string, options?: ApiRequestOptions): Promise<ApiResponse<T>>;

    post<T>(url: string, data?: any, options?: ApiRequestOptions): Promise<ApiResponse<T>>;

    put<T>(url: string, data?: any, options?: ApiRequestOptions): Promise<ApiResponse<T>>;

    delete<T>(url: string, options?: ApiRequestOptions): Promise<ApiResponse<T>>;
}

// Create a new object that only exposes the get, post, put, delete methods of the axios instance.
//
// __DO NOT__ expose any other methods of the axios instance to prevent misuse, which could lead into polluting the
// global axios instance with e.g. interceptors and this will slow down the application.
function createApiClient(): ApiClient {
    return {
        get: (url, options) => http.get(url, toAxiosRequestOptions(options)),
        post: (url, data, options) => http.post(url, data, toAxiosRequestOptions(options)),
        put: (url, data, options) => http.put(url, data, toAxiosRequestOptions(options)),
        delete: (url, options) => http.delete(url, toAxiosRequestOptions(options))
    };
}

function toAxiosRequestOptions(options: ApiRequestOptions | undefined): AxiosRequestConfig | undefined {
    if (!options) {
        return undefined;
    }

    const { siteId, language, sessionToken, clientIp, headerAccept, ...rest } = options;

    return {
        ...rest,
        headers: {
            ...(siteId ? { 'app-siteId': siteId } : {}),
            ...(sessionToken ? { Cookie: `session=${sessionToken}` } : {}),
            ...(clientIp ? { 'app-clientIp': clientIp } : {}),
            ...(headerAccept ? { Accept: headerAccept } : {})
        }
    };
}

export function createSSRApiClient(ctx: NextPageContext | GetServerSidePropsContext): ApiClient {
    const sessionToken = readCookie('session', ctx.req?.headers.cookie);

    // Get client ip from request context
    const clientIp = ctx.req?.socket.remoteAddress;

    // Create new api client object to prevent accidentally reusing the single instance across multiple SSR requests
    const client = createApiClient();

    return {
        get: (url, options) => client.get(url, { ...options, sessionToken, clientIp }),
        post: (url, data, options) => client.post(url, data, { ...options, sessionToken, clientIp }),
        put: (url, data, options) => client.put(url, data, { ...options, sessionToken, clientIp }),
        delete: (url, options) => client.delete(url, { ...options, sessionToken, clientIp })
    };
}

export const browserClient = createApiClient();

export const addBrowserClientRequestInterceptor = (
    onFulfilled: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig
) => {
    if (isSSR()) throw new Error('This function is only available in the browser');
    return http.interceptors.request.use(onFulfilled);
};

export const ejectBrowserClientRequestInterceptor = (interceptorId: number) => {
    if (isSSR()) throw new Error('This function is only available in the browser');
    return http.interceptors.request.eject(interceptorId);
};

const AUTHORIZATION_ERROR_HANDLING_IGNORELIST = [API_EXTEND_SESSION, API_PATRON_ME, API_INTERNAL_LOGIN];

function applyAuthorizationErrorHandling(error: any) {
    const url = error?.config?.url as string | undefined;
    const isAuthError = isUnauthorizedError(error);
    const shouldBeIgnored = url && AUTHORIZATION_ERROR_HANDLING_IGNORELIST.includes(url);
    const isMDPUrl = url?.includes(API_MDP);

    if (isAuthError && !shouldBeIgnored && !isMDPUrl) removePatronInfo(getQueryClient());

    return Promise.reject(error);
}

function applyCommitIdHandling(res: AxiosResponse) {
    if (isSSR()) return res;

    // Note: Axios transforms all response header names to lower case!
    const commitId = res.headers['patron-point-commit-id'] as string | undefined;

    if (typeof commitId !== 'string') return res;

    const shortCommitId = commitId.substring(0, 8);

    document.documentElement.setAttribute('data-service-commit-id', shortCommitId);

    return res;
}

function applyXsrf(config: InternalAxiosRequestConfig) {
    const headerCookies = config.headers ? config.headers['Cookie'] : undefined;
    const existingCookies = headerCookies ? `;${headerCookies}` : ';noex';

    config.headers.set('Cookie', `XSRF-TOKEN=SSR-TOKEN${existingCookies}`);
    config.headers.set('X-XSRF-TOKEN', 'SSR-TOKEN');

    return config;
}

function isJwtObject(payload: unknown): payload is { sub: string } {
    return typeof payload === 'object' && payload !== null && 'sub' in payload && typeof payload.sub === 'string';
}

function applyPatronId(config: InternalAxiosRequestConfig) {
    const headerCookies = config.headers ? config.headers['Cookie'] : undefined;
    const sessionToken = readCookie('session', headerCookies);
    let patronId: string | undefined;

    if (sessionToken) {
        try {
            const jsonPayload = JSON.parse(Buffer.from(sessionToken.split('.')[1], 'base64').toString());
            if (isJwtObject(jsonPayload)) patronId = jsonPayload.sub;
        } catch (error) {
            /* ignore */
        }
    }

    if (patronId) config.headers.set('app-patronId', patronId);

    return config;
}
