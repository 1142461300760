import { QueryClient, QueryKey } from '@tanstack/react-query';

export async function performOptimisticUpdate<QUERY_DATA>(
    queryClient: QueryClient,
    queryKey: QueryKey,
    transform: (arg: QUERY_DATA | undefined) => QUERY_DATA
): Promise<QUERY_DATA> {
    await queryClient.cancelQueries({ queryKey });

    const previousValue = queryClient.getQueryData(queryKey) as QUERY_DATA;

    queryClient.setQueryData<QUERY_DATA>(queryKey, transform);

    return previousValue;
}
